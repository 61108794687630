import React from 'react'
import styles from './TopNavbar.css'
import { toggleUserScopes } from '../../redux/features/leftNavbarSlice.js'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal } from '@anchor/react-components/dist/lib/components/index.js'
import PropTypes from 'prop-types'

function UserScopesModal({ showUserScopes }) {
  const dispatch = useDispatch()
  const userPermissions = useSelector((state) => state.leftNavbar.userPermissions)

  return (
    <Modal
      open={showUserScopes}
      onClose={() => { dispatch(toggleUserScopes(false)) }}
      dimension='medium'
      showCloseIcon={false}
      height='500px'
      width='428px'
      zindex={999}
      actions={{
        primaryAction: <Button label="Close" onClick={() => { dispatch(toggleUserScopes(false)) }} variant="outlined" />
      }}
    >
      <div className={styles.itemsHeading} >
        <div className={styles.headingRight}>Scopes</div>
        <div className={styles.headingLeft}>Permissions</div>
      </div>
      <div className={styles.userScopesModal}>
        {userPermissions?.map((item) => {
          return <div className={styles.items} key={item.resId}>
            <div className={styles.itemTitle}>{item.resName}</div>
            <div className={styles.itemRole}>{item?.roles[0]?.name}</div>
          </div>
        })}
      </div>
    </Modal >
  )
}

export default UserScopesModal

UserScopesModal.propTypes = {
  showUserScopes: PropTypes.bool
}
