import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import styles from './DataGrid.css'
import ContextMenu from './ContextMenu/ContextMenu.js'
import { LOADING, SUCCESS } from '../../constants.js'
import { LoadingIndicator } from '@anchor/react-components/dist/lib/components/index.js'
import PropTypes from 'prop-types'

const DataTable = ({
  data = [],
  columns = [],
  showExportToolbar = false,
  pageSize,
  rowCount,
  pageSizeOption = [10, 25, 50, 100],
  showPagination = true,
  showContextMenu = true,
  onRowsChange = () => { },
  onCellDoubleClick = () => { },
  handleContextMenuClick = () => { },
  handleRowClick = () => { },
  handlePaginationModelChange = () => { },
  successTableLoading = () => { },
  tableLoading = SUCCESS,
  page,
  contextMenuList = [],
  loading = false
}) => {
  const [rows, setRows] = useState(data)
  const [pagination, setPagination] = useState({
    page, rowCount, pageSize
  })
  const [selectedRow, setSelectedRow] = useState()
  const [contextMenu, setContextMenu] = useState(null)

  useEffect(() => {
    setRows(data)
    successTableLoading()
  }, [data])

  const handleContextMenu = (event) => {
    event.preventDefault()
    setSelectedRow(Number(event.currentTarget.getAttribute('data-id')))
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    )
  }

  const onMenuClick = (menu) => {
    const newRow = rows.filter((row) => row.id === selectedRow)
    handleContextMenuClick(newRow, menu)
    handleClose()
  }

  const handleClose = () => {
    setContextMenu(null)
  }

  const handleRowsChange = (e) => {
    const visibleRows = e.visibleRowsLookup
    onRowsChange(visibleRows)
  }
  const handleCellDoubleClick = (row) => {
    onCellDoubleClick(row)
  }

  const handlePaginationChange = (newPaginationModel) => {
    setPagination(prev => ({
      ...prev,
      page: newPaginationModel.page + 1,
      pageSize: newPaginationModel.pageSize
    }))
    handlePaginationModelChange({
      page: newPaginationModel.page + 1,
      pageSize: newPaginationModel.pageSize
    })
  }
  return (
    <div className={`${page === 'summary' ? styles.dataGridSize : ''}`}>
      <Box
        sx={{
          width: '100%',
          '.MuiDataGrid-columnHeaders,.MuiDataGrid-cell,.MuiDataGrid-row': {
            // minHeight: '40px !important',
            // maxHeight: '40px !important',
            // lineHeight: '40px !important',
          },
          '.MuiDataGrid-columnHeader': {
            background: 'var(--mds_brand_appearance_neutral_subtle_background-color)',
            color: '#141414',
            fontWeight: 700,
            fontSize: '16px',
            fontFamily: ' Maersk Text',
            fontStyle: 'normal',
            lineHeight: '24px',
            borderRight: '2px solid #0000000f'
          },
          '.MuiDataGrid-row': {
            color: '#141414',
            fontWeight: 400,
            fontSize: '14px',
            fontFamily: ' Maersk Text',
            lineHeight: '20px'
          },
          '.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell': {
            borderRight: '1px solid #ddd'
          },
          '& .MuiDataGrid-cell:last-child': {
            borderRight: 'none'
          }
        }}
      >
        {tableLoading === LOADING
          ? <div className={styles.loader}>
            <LoadingIndicator />
          </div>
          : <DataGrid
            loading={loading}
            rows={columns.length ? rows : []}
            columns={columns}
            pageSize={pagination.pageSize}
            rowCount={pagination.rowCount}
            page={pagination.page - 1}
            paginationMode='server'
            paginationModel={{ page: pagination.page - 1, pageSize: pagination.pageSize }}
            pageSizeOptions={pageSizeOption}
            disableColumnSelector={false}
            hideFooter={!showPagination}
            slots={{ toolbar: showExportToolbar ? GridToolbar : null }}
            slotProps={{
              row: {
                onContextMenu: handleContextMenu,
                style: { cursor: 'context-menu' }
              }
            }}
            onRowClick={handleRowClick}
            onStateChange={handleRowsChange}
            onCellDoubleClick={handleCellDoubleClick}
            // columnHeaderHeight={40}
            // rowHeight={40}
            onPaginationModelChange={handlePaginationChange}
            getRowId={(row) => row.id || 1}
            sx={{
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
                display: 'flex',
                alignItems: 'center'
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important'
              }
            }}
          />
        }
      </Box>
      {showContextMenu
        ? <ContextMenu handleClose={handleClose} contextMenu={contextMenu} contextMenuList={contextMenuList} onMenuClick={onMenuClick} />
        : null}
    </div>
  )
}

export default DataTable

DataTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  showExportToolbar: PropTypes.bool,
  pageSize: PropTypes.number,
  rowCount: PropTypes.number,
  pageSizeOption: PropTypes.array,
  showPagination: PropTypes.bool,
  showContextMenu: PropTypes.bool,
  onRowsChange: PropTypes.func,
  onCellDoubleClick: PropTypes.func,
  handleContextMenuClick: PropTypes.func,
  handleRowClick: PropTypes.func,
  handlePaginationModelChange: PropTypes.func,
  successTableLoading: PropTypes.func,
  tableLoading: PropTypes.string,
  page: PropTypes.number,
  contextMenuList: PropTypes.array,
  loading: PropTypes.bool
}
