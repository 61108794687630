import React, { Fragment, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './LeftNavbar.css'
import { useSelector, useDispatch } from 'react-redux'
import { toggleSidebar, clickSidebarItem, clickSubMenuItem, toggleSubmenu, fetchCatalogNav, fetchMetadataNav, fetchUserPermissions } from '../../redux/features/leftNavbarSlice.js'
import rightChevron from '../../../public/assets/chevron-right.svg'
import leftChevron from '../../../public/assets/chevron-left.svg'
import LeftbarSubmenu from '../LeftbarSubmenu/LeftbarSubmenu.js'
import { newBreadcrumb } from '../../redux/features/breadcrumbsSlice.js'
import { Icon } from '@anchor/react-components/dist/lib/components/index.js'

const LeftNavbar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const navItems = useSelector((state) => state.leftNavbar.navItems)
  const isSidebarOpen = useSelector((state) => state.leftNavbar.isSidebarOpen)
  const isSubmenuOpen = useSelector((state) => state.leftNavbar.isSubmenuOpen)
  const selectedSidebarItem = useSelector((state) => state.leftNavbar.selectedSidebarItem)

  useEffect(() => {
    dispatch(fetchCatalogNav())
    dispatch(fetchMetadataNav())
    dispatch(fetchUserPermissions())
  }, [dispatch])

  useEffect(() => {
    if (navItems?.length) {
      let route = location.pathname.split('/')[1]
      route = '/' + route
      const matchedNavItem = navItems.filter((item) => {
        const innerItem = item?.submenuHeaders?.filter((inner) => inner.path === route)
        return innerItem?.length > 0
      })
      if (matchedNavItem.length) {
        const innerItem = matchedNavItem[0].submenuHeaders.filter((inner) => inner.path === route)
        dispatch(clickSidebarItem(matchedNavItem[0].id))
        dispatch(clickSubMenuItem(innerItem[0]))
      }
    }
  }, [location, navItems, dispatch])

  const handleNavItemClick = (item) => {
    if (item.path && item.path.length >= 1) {
      navigate(item.path)
      dispatch(newBreadcrumb({
        activeItem: 'dashboard',
        object: {
          ...item, breadcrumbLocator: 'dashboard'
        }
      }))
    }
    dispatch(clickSidebarItem({ item: item.id }))
    if ((item.submenuHeaders?.length > 0 || item.subMenuItems?.length > 0) &&
      (selectedSidebarItem !== item.id || !isSubmenuOpen)) {
      dispatch(toggleSubmenu(true))
    } else {
      dispatch(toggleSubmenu(false))
    }
  }

  return (
    <>
      <button onClick={() => dispatch(toggleSidebar(!isSidebarOpen))}
        className={`${styles.navItem} ${isSidebarOpen && styles.menuWidth}`}>
        <img src='/assets/hamburger.svg' alt="menu" />
        {isSidebarOpen && <div className={styles.menuItemName}>Menu</div>}
      </button>
      {navItems.map((item, index) => {
        return (
          <Fragment key={`${index}-${item.id}`}>
            <button
              id={item.id}
              key={item.id}
              className={`${isSidebarOpen && styles.menuWidth} ${item.id === selectedSidebarItem ? styles.selectedNavItem : styles.navItem} ${item.disabled ? styles.disabledNavItem : ''}`}
              onClick={() => handleNavItemClick(item)}>
              {item.icon && <img src={item.icon} alt="icon" />}
              {item.iconName && <Icon name={item.iconName} />}
              {isSidebarOpen && <>
                <div className={styles.menuItemName}>{item.name}</div>
                {(item.submenuHeaders?.length > 0 || item.subMenuItems?.length > 0) && <img src={item.id === selectedSidebarItem && (isSidebarOpen && isSubmenuOpen) ? leftChevron : rightChevron} alt="icon" className={styles.chevronIcon} />}
              </>}
            </button>
            {isSidebarOpen && isSubmenuOpen && (item.submenuHeaders?.length > 0 || item.subMenuItems?.length > 0) && selectedSidebarItem?.includes(item.id) &&
              <LeftbarSubmenu data-testid="leftbar-submenu" navItem={item.name} submenuHeaders={item.submenuHeaders} subMenuItems={item.subMenuItems} permissions={item.permissions} currentItemId={item.id} />}
          </Fragment>
        )
      })}
    </>
  )
}

export default LeftNavbar
