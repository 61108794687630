import React, { useEffect, useState } from 'react'
import { Button, Tooltip, LoadingIndicator, Icon } from '@anchor/react-components'
import styles from './Review.css'
import PropTypes from 'prop-types'
import { Dialog, Tab, Tabs } from '@mui/material'
import axiosInstance from '../../utility/axiosInstance.js'
import { formatTerraformOutput, syntaxHighlight } from '../../utility/formatReviewData.js'
import { createAndDownloadZip } from '../../utility/downloadReviewZip.js'

const ReviewModal = ({ showModal, handleModalClose, handleOrderStageService, isDisable = false, details, currentStage }) => {
  const [reviewData, setReviewData] = useState([])
  const [reviewJSON, setReviewJSON] = useState({})
  const [loading, setLoading] = useState(false)
  const [copied, setCopied] = useState(false)
  const [reviewDataRaw, setReviewDataRaw] = useState({})
  const [activeTab, setActiveTab] = useState(reviewData?.length > 0 ? reviewData[0]?.id : null);

  const fetchReview = async () => {
    const { request_body, params, api, method } = details

    const url = `${process.env.API_GATEWAY}${api}`
    const queryString = params ? `?${new URLSearchParams(params).toString()}` : ''

    try {
      let response
      if (method === 'POST') {
        response = await axiosInstance.post(`${url}${queryString}`, request_body)
      } else if (method === 'GET') {
        const getQueryString = new URLSearchParams(params || request_body).toString()
        response = await axiosInstance.get(`${url}?${getQueryString}`)
      }

      const data = response.data
      setReviewDataRaw(data)
      const updatedReviewData = [
        ...data
          .map((item, i) => ({
            ...item,
            id: i,
            isVisited: false,
            active: item.title === "ai_summary" || i === 0 // Set "ai_summary" or the first item as active
          }))
          .sort((a, b) => (a.title === "ai_summary" ? -1 : b.title === "ai_summary" ? 1 : 0)) // Move "ai_summary" to the first position
      ]
      setReviewData(updatedReviewData);
      setReviewJSON(updatedReviewData[0]?.diff || {})
      setLoading(false)
    } catch (error) {
      console.error('Error fetching review:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchReview()
  }, [])

  useEffect(() => {
    const currentActiveTab = reviewData?.find((item) => item?.active)
    setActiveTab(currentActiveTab?.id)
  }, [reviewData])

  const download = () => {
    const files = reviewDataRaw.map((item) => ({
      name: `${item.title}.txt`,
      content: typeof item.diff === 'string' ? item.diff : jsonToText(item.diff)
    }))

    createAndDownloadZip(files)
  }

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(typeof reviewJSON === 'string' ? reviewJSON : JSON.stringify(reviewJSON, undefined, 4))
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  const handleTabChange = (newValue) => {
    if (activeTab === newValue) return

    setReviewData(prevTabsData =>
      prevTabsData.map(tab => ({
        ...tab,
        isVisited: tab.isVisited || tab.id === newValue,
        active: tab.id === newValue
      }))
    )
    const clickedTab = reviewData.find(tab => tab.id === newValue)
    if (clickedTab) {
      setActiveTab(newValue)
      setReviewJSON(typeof clickedTab?.diff === 'string' ? clickedTab?.diff : { ...clickedTab?.diff || {} })
    }

  }

  function renderContent() {
    if (loading) {
      return <div className={styles.loader}><LoadingIndicator /></div>
    } else if (Object.keys(reviewData).length === 0) {
      return <div>Error Fetching Data</div>
    } else {
      return <>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => handleTabChange(newValue)}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="tabs"
        >
          {reviewData?.map((tab) => (
            <Tab
              data-testid={`review-tab-${tab.id}`}
              key={tab?.id}
              label={tab?.title}
              value={tab?.id}
              icon={
                tab.title === "ai_summary" && <img src='/assets/aiIcon.png' className={`${styles.aiIcon}`} alt='ai-icon' />
              }
              iconPosition="end"
            />
          ))}
        </Tabs>
        <button className={`${styles.copyButton} ${styles.tooltip}`} onClick={copyToClipBoard}>
          <span className={styles.tooltipText}>{copied ? '✓ Copied to clipboard' : 'Copy to clipboard'}</span>
          <Icon className={styles.copyIcon} name='copy' />
        </button>
        <div className={styles.jsonCtn}>
          {typeof reviewJSON === 'string'
            ? <pre className={`${styles.textArea} ${reviewJSON.length < 1000 ? styles.largeFont : styles.normalFont}`} dangerouslySetInnerHTML={{ __html: formatTerraformOutput(reviewJSON) }} />
            : <pre className={`${styles.textArea} ${styles.normalFont}`} dangerouslySetInnerHTML={{ __html: syntaxHighlight(JSON.stringify(reviewJSON, undefined, 4), styles) }} />
          }

        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.buttonCtn}>
            {isDisable
              ? <div>
                <Button
                  data-testid="close-button"
                  appearance="error"
                  id="primary"
                  justifyItems="left"
                  label="close"
                  name="primary"
                  onClick={handleModalClose}
                  variant="outlined"
                  className={styles.rejectBtn}
                />
              </div>
              : <div>
                <Button
                  data-testid="reject-button"
                  appearance="error"
                  id="primary"
                  justifyItems="left"
                  label="Reject"
                  name="primary"
                  onClick={() => handleOrderStageService(currentStage, 'Rejected', '')}
                  variant="outlined"
                  className={styles.rejectBtn}
                />
                <Tooltip
                  appearance="default"
                  content="Confirm or reject the review, please visit all the tabs."
                  position="right"
                  width="auto"
                >
                  <Button
                    data-testid="confirm-button"
                    appearance="default"
                    id="primary"
                    justifyItems="left"
                    label="Confirm"
                    name="primary"
                    onClick={() => handleOrderStageService(currentStage, 'Success', '')}
                    variant="filled"
                  />
                </Tooltip>
              </div>}
          </div>
          <Button
            data-testid="download-button"
            icon="arrow-to-bottom"
            appearance="default"
            id="primary"
            justifyItems="left"
            label="Download All"
            name="primary"
            onClick={download}
            variant="outlined"
            className={styles.rejectBtn}
          />
        </div>
      </>
    }
  }

  return <Dialog classes={{ paper: styles.modalCtn }} open={showModal} onClose={handleModalClose} maxWidth={false} data-testid='review-modal'>
    {renderContent()}
  </Dialog>
}

export default ReviewModal

ReviewModal.propTypes = {
  showModal: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleOrderStageService: PropTypes.func,
  isDisable: PropTypes.bool,
  details: PropTypes.object,
  currentStage: PropTypes.string
}
