import React, { useEffect } from 'react'
import styles from './PageNotFound.css'
import { useDispatch, useSelector } from 'react-redux'
import { handleIsPageNotFound } from '../../redux/features/appSlice.js'
import { Button } from '@anchor/react-components/dist/lib/components/index.js'
import { useNavigate } from 'react-router-dom'
import { clickSidebarItem } from '../../redux/features/leftNavbarSlice.js'

const PageNotFound = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const navItems = useSelector((state) => state.leftNavbar.navItems)
  useEffect(() => {
    dispatch(handleIsPageNotFound(true))
    return () => {
      dispatch(handleIsPageNotFound(false))
    }
  }, [])
  const handleRedirect = () => {
    navigate('/')
    dispatch(clickSidebarItem(navItems[0].id))
  }

  return <div className={styles.parentCtn}>
    <div className={styles.errorNumber}>404</div>
    <div className={styles.errorText}>You didn’t break the internet, but we can’t find what you are looking for</div>
    <div className={styles.redirectHome}></div>
    <Button
      appearance="default"
      id="primary"
      justifyItems="center"
      label="Return to Home"
      onClick={handleRedirect}
      title="Return to Home"
      variant="secondary"
    />
  </div>
}
export default PageNotFound
