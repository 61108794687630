import React, { useState } from "react";
import styles from './DetailPage.css'
import { Icon } from "@anchor/react-components/dist/lib/components";
import { useSelector } from "react-redux";

const TextViewer = () => {
    const { data } = useSelector(state => state.aegisBackup)
    const [copied, setCopied] = useState(false)

    const copyToClipBoard = (text) => {
        navigator.clipboard.writeText(text)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    return (
        <div className={styles.pdfViewerContainer}>
            <div className={styles.pdfPage} >
                <div className={styles.pageHeader}>
                    <h5>{data?.hostname}</h5>
                    <button className={`${styles.copyButton} ${styles.tooltip}`} onClick={() => copyToClipBoard(data?.file_content)}>
                        <span className={styles.tooltipText}>{copied ? '✓ Copied to clipboard' : 'Copy to clipboard'}</span>
                        <Icon className={styles.copyIcon} name='copy' />
                    </button>
                </div>
                <div className={styles.pageContent}>
                    <pre>{data?.file_content}</pre>
                </div>
            </div>
        </div>
    );
};

export default TextViewer;
