import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SitePageTabs from '../SitePageTabs/SitePageTabs.js'
import SiteTable from '../SiteTable/SiteTable.js'
import CreateSitePopup from '../CreateSitePopup/CreateSitePopup.js'
import { LoadingIndicator } from '@anchor/react-components'
import styles from './SitePage.css'
import { fetchCartData, fetchSchema, handleSitePageStatus } from '../../redux/features/sitePageSlice.js'
import '@anchor/react-components/dist/lib/css/global.css'
import { API_ERROR, LOADING, SUCCESS } from '../../constants.js'
import Toast from '../../commonComponents/Toast/Toast.js'

const SitePage = () => {
  const dispatch = useDispatch()
  const tabsData = useSelector((state) => state.sitePage.schemaData)
  const catalogBreadcrumb = useSelector((state) => state.sitePage.catalogBreadcrumb || []);
  const [showToastObj, setShowToastObj] = useState({ show: false, type: '', message: '' })
  const fetchingSitePageStatus = useSelector((state) => state.sitePage.fetchingSitePageStatus)
  const isCreatePopupOpen = useSelector((state) => state.sitePage.isCreatePopupOpen)
  const [tabs, setTabs] = useState(tabsData || []);

  useEffect(() => {
    setTabs(tabsData)
  }, [tabsData])

  useEffect(() => {
    dispatch(handleSitePageStatus(LOADING))
    if (catalogBreadcrumb && catalogBreadcrumb.length > 0) {
      dispatch(fetchCartData()).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          dispatch(fetchSchema({ site: catalogBreadcrumb }));
        } else {
          console.error('Failed to fetch cart data:', response.error);
        }
      });
    }
  }, [catalogBreadcrumb, dispatch]);

  const setToast = ({ show, type, message }) => {
    setShowToastObj({ show, type, message })
  }

  const renderSiteContent = () => {
    if (fetchingSitePageStatus === LOADING) {
      return <div className={styles.loader}><LoadingIndicator /></div>
    } else if (fetchingSitePageStatus === API_ERROR) {
      return <div>Error fetching Data</div>
    } else if (fetchingSitePageStatus === SUCCESS) {
      return <>
        {
          showToastObj.show && (
            <Toast
              showToast={showToastObj.show}
              setShowToast={setShowToastObj}
              message={showToastObj.message}
              type={showToastObj.type}
            />
          )
        }
        <div
          className={`${tabs && tabs?.length < 4 ? '' : styles.container}`} >
          <SitePageTabs />
          <SiteTable />
        </div>
        {isCreatePopupOpen && <CreateSitePopup setToast={setToast} />}
      </>
    } else {
      return null
    }
  }

  return <>
    {renderSiteContent()}
  </>
}
export default SitePage
