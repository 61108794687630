import React, { useEffect, useRef, useState } from 'react'
import { Input, LoadingIndicator } from '@anchor/react-components'
import styles from './AuditPage.css'
import AuditDetailPage from './AuditDetailPage.js'
import { clearSearch, dateRangeSelector, fetchAuditData, fullFillTableLoading, paginationSelector, searchSelector } from '../../redux/features/auditSlice.js'
import { useDispatch, useSelector } from 'react-redux'
import { API_ERROR, LOADING, auditContextMenulist } from '../../constants.js'
import DataTable from '../DataGrid/DataGrid.js'
import MessageView from './MessageView.js'
import DateRangePicker from '../Common/DateRangePicker/DateRangePicker.js'

const AuditPage = () => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const timeoutRef = useRef(null);
  const [isOpenMessageView, setIsOpenMessageView] = useState(false)
  const [auditData, setAuditData] = useState({})
  const [searchQuery, setSearchQuery] = useState('')
  const auditList = useSelector((state) => state.audit.auditList)
  const totalCount = useSelector((state) => state.audit.totalCount)
  const auditParams = useSelector((state) => state.audit.params)
  const fetchAuditStatus = useSelector((state) => state.audit.fetchAuditStatus)
  const tableLoading = useSelector((state) => state.audit.tableLoading)

  useEffect(() => {
    dispatch(fetchAuditData())
  }, [auditParams])

  const AuditColumnNames = [
    { field: 'user', headerName: 'User', flex: 1 },
    { field: 'operation', headerName: 'Operation', flex: 1 },
    { field: 'entity_type', headerName: 'Entity Type', flex: 1 },
    { field: 'request_time', headerName: 'Request Time (UTC)', flex: 1 },
    { field: 'response_time', headerName: 'Response Time (UTC)', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        switch (params.row.status) {
          case 'failed':
            return <div className={styles.status}>
              <div className={styles.redDot}></div>
              <div>Failed</div>
            </div>
          case 'success':
            return <div className={styles.status} >
              <div className={styles.greenDot}></div>
              <div>Success</div>
            </div>
          case 'rejected':
            return <div className={styles.status}>
              <div className={styles.redDot}></div>
              <div>Rejected</div>
            </div>
          default:
            return <div className={styles.status} >
              <div className={styles.redDot}></div>
              <div>No Status</div>
            </div>
        };
      }
    },
    { field: 'unique_identifier', headerName: 'Unique Identifier', flex: 1 },
  ]

  const formatDate = (date) => {
    const [dd, mm, yyyy] = date.split('/')
    return `${yyyy}-${mm}-${dd}`
  }

  const handleApplyDate = (dates) => {
    dispatch(dateRangeSelector({
      startDate: formatDate(dates.startDate),
      endDate: formatDate(dates.endDate)
    }))
    dispatch(fetchAuditData())
  }

  const handleRowClick = (row, menu) => {
    setAuditData(row[0] || row.row)
    setIsOpen(true)
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      dispatch(searchSelector(event.target.value))
    }, 1000);
  }

  const handleClearSearch = () => {
    setSearchQuery('')
    dispatch(clearSearch())
  }
  const handleClose = () => setIsOpen(false)
  const handlePaginationModelChange = (newPaginationModel) => dispatch(paginationSelector(newPaginationModel))
  const successTableLoading = () => dispatch(fullFillTableLoading())
  const openMessageView = () => setIsOpenMessageView(true)
  const handleCloseMessageView = () => setIsOpenMessageView(false)

  const renderAuditContent = () => {
    if (fetchAuditStatus === LOADING) {
      return (
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      )
    }

    if (fetchAuditStatus === API_ERROR || !auditList.length) {
      return (
        <div className={styles.noSites}>
          No Data Found
        </div>
      )
    }

    return (
      <div className={styles.auditCtn}>
        <DataTable
          data={auditList}
          columns={AuditColumnNames}
          contextMenuList={auditContextMenulist}
          handleContextMenuClick={handleRowClick}
          handlePaginationModelChange={handlePaginationModelChange}
          rowCount={totalCount}
          pageSize={auditParams.per_page}
          page={auditParams.page}
          tableLoading={tableLoading}
          successTableLoading={successTableLoading}
          handleRowClick={handleRowClick}
        />
      </div>
    )
  }

  return <>
    <div className={styles.titleCtn}>
      <div>Audit</div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <MessageView isOpenModal={isOpenMessageView} jsonData={auditData?.response_body} handleCloseMessageView={handleCloseMessageView} />
          <AuditDetailPage
            isOpen={isOpen}
            handleClose={handleClose}
            auditData={auditData}
            openMessageView={openMessageView}
          />
        </div>
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <DateRangePicker dateValues={auditParams} handleApplyDate={handleApplyDate} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              clearButton={true}
              onClear={handleClearSearch}
              fit="medium"
              icon="magnifying-glass"
              id="textInput"
              onChange={handleSearchChange}
              onIconClick={() => { }}
              placeholder="Search"
              type="text"
              value={searchQuery}
              variant="default"
              style={{ marginLeft: '10px' }}
            />
          </div>
        </div>
      </div>
    </div>
    {renderAuditContent()}
  </>
}

export default AuditPage
