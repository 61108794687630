import { createSlice } from '@reduxjs/toolkit'

const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('breadcrumbsData')
    return serializedState ? JSON.parse(serializedState) : undefined
  } catch (e) {
    console.warn('Could not load state', e)
    return undefined
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    sessionStorage.setItem('breadcrumbsData', serializedState)
  } catch (e) {
    console.warn('Could not save state', e)
  }
}

const initialState = loadState() || {
  currentPath: ['Dashboard'],
  breadcrumbsObj: { Dashboard: { id: 'Dashboard', name: 'Dashboard', path: '/' } }
}

export const breadcrumbsSlices = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    insertChildNode: (state, action) => {
      state.breadcrumbsObj = { ...state.breadcrumbsObj, ...action.payload }
      saveState(state)
    },
    updateCurrentBreadCrumb: (state, action) => {
      state.currentPath = action.payload
    }
  }
})

export const { insertChildNode, updateCurrentBreadCrumb } = breadcrumbsSlices.actions
export default breadcrumbsSlices.reducer
