// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parentCtn__KuP5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.errorNumber_b9E_B {

    font-family: 'Maersk Headline', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 200px;
    line-height: 240px;
    color: #141414;


}

.errorText_a_R5S {
    font-family: 'Maersk Headline', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #141414;
    margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/PageNotFound/PageNotFound.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;;IAEI,0CAA0C;IAC1C,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;;;AAGlB;;AAEA;IACI,0CAA0C;IAC1C,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".parentCtn {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n}\n\n.errorNumber {\n\n    font-family: 'Maersk Headline', sans-serif;\n    font-style: normal;\n    font-weight: 500;\n    font-size: 200px;\n    line-height: 240px;\n    color: #141414;\n\n\n}\n\n.errorText {\n    font-family: 'Maersk Headline', sans-serif;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 24px;\n    line-height: 29px;\n    color: #141414;\n    margin-bottom: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parentCtn": `parentCtn__KuP5`,
	"errorNumber": `errorNumber_b9E_B`,
	"errorText": `errorText_a_R5S`
};
export default ___CSS_LOADER_EXPORT___;
