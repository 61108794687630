import React, { useState } from 'react'
import { TextField, InputAdornment } from '@mui/material'
import searchIcon from '../../../public/assets/map-search.svg'
import PropTypes from 'prop-types'

export default function InputField ({
  defaultValue,
  name,
  placeholderText = 'Search',
  height = 32,
  icon = { searchIcon },
  onChangeCallback = () => { }
}) {
  const [searchVal, setSearchVal] = useState(defaultValue || '')
  const onChange = (e) => {
    setSearchVal(e.target.value)
    onChangeCallback(e.target.value, name)
  }
  return (
    <div>
      <TextField
        label=""
        value={searchVal}
        placeholder={placeholderText}
        onChange={(event) => onChange(event)}
        fullWidth
        InputProps={{
          type: 'search',
          startAdornment: (<InputAdornment position="start"> <img src={icon || ''} alt='search' />
          </InputAdornment>)
        }}
        sx={{

          '.MuiFormControl-root.MuiFormControl-fullWidth': {
            border: '1px solid var(--mds_brand_appearance_neutral_default_border-color)',
            borderRadius: 'var(--mds_brand_border_medium_radius)'
          },
          '.MuiInputBase-root.MuiOutlinedInput-root': {
            padding: '4px 8px',
            height: `${height}px`,
            // width: '200px',
            background: 'var(--mds_brand_appearance_neutral_inverse_text-color)'
          },
          '.MuiInputBase-input.MuiOutlinedInput-input': {
            padding: '0px',
            fontFamily: 'Maersk Text',
            fontSize: '14px',
            fontWeight: '400'
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid var(--color-state-border-neutral-hover, #878787)'
          },
          '.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid var(--color-state-border-neutral-hover, #878787)'
          },
          '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--color-state-border-neutral-hover, #878787)',
            borderWidth: '1px'
          }
        }}
      />
    </div>
  )
}

InputField.propTypes = {
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  placeholderText: PropTypes.string,
  height: PropTypes.number,
  icon: PropTypes.any,
  onChangeCallback: PropTypes.func
}
