export function syntaxHighlight(json, styles) {
    if (!json) return '' // no JSON from response

    // Escape HTML characters
    json = json.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')

    // Define regular expressions for different JSON components
    const stringRegex = /"(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"/g
    const keyRegex = /"(\s*:)/g
    const numberRegex = /-?\d+(\.\d+)?([eE][+]?[-]?\d+)?/g
    const booleanRegex = /\b(true|false)\b/g
    const nullRegex = /\bnull\b/g

    // Replace and highlight strings
    json = json.replace(stringRegex, match => {
        let cls = styles.strings
        if (match.endsWith(':')) {
            cls = styles.key
        }
        return '<span class="' + cls + '">' + match + '</span>'
    })

    // Highlight keys
    json = json.replace(keyRegex, match => {
        return '<span class="' + styles.key + '">' + match + '</span>'
    })

    // Highlight numbers
    json = json.replace(numberRegex, match => {
        return '<span class="' + styles.number + '">' + match + '</span>'
    })

    // Highlight booleans
    json = json.replace(booleanRegex, match => {
        return '<span class="' + styles.boolean + '">' + match + '</span>'
    })

    // Highlight null values
    json = json.replace(nullRegex, match => {
        return '<span class="' + styles.null + '">' + match + '</span>'
    })

    return json
}

export const formatTerraformOutput = (output) => {
    // Replace ANSI color codes with corresponding HTML tags
    return output
        .replace(/\u001b\[32m/g, '<span style="color: green;">') // Green
        .replace(/\u001b\[31m/g, '<span style="color: red;">') // Red
        .replace(/\u001b\[0m/g, '</span>') // Reset
        .replace(/\u001b\[1m/g, '<span>') // Bold
        .replace(/\u001b\[22m/g, '</span>') // End Bold
        .replace(/\n/g, '<br>') // Newline
        .replace(/###/g, '') // Newline
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold text inside ** **
}