import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ChartCard from '../Chart/ChartCard/ChartCard.js'
import Chart from '../Chart/Chart/Chart.js'
import DateRangePicker from '../Common/DateRangePicker/DateRangePicker.js'
import { LoadingIndicator } from '@anchor/react-components/dist/lib/components/index.js'
import styles from './UsageReport.css'
import { lineChartOptions, pieChartOptions } from '../../staticData/ChartData.js'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAuditReport, dateRangeSelector } from '../../redux/features/reportSlice.js'
import { API_ERROR, LOADING, SUCCESS } from '../../constants.js'

export default function UsageReport () {
  const dispatch = useDispatch()
  const [data, setData] = useState({
    allFeatures: { ...pieChartOptions, labels: [], series: [] },
    topTenFeatures: { ...pieChartOptions, labels: [], series: [] },
    userLoggedIn: { ...lineChartOptions, series: [{ name: 'User', data: [] }], xaxis: { categories: [] } }
  })
  const reportData = useSelector((state) => state.report.reportData)
  const reportParams = useSelector((state) => state.report.params)
  const fetchReportStatus = useSelector((state) => state.report.fetchReportStatus)

  useEffect(() => {
    dispatch(fetchAuditReport())
  }, [reportParams])

  useEffect(() => {
    if (fetchReportStatus === SUCCESS) {
      const allFeaturesLabels = Object?.keys(reportData?.top_10_features)
      const allFeaturesSeries = Object?.values(reportData?.top_10_features)

      const topTenFeaturesLabels = Object?.keys(reportData?.top_10_used_apis)
      const topTenFeaturesSeries = Object?.values(reportData?.top_10_used_apis)

      const userLoggedInCategories = Object.keys(reportData?.user_logged)
      const userLoggedInSeries = Object.values(reportData?.user_logged)

      setData({
        allFeatures: {
          ...pieChartOptions,
          labels: allFeaturesLabels.length ? allFeaturesLabels : ['No Data'],
          series: allFeaturesSeries.length ? allFeaturesSeries : [0]
        },
        topTenFeatures: {
          ...pieChartOptions,
          labels: topTenFeaturesLabels.length ? topTenFeaturesLabels : ['No Data'],
          series: topTenFeaturesSeries.length ? topTenFeaturesSeries : [0]
        },
        userLoggedIn: {
          ...lineChartOptions,
          series: [{ name: 'User', data: userLoggedInSeries.length ? userLoggedInSeries : [0] }],
          xaxis: {
            categories: userLoggedInCategories.length ? userLoggedInCategories : ['No Data'],
            convertedCatToNumeric: true
          }
        }
      })
    }
  }, [reportData])

  const formatDate = (date) => {
    const [dd, mm, yyyy] = date.split('/')
    return `${yyyy}-${mm}-${dd}`
  }

  const handleApplyDate = (dates) => {
    dispatch(dateRangeSelector({
      startDate: formatDate(dates.startDate),
      endDate: formatDate(dates.endDate)
    }))
  }

  const renderContent = () => {
    if (fetchReportStatus === LOADING) {
      return <div className={styles.loader}>
        <LoadingIndicator />
      </div>
    } else if (fetchReportStatus === API_ERROR) {
      return <div className={styles.noSites}>
        No Data Found
      </div>
    } else if (fetchReportStatus === SUCCESS) {
      return <Grid container spacing={2} >
        <Grid item xs={6}>
          <ChartCard title='Top 10 used APIs' className={'chartHorizontal'}>
            <Chart
              options={data.topTenFeatures}
              className={'chartHorizontalPie'}
            />
          </ChartCard>
        </Grid>
        <Grid item xs={6}>
          <ChartCard title='Top 10 Features' className={'chartHorizontal'}>
            <Chart
              options={data.allFeatures}
              className={'chartHorizontalPie'}
            />
          </ChartCard>
        </Grid>
        <Grid item xs={12}>
          {/* <ChartCard title='Config Generated' className={'chartHorizontal'}>
            <Chart
                options={lineOptions}
                className={'chartHorizontal'}
            />
        </ChartCard> */}
          <ChartCard title='Users LoggedIn' className={'chartHorizontal'}>
            <Chart
              options={data.userLoggedIn}
              className={'chartHorizontal'}
            />
          </ChartCard>
        </Grid>
        {/* <Grid item xs={3}>
        <ChartCard title='Top 10 Highest Used Features' className={'chartVertical'}>
            <Chart
                options={pieOptions}
                className={'chartVertical'}
            />
        </ChartCard>
    </Grid> */}
        {/* <Grid item xs={3}>
        <ChartCard title='Sample Model Commits' className={'chartVertical'}>
            <Chart
                options={pieOptions}
                className={'chartVertical'}
            />
        </ChartCard>
    </Grid> */}
      </Grid >
      /* <Grid container spacing={12}>
          <Grid item xs={6}>
              <ChartCard title='Users Logged in the Portal   ' className={'chartVertical'}>
                  <Chart
                      options={lineOptions}
                      className={'chartHorizontal'}
                  />
              </ChartCard>
          </Grid>
          <Grid item xs={6}>
              <ChartCard title='Unique Visitors' className={'chartVertical'}>
                  <Chart
                      options={lineOptions}
                      className={'chartHorizontal'}
                  />
              </ChartCard>
          </Grid>
      </Grid> */
    }
  }

  return <>
    <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'end', marginBottom: '10px' }}>
      <DateRangePicker dateValues={reportParams} handleApplyDate={handleApplyDate} />
    </div>
    {renderContent()}
  </>
}
