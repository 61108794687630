import React, { useEffect, useState } from 'react';
import { fetchIntentData, handleCreateForm, handleSelectedTabClick, setColumnNames } from '../../redux/features/sitePageSlice.js';
import { useSelector, useDispatch } from 'react-redux';
import { catalogBreadCrumb } from '../../redux/features/breadcrumbsSlice.js';
import styles from './SitePageTabs.css';
import { Tab, Tabs } from '@mui/material';

const SitePageTabs = () => {
  const tabsData = useSelector((state) => state.sitePage.schemaData)
  const catalogBreadcrumb = useSelector((state) => state.sitePage.catalogBreadcrumb)
  const [tabs, setTabs] = useState(tabsData || []);
  const [activeTab, setActiveTab] = useState(tabs?.length > 0 ? tabs[0]?.id : null);

  useEffect(() => {
    setTabs(tabsData)
    const currentActiveTab = tabsData?.find((item) => item?.active)
    setActiveTab(currentActiveTab?.title)
    if (currentActiveTab) dispatch(catalogBreadCrumb({ path: `${catalogBreadcrumb}/${currentActiveTab.id}`, tab: currentActiveTab.id }))
  }, [tabsData])

  const dispatch = useDispatch()
  const handleTabChange = (newValue) => {
    if (activeTab === newValue) return
    setTabs(prevTabsData =>
      prevTabsData?.map(tab => ({
        ...tab,
        active: tab?.title === newValue // Set active to true for the clicked tab
      }))
    )
    const selectedTabObj = tabs?.find((item) => item?.id === newValue)
    setActiveTab(newValue)
    dispatch(handleSelectedTabClick(selectedTabObj))
    dispatch(fetchIntentData({ path: `${catalogBreadcrumb}/${newValue}`, reload: true }))
    if (selectedTabObj) {
      const columnNames = selectedTabObj.columnNames
      dispatch(handleCreateForm(selectedTabObj?.createForm))
      dispatch(setColumnNames(columnNames))
    }
  }

  return (
    <div>
      {tabs?.length < 4 ? <Tabs
        value={activeTab}
        onChange={(_, newValue) => handleTabChange(newValue)}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="tabs"
      >
        {tabs?.map((tab) => (
          <Tab
            key={tab?.id}
            label={tab?.title}
            value={tab?.id}
          />
        ))}
      </Tabs> :
        <div className={styles.tabContainer} data-testid="site-page-tabs">
          <div className={styles.tabList}>
            {tabs?.map((tab) => (
              <button
                key={tab?.id}
                className={`${styles.tabItem} ${activeTab === tab?.title ? styles.active : ''}`}
                onClick={() => handleTabChange(tab?.title)}
              >
                {tab?.title}
              </button>
            ))}
          </div>
        </div>
      }

    </div>
  );
}
export default SitePageTabs
