import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_ERROR, LOADING, SUCCESS } from '../../constants.js'
import axiosInstance from '../../utility/axiosInstance.js'
const today = new Date()
const yesterday = new Date(today)
yesterday.setDate(yesterday.getDate() - 6)

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

export const fetchAuditData = createAsyncThunk(
  'fetchAuditData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState()
      const intentResponse = await axiosInstance.get(`${process.env.API_GATEWAY}/audits`, {
        params: state.audit.params
      })
      const response = intentResponse.data || {}
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const auditSlice = createSlice({
  name: 'audit',
  initialState: {
    auditList: [],
    fetchAuditStatus: LOADING,
    tableLoading: LOADING,
    totalCount: 0,
    params: {
      page: 1,
      per_page: 10,
      start_date: formatDate(yesterday),
      end_date: formatDate(today),
    }
  },

  reducers: {
    dateRangeSelector: (state, action) => {
      state.params.page = 1
      state.params.per_page = 10
      state.params.start_date = action.payload.startDate
      state.params.end_date = action.payload.endDate
    },
    paginationSelector: (state, action) => {
      state.params.page = action.payload.page
      state.params.per_page = action.payload.pageSize
    },
    fullFillTableLoading: (state, action) => {
      state.tableLoading = SUCCESS
    },
    searchSelector: (state, action) => {
      if (state.params.search_string) {
        state.params.search_string = action.payload
      } else {
        state.params.search_string = action.payload
        state.params.page = 1
        state.params.per_page = 10
        state.params.start_date = formatDate(yesterday)
        state.params.end_date = formatDate(today)
      }
    },
    clearSearch: (state) => {
      state.params = {
        page: 1,
        per_page: 10,
        start_date: formatDate(yesterday),
        end_date: formatDate(today),
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAuditData.pending, (state, action) => {
        state.fetchAuditStatus = LOADING
        state.tableLoading = LOADING
      })
      .addCase(fetchAuditData.fulfilled, (state, action) => {
        state.auditList = action.payload?.list_audit || []
        state.totalCount = action.payload?.count?.total_count || 0
        state.fetchAuditStatus = SUCCESS
      })
      .addCase(fetchAuditData.rejected, (state, action) => {
        state.fetchAuditStatus = API_ERROR
      })
  }
})

export const { dateRangeSelector, paginationSelector, fullFillTableLoading, searchSelector, clearSearch } = auditSlice.actions
export default auditSlice.reducer
