import React, { useState } from 'react'
import styles from './LoginPage.css'
import { Button, Input } from '@anchor/react-components'
import { loginRequest } from '../../utility/config.js'
import PropTypes from 'prop-types'

function LoginPage ({ instance }) {
  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  })
  const [registeredEmail, setRegisteredEmail] = useState('')
  const initialErrorObj = { isFailed: false, message: '' }
  const [error, setError] = useState(initialErrorObj)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [isForgotPasswordLinkSent, setIsForgotPasswordLinkSent] = useState(false)

  const handleRedirect = () => {
    instance.loginRedirect({
      ...loginRequest,
      prompt: 'create'
    })
      .catch(error => setError({ isFailed: true, message: error }))
  }

  const handleInputCredentials = (event) => {
    const { name, value } = event.target
    setCredentials(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleForgotPassword = () => {
    setForgotPassword(true)
  }

  const handleRegister = (event) => {
    setRegisteredEmail(event.target.value)
  }
  const handleSendEmail = () => {
    setIsForgotPasswordLinkSent(true)
  }
  const areCredentialsFilled = credentials.username.trim() !== '' && credentials.password.trim() !== ''
  const isRegisteredMailFilled = registeredEmail.trim() !== ''
  return <div className={styles.unAuthenticatedCtn}>
    <div className={styles.appTitle}>ProviGenius</div>
    <div className={styles.loginCtn}>
      <div className={styles.logoCtn}>
        <img src='/assets/maerskLogo.svg' className={styles.logoImg} alt='logo' />
      </div>
      {forgotPassword
        ? <div>
          <div className={styles.forgotTitle}>Forgot Password</div>
          {isForgotPasswordLinkSent
            ? <div className={styles.forgotText}>
              Reset password link sent to your registered Email ID.
            </div>
            : <><div className={styles.forgotText}>We will send you reset password link to your registered Email ID.</div>
              <Input
                fit="medium"
                icon="user"
                label="Registered Email "
                onChange={handleRegister}
                placeholder="Enter your registered Email ID"
                type="email"
                value={registeredEmail}
                variant="default"
                name="username"
              />
              <div className={styles.loginButton}>
                <Button
                  appearance="default"
                  disabled={!isRegisteredMailFilled}
                  justifyItems="center"
                  label="Send"
                  onClick={handleSendEmail}
                  title="Button"
                  variant="filled"
                />
              </div>
            </>}
        </div>
        : <>
          <div className={styles.ssoBtn}>
            <Button
              appearance="default"
              justifyItems="center"
              label="Login with Single Sign On"
              name="primary"
              onClick={handleRedirect}
              className={styles.ssoBtn}
              title="Login with Single Sign On"
              variant="filled"
            />
          </div>
          <div className={styles.continueCtn}>
            <hr className={styles.hr} />
            <div className={styles.continueText}>Or continue with</div>
            <hr className={styles.hr} />
          </div>
          {error.isFailed
            ? <div className={styles.errorCtn}>
              <img src='/assets/redExClamation.svg' className={styles.errorIcon} alt='error' />
              {error.message}
            </div>
            : null}
          <div className={styles.emailCtn}>
            <Input
              fit="medium"
              icon="user"
              label="Username"
              onChange={handleInputCredentials}
              placeholder="Enter Email / Username"
              type="email"
              value={credentials.username}
              variant="default"
              name="username"
            />
          </div>
          <Input
            fit="medium"
            icon="lock"
            label="Password"
            iconPosition="right"
            onChange={handleInputCredentials}
            placeholder="Enter Password"
            value={credentials.password}
            variant="default"
            name="password"
            type="passowrd"
            className={styles.passwordCtn}
            suffix="$"
          />
          <button className={styles.forgotPassword} onClick={handleForgotPassword}>Forgot Password</button>
          <div className={styles.loginButton}>
            <Button
              appearance="default"
              disabled={!areCredentialsFilled}
              justifyItems="center"
              label="Login"
              onClick={function noRefCheck () { }}
              title="Button"
              variant="filled"
            />
          </div>
        </>}
    </div>
  </div>
}

export default LoginPage

LoginPage.propTypes = {
  instance: PropTypes.object
}
