import { ReactSelect } from '@anchor/react-components/dist/lib/components/index.js'
import React from 'react'
import PropTypes from 'prop-types'

export default function Dropdown({ className, fit, id, label, name, onChange, required, options, placeholder, value, disabled, searchable = false, error, errorMessage, onOptionUpdate = () => { } }) {
  const formattedOptions = options.map((option) => ({
    label: option,
    value: option
  }))
  return (
    <ReactSelect
      className={className}
      closeMenuOnSelect
      customNoOptionsMessage={<p>Not a valid option</p>}
      error={error}
      errorMessage={errorMessage}
      fit={fit}
      id={id}
      key={id}
      isClearable
      isSearchable={searchable}
      label={label}
      name={name}
      onChange={onChange}
      options={formattedOptions}
      orientation="vertical"
      placeholder={placeholder}
      required={required}
      suggestType="static"
      value={formattedOptions.find((option) => option.value === value) || null}
      variant="default"
      width={100}
      disabled={disabled}
      onOptionUpdate={onOptionUpdate}
    />
  )
}
Dropdown.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  fit: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  error: PropTypes.bool,
  onOptionUpdate: PropTypes.func
}
