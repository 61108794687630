import React from 'react'
import Styles from './ChartCard.css'
import PropTypes from 'prop-types'

export default function ChartCard ({ children, title, className }) {
  return (
    <div className={Styles[className]}>
      <div className={Styles.chartTitle}>{title}</div>
      {children}
    </div>
  )
}

ChartCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string
}
