import React, { useEffect, useState } from 'react'
import styles from './OrderDetailsPage.css'
import { useDispatch, useSelector } from 'react-redux'
import ReviewModal from '../Review/Review.js'
import CommitModal from '../CommitModal/CommitModal.js'
import { fetchOrderDetails } from '../../redux/features/trackOrdersSlice.js'
import { useLocation } from 'react-router-dom'
import Toast from '../../commonComponents/Toast/Toast.js'
import { Button, Icon, LoadingIndicator } from '@anchor/react-components'
import { API_ERROR, APPROVED, FAILED, IN_PROGRESS, LOADING, PENDING, REJECTED, SUCCESS, SUCCESS_STAGE } from '../../constants.js'
import axiosInstance from '../../utility/axiosInstance.js'
import RejectModal from '../RejectModal/RejectModal.js'

const OrderDetailsPage = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [intervalId, setIntervalId] = useState(null)
  const [rejectModal, setRejectModal] = useState(false)
  const [copied, setCopied] = useState(false)
  const [showToastObj, setShowToastObj] = useState({ show: false, type: '', message: '' })
  const [toggleExpand, setToggleExpand] = useState({ show: false })
  const orderDetails = useSelector((state) => state.trackOrders.orderDetails)
  const [orderDetailsObj, setOrderDetailsObj] = useState(orderDetails)
  const [showReviewModal, setShowReviewModal] = useState(false)
  const [showCommitModal, setShowCommitModal] = useState(false)
  const [isReviewButtonDisabled, setIsReviewButtonDisabled] = useState(false)
  const [tooltip, setTooltip] = useState(null)
  const fetchOrderDetailsStatus = useSelector((state) => state.trackOrders.fetchOrderDetailsStatus)
  const searchParams = new URLSearchParams(location.search)
  const orderid = searchParams.get('orderid')
  const [progress, setProgress] = useState(100)
  const [isLoading, setIsLoading] = useState(false)
  const [currentButton, setCurrentButton] = useState(null)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isRotating, setIsRotating] = useState(false);

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);

  useEffect(() => {
    setOrderDetailsObj(orderDetails)
    if (orderDetails.poll && orderDetails.current_status === IN_PROGRESS) {
      startPolling()
    } else if (intervalId) {
      setIsLoading(false)
      setIsButtonLoading(false)
      clearInterval(intervalId)
      setIntervalId(null)
    }
    return () => {
      if (intervalId) {
        setIsLoading(false)
        setIsButtonLoading(false)
        clearInterval(intervalId)
        setIntervalId(null)
      }
    }
  }, [orderDetails])

  useEffect(() => {
    dispatch(fetchOrderDetails(orderid))
  }, [orderid])

  const startPolling = () => {
    setIsButtonLoading(true)
    setIsLoading(true)
    // setProgress(0)

    // const progressInterval = setInterval(() => {
    //   setProgress((prevProgress) => {
    //     if (prevProgress >= 90) {
    //       clearInterval(progressInterval)
    //       return 90
    //     }
    //     return prevProgress + 2
    //   })
    // }, 600)

    const pollingInterval = setInterval(async () => {
      try {
        const userId = sessionStorage.getItem('user_id')
        const response = await axiosInstance.post(
          `${process.env.API_GATEWAY}/check-stage-status?stage=${orderDetails.current_stage}&uuid=${orderDetails.order_id}&user_id=${userId}`
        )

        if (response.status === 200) {
          if (['Success', 'Failed'].includes(response.data.current_status)) {
            clearInterval(pollingInterval)
            // clearInterval(progressInterval)
            // setProgress(100)
            setIsLoading(false)
            setIsButtonLoading(false)
            dispatch(fetchOrderDetails(orderDetails.order_id))
          }
        }
      } catch (error) {
        clearInterval(pollingInterval)
        // clearInterval(progressInterval)
        // setProgress(100)
        setIsLoading(false)
        setIsButtonLoading(false)
      }
    }, 3000)
    setIntervalId(pollingInterval)
  }

  const buttonClick = async (button) => {
    setCurrentButton(button)
    if (button.type === 'submit') {
      await handleApiCall(button, () => dispatch(fetchOrderDetails(orderDetailsObj.order_id)))
    } else if (button.type === 'reject_model') {
      setRejectModal(true)
    } else if (button.type === 'review_modal') {
      setShowReviewModal(true)
    } else if (button.type === 'commit_modal') {
      setShowCommitModal(true)
    }
  }

  const handleStageRefresh = async () => {
    try {
      setIsRotating(true);
      const userId = sessionStorage.getItem('user_id')
      await axiosInstance.post(
        `${process.env.API_GATEWAY}/check-stage-status?stage=${orderDetails.current_stage}&uuid=${orderDetails.order_id}&user_id=${userId}`
      )
      dispatch(fetchOrderDetails(orderDetails.order_id))
    } catch (err) {
      setShowToastObj({ show: true, type: 'error', message: 'Error refreshing stage' })
    } finally {
      setIsRotating(false);
    }

  }

  const handleApiCall = async (buttonDetails, postSuccessAction) => {
    setIsButtonLoading(true)
    let { request_body, params, api, method } = buttonDetails
    const userId = sessionStorage.getItem('user_id');

    if (params?.user_id) params = { ...params, user_id: userId };
    if (request_body?.user_id) request_body = { ...request_body, user_id: userId };

    const url = `${process.env.API_GATEWAY}${api}`
    const queryString = params ? `?${new URLSearchParams(params).toString()}` : ''

    try {
      let response
      if (method === 'POST') {
        response = await axiosInstance.post(`${url}${queryString}`, request_body)
      } else if (method === 'GET') {
        const getQueryString = new URLSearchParams(params || request_body).toString()
        response = await axiosInstance.get(`${url}?${getQueryString}`)
      }

      if (postSuccessAction) postSuccessAction()

      setShowToastObj({ show: true, type: `${(response?.data?.current_status?.includes('progress') || response?.data?.status?.includes('progress')) ? 'warning' : 'success'}`, message: `${buttonDetails.title} ${response?.data?.current_status || response?.data?.status}` })
    } catch (error) {
      const message = error.response?.data?.message || `${buttonDetails.title} failed`
      setShowToastObj({ show: true, type: 'error', message })
    } finally {
      setIsButtonLoading(false)
    }
  }

  const handleReject = async (note) => {
    const buttonDetails = {
      ...currentButton,
      ...(currentButton.params && {
        params: { ...currentButton.params, description: note }
      }),
      ...(currentButton.request_body && {
        request_body: { ...currentButton.request_body, description: note }
      })
    }

    await handleApiCall(
      buttonDetails,
      () => {
        setRejectModal(false)
        dispatch(fetchOrderDetails(orderDetailsObj.order_id))
      }
    )
  }

  const handleCommitModal = async (note, referenceNumber) => {
    const buttonDetails = {
      ...currentButton,
      ...(currentButton.params && {
        params: { ...currentButton.params, reference_number: referenceNumber, user: `${currentButton.params.user}`, note }
      }),
      ...(currentButton.request_body && {
        request_body: { ...currentButton.request_body, reference_number: referenceNumber, user: `${currentButton.request_body.user}`, note }
      })
    }

    await handleApiCall(
      buttonDetails,
      () => {
        setShowCommitModal(false)
        dispatch(fetchOrderDetails(orderDetailsObj.order_id))
      }
    )
  }

  const setStatusColor = (status) => {
    let statusStyle
    switch (status) {
      case SUCCESS_STAGE:
      case APPROVED:
        statusStyle = styles.greenValStage
        break
      case FAILED:
      case REJECTED:
        statusStyle = styles.redValStage
        break
      case IN_PROGRESS:
        statusStyle = styles.orangeValStage
        break
      case PENDING:
        statusStyle = styles.greyValStage
        break
      default:
        statusStyle = styles.default
        break
    }
    return statusStyle
  }

  const setCurrentStatusColor = (status) => {
    let statusStyle
    switch (status) {
      case SUCCESS_STAGE:
      case APPROVED:
        statusStyle = styles.greenVal
        break
      case FAILED:
      case REJECTED:
        statusStyle = styles.redVal
        break
      case IN_PROGRESS:
        statusStyle = styles.orangeVal
        break
      case PENDING:
        statusStyle = styles.greyVal
        break
      default:
        statusStyle = styles.default
        break
    }
    return statusStyle
  }

  const handleOrderStageService = async (stage, status, description) => {
    try {
      const userId = sessionStorage.getItem('user_id')
      await axiosInstance.post(`${process.env.API_GATEWAY}/check-stage-status?uuid=${orderDetailsObj.order_id}&stage=${stage}&status=${status}&user_id=${userId}&description=${description}`)
      handleReviewModalClose()
      setShowToastObj({ show: true, type: 'success', message: status === 'Success' ? 'Review Confirmed' : 'Review Rejected' })
      dispatch(fetchOrderDetails(orderDetailsObj.order_id))
    } catch (err) {
      setShowToastObj({ show: true, type: 'warning', message: 'Review Failed' })
    }
  }

  const chunkArray = (array, size) => {
    const chunkedArr = []
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size))
    }
    return chunkedArr
  }

  const handleToggleExpand = (itemsWithOperation, index) => {
    const chunkedItems = chunkArray(itemsWithOperation.slice(3), 5)
    if (chunkedItems.length && chunkedItems[chunkedItems.length - 1].length < 5) {
      const lastChunk = chunkedItems[chunkedItems.length - 1]
      while (lastChunk.length < 5) {
        lastChunk.push({ title: '', value: '' })
      }
    }
    setToggleExpand({ show: !toggleExpand.show, data: chunkedItems, id: index })
  }

  const handleReviewModalClose = () => {
    setShowReviewModal(false)
    setIsReviewButtonDisabled(false)
  }

  const handleCommitModalClose = () => {
    setShowCommitModal(false)
  }

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  const renderButton = (button) => {
    return <Button
      className={`${styles.btn}`}
      appearance={`${button.color === 'Blue' ? 'default' : 'error'}`}
      icon={button.icon}
      id="primary"
      justifyItems="center"
      label={button.title}
      name="primary"
      onClick={() => buttonClick(button)}
      title={button.title}
      variant="filled"
      data-testid={button.title}
      loading={(isButtonLoading && !currentButton) || (isButtonLoading && currentButton.title === button.title)}
      disabled={isButtonLoading}
    />
  }

  function getTextColorClass(value) {
    if (value === 'Create') {
      return styles.greenText
    } else if (value === 'Modify') {
      return styles.orangeText
    } else if (value === 'Delete') {
      return styles.redText
    } else {
      return ''
    }
  }

  const renderOrderText = (item, i) => (
    <div className={styles.orderText} key={`${i}-${item.title}`}>
      <div className={styles.greyText}>{item.title}</div>
      <div className={styles.blacktext}>
        {Array.isArray(item.value) ? renderTooltip(item.value) : `${item.value}`}
      </div>
    </div>
  )

  const renderTooltip = (valueArray) => (
    <div className={styles.tooltip}>
      {valueArray[0]}
      {valueArray.length > 1 && (
        <>
          <span className={styles.tooltipText}>
            {valueArray.join(', ')}
          </span>
          <span>, {valueArray[1].slice(0, 3)}...</span>
        </>
      )}
    </div>
  )

  const renderChunk = (chunk, chunkIndex, index) => (
    <div
      className={`${styles.firstCtn} ${chunkIndex === toggleExpand.data.length - 1
        ? styles.bottomCtn
        : styles.bottomMargin
        }`}
      key={`${index}-${chunkIndex}`}
    >
      {chunk.map(renderOrderText)}
      {renderChevron(chunkIndex, index)}
    </div>
  )

  const renderChevron = (chunkIndex, index) => {
    if (chunkIndex === toggleExpand.data.length - 1 && index === toggleExpand.id) {
      return (
        <div className={styles.chevronContainer}>
          <button
            className={styles.chevron}
            onClick={() => setToggleExpand({ show: !toggleExpand.show })}
          >
            <img src='/assets/upChevron.svg' alt="upChevron" />
          </button>
        </div>
      )
    }
    return null
  }

  const renderChunks = (chunks, index) => (
    chunks.map((chunk, chunkIndex) => renderChunk(chunk, chunkIndex, index))
  )

  const showTooltip = (index) => {
    if (tooltip && tooltip.index === index) return setTooltip(null)
    const currentItem = orderDetailsObj?.status_arr[index]
    const stageDetails = orderDetails?.stages_info?.[currentItem?.value]
    if (stageDetails?.description_type === 'String') {
      setTooltip({ ...stageDetails, index })
    } else if (stageDetails?.description_type === 'Modal') {
      if (stageDetails.modal_details.type === 'review_modal') {
        setCurrentButton({ ...stageDetails.modal_details, params: { uuid: orderDetailsObj.order_id, stage: currentItem?.value } })
        setIsReviewButtonDisabled(true)
        setShowReviewModal(true)
      }
    }
  }

  const statusIndicators = () => {
    return (
      <div className={styles.statusIndicators}>
        <div className={styles.status}>
          <span className={`${styles.circle} ${styles.success}`}></span>
          <span className={styles.label}>Success</span>
        </div>
        <div className={styles.status}>
          <span className={`${styles.circle} ${styles.inProgress}`}></span>
          <span className={styles.label}>In Progress</span>
        </div>
        <div className={styles.status}>
          <span className={`${styles.circle} ${styles.notStarted}`}></span>
          <span className={styles.label}>Not started</span>
        </div>
        <div className={styles.status}>
          <span className={`${styles.circle} ${styles.failed}`}></span>
          <span className={styles.label}>Failed</span>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (fetchOrderDetailsStatus === LOADING) {
      return <div className={styles.loader}>
        <LoadingIndicator />
      </div>
    } else if (fetchOrderDetailsStatus === API_ERROR) {
      return <div className={styles.apiError}>Error fetching data</div>
    } else if (Object.keys(orderDetailsObj).length === 0) {
      return <div className={styles.noSites}>No Data Found</div>
    } else if (fetchOrderDetailsStatus === SUCCESS) {
      return <div className={styles.orderDetailsBody}>
        <div className={styles.firstCtn}>
          <div className={styles.orderText}>
            <div className={styles.greyText}>Order Placed On</div>
            <div className={styles.blacktext}>{orderDetailsObj.order_placed_on}</div>
          </div>
          <div className={styles.orderText}>
            <div className={styles.greyText}>Order Placed By</div>
            <div className={styles.blacktext}> {orderDetailsObj.order_placed_by}</div>
          </div>
          <div className={styles.orderText}>
            <div className={styles.greyText}>Order Id </div>

            <div className={`${styles.blacktext} `}>
              <span className={styles.tooltip}>{orderDetailsObj.order_id.slice(0, 10)}...
                <span className={styles.tooltipText}>
                  {orderDetailsObj.order_id}
                </span></span>
              <button className={`${styles.copyButton} ${styles.tooltip}`} onClick={() => copyToClipBoard(orderDetailsObj.order_id)}>
                <span className={styles.tooltipText}>{copied ? '✓ Copied to clipboard' : 'Copy to clipboard'}</span>
                <Icon className={styles.copyIcon} name='copy' />
              </button>
            </div>
          </div>
          <div className={styles.orderText}>
            <div className={styles.greyText}>Order Stage</div>
            <div className={styles.blacktext} data-testid='order-stage'> {orderDetailsObj?.current_stage}</div>
          </div>
          <div className={styles.orderText}>
            <div className={styles.greyText}>Order Status</div>
            <div className={`${setCurrentStatusColor(orderDetailsObj?.current_status)} ${styles.default} ${styles.statusButton}`} data-testid='order-status'>{orderDetailsObj?.current_status}</div>
          </div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.orderStageStatusCtn}>
          <div className={styles.stageOuter}>
            <div className={styles.stages}>
              {orderDetails.status_arr.map((item, index) => {
                return <React.Fragment key={`${index}-${item.title}-${item.value}`}>
                  <button className={`${styles.stage} ${styles.tooltip}`} onClick={() => { ![PENDING, IN_PROGRESS].includes(item.state) && showTooltip(index) }}>
                    <div className={`${setStatusColor(item.state)} ${styles.default}`}>
                      {/* Progress bar background */}
                      {(isLoading && orderDetails.current_stage === item.value) && <div className={styles.progressBarContainer}>
                        <div className={styles.progressBar} style={{ width: `${progress}%` }}>
                          <div className={styles.glow}></div> {/* Glow effect */}
                        </div>
                      </div>}
                      {/* Content (icon and title) */}
                      <div className={styles.content}>
                        <Icon className={styles.buttonIcon} name={item.icon} />
                        {item.title}
                      </div>
                    </div>
                    {![PENDING, IN_PROGRESS].includes(item.state) &&
                      <>
                        {item.ai_summary ? <img src='/assets/aiIcon.png' className={`${styles.aiIcon}`} alt='ai-icon' onClick={() => showTooltip(index)} /> :
                          <Icon name='info-circle' className={`${styles.infoIcon}`} onClick={() => showTooltip(index)} />}
                      </>
                    }
                    {tooltip && tooltip.index === index && (
                      <div className={styles.notificationBox}>
                        <div className={styles.notificationContent}>
                          <div>
                            <p className={styles.message}>{`${item.value === 'approve' ? item?.state : item?.title} by ${tooltip?.updated_by_user}`}</p>
                            {item.value === 'review'
                              ? <Button
                                appearance="default"
                                id="primary"
                                justifyItems="left"
                                label="Show Review Output"
                                name="primary"
                                onClick={() => {
                                  setIsReviewButtonDisabled(true)
                                  setShowReviewModal(true)
                                }}
                                variant="outlined"
                              />
                              : <p className={styles.message}>{tooltip?.description}</p>}
                          </div>
                          <div className={styles.actions}>
                            {/* <button className={styles.copyBtn} >
                          <Icon className={styles.icon} name='copy' />
                        </button> */}
                            <button className={styles.closeBtn} onClick={() => setTooltip(null)}>
                              <Icon className={styles.icon} name='times' />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </button>
                  {index !== orderDetailsObj.status_arr.length - 1 && <div className={styles.horizontalLine}></div>}
                </React.Fragment>
              })}
            </div>
            {statusIndicators()}
          </div>
          <div className={styles.nextStep}>{orderDetails?.buttons_data?.map((button) => {
            return renderButton(button)
          })}</div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.orderDetailsObj}>Ordered Items</div>
        <div >
          {orderDetailsObj?.all_actions?.map((detail, index) => {
            const items = Object.values(detail).sort((a, b) => a?.title?.localeCompare(b?.title))
            const operationItem = items?.find(item => item?.title === 'Operation')
            const itemsWithOperation = items?.filter(item => item?.title !== 'Operation')
            while (itemsWithOperation?.length < 3) {
              itemsWithOperation.push({ title: '', value: '' })
            }
            return (
              <div key={`${index}-${detail?._action_?.url}`}>
                <div className={`${styles.firstCtn} ${index !== toggleExpand?.id ? styles.bottomCtn : styles.bottomMargin}`} >
                  {operationItem && (
                    <div className={styles.orderText} key={`Operation-${index}-${detail?._action_?.url}`}>
                      <div className={styles.greyText}>{operationItem?.title}</div>
                      <div className={`${styles.blacktext} ${getTextColorClass(operationItem?.value)}`}>{operationItem.value}</div>
                    </div>
                  )}
                  <div className={styles.orderText} key={`first-${index}-${detail?._action_?.url}`}>
                    <div className={styles.greyText}>Url</div>
                    <div className={`${styles.blacktext} ${styles.tooltip}`}>
                      {detail?._action_?.url.split('/')[0]} /
                      {detail?._action_?.url.split('/')?.length > 1 && (
                        <>
                          <span className={styles.tooltipText}>
                            {detail?._action_?.url.split('/')?.join('/')}
                          </span>
                          <span>{detail?._action_?.url?.split('/')[1]?.slice(0, 5)}...</span>
                        </>
                      )}
                    </div>
                  </div>
                  {itemsWithOperation?.slice(0, 3)?.map((item, i) => {
                    return (
                      <div className={styles.orderText} key={`item-${i}-${item?.title}`}>
                        <div className={styles.greyText}>{item?.title}</div>
                        <div className={styles.blacktext}>
                          {Array.isArray(item?.value)
                            ? (
                              <div className={styles.tooltip}>
                                {item?.value[0]}
                                {item?.value?.length > 1 && (
                                  <>
                                    <span className={styles.tooltipText}>
                                      {item?.value?.join(', ')}
                                    </span>
                                    <span>, {item?.value[1]?.slice(0, 3)}...</span>
                                  </>
                                )}
                              </div>
                            )
                            : (
                              `${item?.value}`
                            )}
                        </div>
                      </div>
                    )
                  })}
                  <div className={styles.chevronContainer}>
                    {itemsWithOperation?.length > 3 && index !== toggleExpand.id && (
                      <button className={styles.chevron} onClick={() => handleToggleExpand(itemsWithOperation, index)}>
                        <img src='/assets/downChevron.svg' alt="downChevron" />
                      </button>
                    )}
                  </div>
                </div>
                {toggleExpand?.show && index === toggleExpand?.id && renderChunks(toggleExpand?.data, index)}
              </div>
            )
          })}
        </div>
      </div >
    }
  }

  return <div className={styles.orderDetailsCtn} data-testid='order-details'>
    <div className={styles.orderDetailsTitle}>
      Order Details
      {orderDetails?.poll && <div className={styles.reloadIcon} data-testid='reload'> <Icon title='Refresh' name='arrow-clockwise' onClick={handleStageRefresh} className={`${isRotating ? styles.rotateIcon : ''}`} /></div>}
    </div>
    <RejectModal rejectModal={rejectModal} handleRejectModalClose={() => setRejectModal(false)} handleReject={handleReject} />
    {renderContent()}
    {showReviewModal && <ReviewModal showModal={showReviewModal} handleModalClose={handleReviewModalClose} handleOrderStageService={handleOrderStageService} isDisable={isReviewButtonDisabled} details={currentButton} currentStage={orderDetails.current_stage} />}
    {showCommitModal && <CommitModal commitModal={showCommitModal} handleModalClose={handleCommitModalClose} handleCommit={handleCommitModal} />}
    {showToastObj.show &&
      <Toast
        showToast={showToastObj.show}
        setShowToast={setShowToastObj}
        message={showToastObj.message}
        type={showToastObj.type} />
    }
  </div>
}
export default OrderDetailsPage
