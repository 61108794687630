import React, { useEffect, useState } from 'react'
import styles from './FirewallPage.css'
import { Button, Input } from '@anchor/react-components'
import Toast from '../../commonComponents/Toast/Toast.js'
import axiosInstance from '../../utility/axiosInstance.js'

const FirewallPage = () => {
  const [fileName, setFileName] = useState('')
  const [file, setFile] = useState(null)
  const [toggleGenerateConfig, setToggleGenerateConfig] = useState(false)
  const [showToastObj, setShowToastObj] = useState({ show: false, type: '', message: '' })

  useEffect(() => {
    if (fileName && file) {
      setToggleGenerateConfig(true)
    } else {
      setToggleGenerateConfig(false)
    }
  }, [fileName, file])

  const handleGenerateConfig = async () => {
    const formData = new FormData()
    formData.append('file', file)
    try {
      const response = await axiosInstance.post(`${process.env.FIREWALL_GENERATE}?filename=${fileName}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'blob'
      })
      const contentType = response.headers['content-type']
      if (contentType === 'application/zip') {
        const contentDisposition = response.headers.get('content-disposition')
        let filename = 'download.zip'
        // Default filename if not found
        if (contentDisposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(contentDisposition)
          filename = matches?.[1]?.replace(/['"]/g, '') || filename
        }

        const blob = new Blob([response.data], { type: 'application/zip' })
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }
    } catch (err) {
      const errorObj = await err.response?.data?.text()
      const errorText = JSON.parse(errorObj)?.errorText
      setShowToastObj({ show: true, type: 'error', message: errorText || 'Error Occurred' })
    }
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    setFile(selectedFile)
  }
  const handleWarehouseChange = (event) => {
    setFileName(event.target.value)
  }
  return <>
    <div className={styles.title}>
      Firewall Config
    </div>
    <div className={`${styles.formCtn}`}>
      <div className={styles.inputCtn}>
        <Input
          fit="medium"
          id="textInput"
          label="File Name"
          className={styles.inputField}
          maxLength={10}
          onChange={handleWarehouseChange}
          placeholder="Enter File Name"
          required
          type="text"
          value={fileName}
          variant="default"
          disabled={false}
        />

        <div className={styles.inputFileCtn}>
          <div className={styles.inputFileLabel}>File <span className={styles.requiredStar}>*</span></div>
          <div className={styles.inputFieldCtn}>
            <label htmlFor="file-upload" className={styles.labelCtn}>
              {file
                ? <span className={styles.fileName}> {file.name} </span>
                : <span className={styles.placeholderFile}>Upload Excel File</span>}
              <div className={styles.uploadBtn}><img src='/assets/whiteUpChevron.svg' alt="icon" />Upload</div>

            </label>
            <input
              type="file"
              accept=".xlsx, .xls"
              disabled={false}
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="file-upload"
              data-testid="file-upload"
            />
          </div>
        </div>

      </div>
      <Button
        appearance="default"
        justifyItems="center"
        label="Generate Config"
        name="primary"
        className={styles.generateBtn}
        onClick={handleGenerateConfig}
        title="Generate Config"
        variant="filled"
        disabled={!toggleGenerateConfig}
      />
    </div>
    {showToastObj.show
      ? <Toast
        showToast={showToastObj.show}
        setShowToast={setShowToastObj}
        message={showToastObj.message}
        type={showToastObj.type} />
      : null}
  </>
}
export default FirewallPage
