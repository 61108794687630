import { createSlice } from '@reduxjs/toolkit'

function generateLabel (segment) {
  return segment.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
}

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState: {
    breadcrumbObject: {
      separator: '/',
      mainObject: {
        dashboard: {
          label: 'Dashboard',
          path: '/',
          breadcrumbLocator: 'dashboard'
        },
        catalog: {
          label: 'Catalog',
          // path: '/catalog',
          breadcrumbLocator: 'catalog'
        },
        analyse: {
          label: 'Analyse',
          path: '/analyse',
          breadcrumbLocator: 'analyse'
        },
        track: {
          label: 'Track',
          // path: '/track',
          breadcrumbLocator: 'track'
        },
        trackOrders: {
          label: 'Track Orders',
          path: '/trackorders',
          breadcrumbLocator: 'track.trackOrders'
        },
        audit: {
          label: 'Audit',
          path: '/audit',
          breadcrumbLocator: 'track.audit'
        },
        resource: {
          label: 'Resource',
          path: '/resource',
          breadcrumbLocator: 'resource'
        },
        migrateWarehouse: {
          label: 'Migrate Warehouse',
          path: '/migratewarehouse',
          breadcrumbLocator: 'migrateWarehouse'
        },
        firewallConfig: {
          label: 'Firewall Config',
          path: '/firewall',
          breadcrumbLocator: 'firewallConfig'
        }
      },
      activeItem: 'dashboard',
      className: 'breadcrumb-story',
      isActiveItemClickable: true
    }

  },
  reducers: {
    newBreadcrumb: (state, action) => {
      state.breadcrumbObject.mainObject = {
        ...state.breadcrumbObject.mainObject, ...action.payload.object
      }
      state.breadcrumbObject.activeItem = action.payload.activeItem
    },
    updateBreadCrumb: (state, action) => {
      state.breadcrumbsArr = action.payload
    },
    updateCurrentBreadCrumbPath: (state, action) => {
      state.breadcrumbObject.activeItem = action.payload
    },
    catalogBreadCrumb: (state, action) => {
      const { path, tab } = action.payload
      const storedBreadcrumbs = JSON.parse(sessionStorage.getItem('catalogBreadcrumbs'))
      if (storedBreadcrumbs) {
        state.breadcrumbObject.mainObject = storedBreadcrumbs
      }
      const segments = path.split('/').filter(Boolean)
      let currentPath = ''
      const breadcrumbLocator = []
      segments.forEach((segment, index) => {
        breadcrumbLocator.push(segment)
        const locator = breadcrumbLocator.join('.')
        const currentSegment = state.breadcrumbObject.mainObject[segment]
        currentPath += `/${segment}`
        const path = (segment === tab) ? currentPath.split('/').slice(0, -1).join('/') : currentPath
        if (!currentSegment || currentSegment.path !== path) {
          state.breadcrumbObject.mainObject = {
            ...state.breadcrumbObject.mainObject,
            [segment]: {
              label: generateLabel(segment),
              path,
              bredCrumbName: generateLabel(segment),
              breadcrumbLocator: locator
            }
          }
        }
      })
      sessionStorage.setItem('catalogBreadcrumbs', JSON.stringify(state.breadcrumbObject.mainObject))
      // Set the active item to the last segment
      state.breadcrumbObject.activeItem = segments[segments.length - 1]
    }
  }
})

export const { updateBreadCrumb, newBreadcrumb, updateCurrentBreadCrumbPath, catalogBreadCrumb } = breadcrumbsSlice.actions
export default breadcrumbsSlice.reducer
