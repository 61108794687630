import { Input } from '@anchor/react-components/dist/lib/components/index.js'
import PropTypes from 'prop-types';
import React, { useState } from "react";

export default function Password({ className, fit, label, onChange, placeholder, required, error, errorMessage, id, value, variant, disabled, textRef }) {

    const [show, setShow] = useState(false);

    const handleToggle = () => {
        setShow(!show)
    }

    return (
        <Input
            type={show ? 'text' : 'password'}
            label={label}
            name={label}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            autoComplete="current-password"
            key={id}
            disabled={disabled}
            required={required}
            className={className}
            fit={fit}
            error={error}
            errorMessage={errorMessage}
            ref={textRef}
            icon={show ? 'eye' : 'eye-slash'}
            iconPosition="right"
            onIconClick={handleToggle}
        />
    );
}

Password.propTypes = {
    className: PropTypes.string,
    fit: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    textRef: PropTypes.any
}