import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@anchor/react-components'
import { Typography, Grid } from '@mui/material'
import styles from './DetailsModal.css'
import { FAILED, REJECTED } from '../../constants'

const DetailModal = ({ isOpen, handleClose, data, fields }) => {
  const labelRefs = useRef([]);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    if (labelRefs.current.length) {
      const maxWidth = Math.max(
        ...labelRefs.current.map((ref) => ref?.offsetWidth || 0)
      );

      setLabelWidth(maxWidth);
    }
  }, [fields]);

  return (

    <Modal
      open={isOpen}
      heading="More Details"
      onClose={handleClose}
      dimension='medium'
      showCloseIcon
      height='500px'
      zindex={998}
    >
      <Grid container spacing={2} className={styles.gridContainer}>

        {fields?.map((field, index) => (
          <Grid item xs={12} className={styles.gridItem} key={`${index}-${field.field}`}>
            <Typography className={styles.label} ref={(el) => (labelRefs.current[index] = el)} style={{ width: `${labelWidth}px` }} gutterBottom>{field.headerName}</Typography>
            <Typography className={styles.colon} gutterBottom>:</Typography>
            {[FAILED, REJECTED].includes(data[field.field])
              ? (<div className={styles.status}>
                <div>{data[field.field]}</div>
                <div>Description:</div>
                <div>{data?.description}</div>
              </div>)
              : <Typography className={styles.value} gutterBottom>{data[field.field]}</Typography>
            }
          </Grid>
        ))}
      </Grid>
    </Modal>
  )
}

export default DetailModal

DetailModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
  fields: PropTypes.array
}
