import React, { useEffect, useState } from 'react'
import styles from './TopNavbar.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { newBreadcrumb } from '../../redux/features/breadcrumbsSlice.js'
import { clickSidebarItem, toggleSubmenu, toggleUserScopes } from '../../redux/features/leftNavbarSlice.js'
import { fetchCartData } from '../../redux/features/sitePageSlice.js'
import { Avatar, Icon, Popover } from '@anchor/react-components/dist/lib/components/index.js'

const TopNavbar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cartData = useSelector((state) => state.sitePage.cartData)
  const [cartLength, setCartLength] = useState(0)
  const [userDetails, setUserDetails] = useState(null)
  const name = sessionStorage.getItem('name')
  const email = sessionStorage.getItem('email')

  useEffect(() => {
    setCartLength(cartData?.items?.length ? cartData.items.length : 0)
  }, [cartData])

  const getShortName = (fullName) => {
    const nameParts = fullName.trim().split(/\s+/)
    let shortName = ''
    for (const part of nameParts) {
      shortName += part[0].toUpperCase()
    }
    return shortName
  }

  useEffect(() => {
    if (name && email) {
      setUserDetails({ name, email, shortName: getShortName(name) })
    }
    dispatch(fetchCartData())
  }, [name, email])

  const handleCartClick = () => {
    navigate('/basket')
    dispatch(clickSidebarItem(''))
    dispatch(toggleSubmenu(false))
    dispatch(newBreadcrumb({
      activeItem: 'basket',
      object: {
        basket: {
          label: 'Basket',
          path: '/basket',
          breadcrumbLocator: 'Basket'
        }
      }
    }))
  }

  const handleLogoutClick = () => {
    sessionStorage.clear()
    navigate('/')
    window.location.reload()
  }

  const renderMenuComponent = () => {
    return <div className={styles.popover}>
      <div className={styles.userDetails}>
        <Avatar
          appearance="color1"
          fit="medium"
          hiddentooltip
          imagesrc=""
          info=""
          initials={userDetails.shortName}
        />
        <div>
          <div className={styles.userName}>{userDetails.name}</div>
          <div>{userDetails.email}</div>
        </div>
      </div>
      <button className={styles.logoutBtn} onClick={() => { dispatch(toggleUserScopes(true)) }}>
        <div>Scopes</div>
      </button>
      <button className={styles.logoutBtn} onClick={handleLogoutClick}>
        <Icon name="arrow-from-left" />
        <div>Logout</div>
      </button>
    </div>
  }

  return (
    <div className={styles.parentCtn}>
      <img src='/assets/Provigenius-Light Theme.svg' alt='logo' />
      {/* <input className={styles.globalSearch} placeholder='Search' /> */}
      <span className={styles.navComp}>
        <button className={styles.cartCtn} onClick={handleCartClick} data-testid='cartBtn'>
          <img src='/assets/cart.svg' className={`${styles.navigationIcons} ${styles.cartIcon}`} alt='cart' />
          {cartLength > 0 ? <span className={styles.cartCount}>{cartLength}</span> : null}
        </button>
        {/* <img src='/assets/settings.svg' className={styles.navigationIcons} />
                <img src='/assets/bell.svg' className={styles.navigationIcons} /> */}
        {userDetails && <Popover
          fit="medium"
          maxheight="200px"
          onHide={function noRefCheck() { }}
          onShow={function noRefCheck() { }}
          position="top-center"
          trigger="click"
          width="260px"
        >
          <Avatar
            data-testid='avatar'
            appearance="color1"
            fit="medium"
            hiddentooltip
            imagesrc=""
            info=""
            className={styles.avatar}
            initials={userDetails.shortName}
          />
          {renderMenuComponent()}
        </Popover>}
      </span>
    </div >
  )
}
export default TopNavbar
