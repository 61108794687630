import React, { useEffect, useState } from 'react'
import { Tabs, Button, Tooltip, LoadingIndicator, Icon } from '@anchor/react-components'
import styles from './Review.css'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Dialog } from '@mui/material'
import axiosInstance from '../../utility/axiosInstance.js'

export function syntaxHighlight(json) {
  if (!json) return '' // no JSON from response

  // Escape HTML characters
  json = json.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')

  // Define regular expressions for different JSON components
  const stringRegex = /"(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"/g
  const keyRegex = /"(\s*:)/g
  const numberRegex = /-?\d+(\.\d+)?([eE][+]?[-]?\d+)?/g
  const booleanRegex = /\b(true|false)\b/g
  const nullRegex = /\bnull\b/g

  // Replace and highlight strings
  json = json.replace(stringRegex, match => {
    let cls = styles.strings
    if (match.endsWith(':')) {
      cls = styles.key
    }
    return '<span class="' + cls + '">' + match + '</span>'
  })

  // Highlight keys
  json = json.replace(keyRegex, match => {
    return '<span class="' + styles.key + '">' + match + '</span>'
  })

  // Highlight numbers
  json = json.replace(numberRegex, match => {
    return '<span class="' + styles.number + '">' + match + '</span>'
  })

  // Highlight booleans
  json = json.replace(booleanRegex, match => {
    return '<span class="' + styles.boolean + '">' + match + '</span>'
  })

  // Highlight null values
  json = json.replace(nullRegex, match => {
    return '<span class="' + styles.null + '">' + match + '</span>'
  })

  return json
}

const ReviewModal = ({ showModal, handleModalClose, handleOrderStageService, isDisable = false }) => {
  const orderDetails = useSelector((state) => state.trackOrders.orderDetails)
  const [reviewData, setReviewData] = useState([])
  const [reviewJSON, setReviewJSON] = useState({})
  const [loading, setLoading] = useState(false)
  const [copied, setCopied] = useState(false)

  const fetchReview = async () => {
    try {
      const response = await axiosInstance.get(`${process.env.API_GATEWAY}/dryrun-review?uuid=${orderDetails.order_id}`)
      const data = response.data
      setReviewData([
        ...data.map((item, i) => {
          return {
            ...item,
            id: i,
            isVisited: false,
            active: i === 0
          }
        })
      ])
      setReviewJSON(data[0]?.diff || {})
      setLoading(false)
    } catch (error) {
      console.error('Error fetching review:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchReview()
  }, [])

  const formatTerraformOutput = (output) => {
    // Replace ANSI color codes with corresponding HTML tags
    return output
      .replace(/\u001b\[32m/g, '<span style="color: green;">') // Green
      .replace(/\u001b\[31m/g, '<span style="color: red;">') // Red
      .replace(/\u001b\[0m/g, '</span>') // Reset
      .replace(/\u001b\[1m/g, '<span>') // Bold
      .replace(/\u001b\[22m/g, '</span>') // End Bold
      .replace(/\n/g, '<br>') // Newline
  }

  const handleTabClick = (event) => {
    setReviewData(prevTabsData =>
      prevTabsData.map(tab => ({
        ...tab,
        isVisited: tab.isVisited || tab.id === event.selectedItemId,
        active: tab.id === event.selectedItemId // Set active to true for the clicked tab
      }))
    )
    const clickedTab = reviewData.find(tab => tab.id === event.selectedItemId)
    if (clickedTab) {
      setReviewJSON({ ...clickedTab?.diff || {} })
    }
  }

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(typeof reviewJSON === 'string' ? reviewJSON : JSON.stringify(reviewJSON, undefined, 4))
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  function renderContent() {
    if (loading) {
      return <div className={styles.loader}><LoadingIndicator /></div>
    } else if (Object.keys(reviewData).length === 0) {
      return <div>Error Fetching Data</div>
    } else {
      return <>
        <Tabs
          className={styles.tabsCtn}
          items={reviewData}
          onClick={handleTabClick}
          type="default"
          role="tab"
        />
        <button className={`${styles.copyButton} ${styles.tooltip}`} onClick={copyToClipBoard}>
          <span className={styles.tooltipText}>{copied ? '✓ Copied to clipboard' : 'Copy to clipboard'}</span>
          <Icon className={styles.copyIcon} name='copy' />
        </button>
        <div className={styles.jsonCtn}>
          {typeof reviewJSON === 'string'
            ? <div dangerouslySetInnerHTML={{ __html: formatTerraformOutput(reviewJSON) }} />
            : <pre dangerouslySetInnerHTML={{ __html: syntaxHighlight(JSON.stringify(reviewJSON, undefined, 4)) }} />
          }

        </div>
        <div className={styles.buttonCtn}>
          {isDisable ? <div>
            <Button
              appearance="error"
              id="primary"
              justifyItems="left"
              label="close"
              name="primary"
              onClick={handleModalClose}
              title="Button"
              variant="outlined"
              className={styles.rejectBtn}
            />
          </div> :
            <div>
              <Button
                appearance="error"
                id="primary"
                justifyItems="left"
                label="Reject"
                name="primary"
                onClick={() => handleOrderStageService('review', 'Rejected', '')}
                title="Button"
                variant="outlined"
                className={styles.rejectBtn}
              />
              <Tooltip
                appearance="default"
                content="Confirm or reject the review, please visit all the tabs."
                position="right"
                width="auto"
              >
                <Button
                  appearance="default"
                  id="primary"
                  justifyItems="left"
                  label="Confirm"
                  name="primary"
                  onClick={() => handleOrderStageService('review', 'Success', '')}
                  variant="filled"
                />
              </Tooltip>
            </div>}
        </div>
      </>
    }
  }

  return <Dialog classes={{ paper: styles.modalCtn }} open={showModal} onClose={handleModalClose} title="Review">
    {renderContent()}
  </Dialog>
}

export default ReviewModal

ReviewModal.propTypes = {
  showModal: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleOrderStageService: PropTypes.func,
  isDisable: PropTypes.bool
}
