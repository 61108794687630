import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  DASHBOARD
} from '../../constants.js'
import { navItems } from '../../staticData/NavItems.js'
import axiosInstance from '../../utility/axiosInstance.js'

export const fetchCatalogNav = createAsyncThunk(
  'fetchCatalogNav',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`${process.env.API_GATEWAY}/schema?path=catalog`)
      const data = res.data
      return data
    } catch (error) {
      console.error('Error fetching initial data:', error)
      return rejectWithValue({
        message: error.message,
        code: error.code
      })
    }
  }
)

export const fetchMetadataNav = createAsyncThunk(
  'fetchMetadataNav',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`${process.env.API_GATEWAY}/schema?path=metadata`)
      const data = res.data
      return data
    } catch (error) {
      console.error('Error fetching initial data:', error)
      return rejectWithValue({
        message: error.message,
        code: error.code
      })
    }
  }
)

export const fetchUserPermissions = createAsyncThunk(
  'fetchUserPermissions',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`${process.env.API_GATEWAY}/user-permissions`)
      const data = res.data
      return data
    } catch (error) {
      console.error('Error fetching initial data:', error)
      return rejectWithValue({
        message: error.message,
        code: error.code
      })
    }
  }
)

const loadNavItemsFromSession = () => {
  const storedNavItems = sessionStorage.getItem('navItems')
  return storedNavItems ? JSON.parse(storedNavItems) : navItems
}

const loadRolesFromSession = () => {
  const storedRoles = sessionStorage.getItem('roles')
  return storedRoles ? JSON.parse(storedRoles) : []
}

export const leftNavbarSlice = createSlice({
  name: 'leftMenu',
  initialState: {
    isSidebarOpen: true,
    isSubmenuOpen: false,
    selectedSidebarItem: DASHBOARD,
    selectedSubmenuItem: '',
    userPermissions: undefined,
    showUserScopes: false,
    currentItemPermission: loadRolesFromSession(),
    navItems: loadNavItemsFromSession()
  },
  reducers: {
    toggleSidebar: (state, action) => {
      state.isSidebarOpen = action.payload
    },
    toggleSubmenu: (state, action) => {
      state.isSubmenuOpen = action.payload
    },
    clickSidebarItem: (state, action) => {
      state.selectedSidebarItem = action.payload.item
    },
    clickSubMenuItem: (state, action) => {
      state.selectedSubmenuItem = action.payload.item
    },
    handlePermission: (state, action) => {
      const roles = action.payload?.roles?.map((item) => item.name)
      state.currentItemPermission = roles
      sessionStorage.setItem('roles', JSON.stringify(roles))
    },
    toggleUserScopes: (state, action) => {
      state.showUserScopes = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalogNav.fulfilled, (state, action) => {
        // Find the submenuHeaders with name 'Products'
        const catalogNavItem = state.navItems?.find((item) => item?.name === 'Catalog')

        // Check if the catalogNavItem is found
        if (catalogNavItem) {
          catalogNavItem.subMenuItems = action?.payload?.map((item) => {
            return { ...item, bredCrumbName: item?.id }
          })
        }
        sessionStorage.setItem('navItems', JSON.stringify(state.navItems))
      })
      .addCase(fetchCatalogNav.rejected, (state, action) => {
        console.error('Error fetching track orders:', action?.payload)
      })
      .addCase(fetchMetadataNav.fulfilled, (state, action) => {
        // Find the submenuHeaders with name 'Products'
        const metadataNavItem = state.navItems?.find((item) => item?.name === 'Metadata')

        // Check if the metadataNavItem is found
        if (metadataNavItem) {
          metadataNavItem.subMenuItems = action?.payload?.map((item) => {
            return { ...item, bredCrumbName: item?.id }
          })
        }
        sessionStorage.setItem('navItems', JSON.stringify(state.navItems))
      })
      .addCase(fetchMetadataNav.rejected, (state, action) => {
        console.error('Error fetching track orders:', action?.payload)
      })
      .addCase(fetchUserPermissions.fulfilled, (state, action) => {
        state.userPermissions = action.payload.resource
      })
  }
})
export const { toggleSidebar, toggleSubmenu, clickSidebarItem, clickSubMenuItem, handlePermission, toggleUserScopes } = leftNavbarSlice.actions
export default leftNavbarSlice.reducer
