import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_ERROR, LOADING, SUCCESS } from '../../constants'
import axiosInstance from '../../utility/axiosInstance'

export const fetchConfig = createAsyncThunk(
    'fetchConfig',
    async ({ hostName, configType }, { rejectWithValue, getState }) => {
        try {
            const response = await axiosInstance.post(`${process.env.API_GATEWAY}/aegis-backup/get-backup`, {
                hostname_or_ip: hostName
            })
            return { data: response.data, hostName, configType }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const fetchCompareConfig = createAsyncThunk(
    'fetchCompareConfig',
    async ({ hostName, configType, compareDate }, { rejectWithValue, getState }) => {
        try {
            const response = await axiosInstance.post(`${process.env.API_GATEWAY}/aegis-backup/compare-backup`, {
                hostname_or_ip: hostName,
                from_date: compareDate.from,
                to_date: compareDate.to
            })
            return { data: response.data, hostName, configType }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)


export const aegisBackupSlice = createSlice({
    name: 'aegisBackupSlice',
    initialState: {
        data: {},
        hostName: '',
        fetchStatus: '',
        configType: '',
        error: '',
        siteName: '',
        region: '',
        role: '',
        deviceType: '',
        content: ''
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload.data
            state.hostName = action.payload.hostName
            state.configType = action.payload.configType
        },
        setLoading: (state, action) => {
            state.fetchStatus = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchConfig.pending, (state, action) => {
                state.fetchStatus = LOADING
            })
            .addCase(fetchConfig.fulfilled, (state, action) => {
                state.data = action.payload?.data || {}
                state.hostName = action.payload?.hostName || ''
                state.configType = action.payload.configType
                state.deviceType = action.payload?.data?.latest_version?.device_type || ''
                state.region = action.payload?.data?.latest_version?.region_name || ''
                state.siteName = action.payload?.data?.latest_version?.site_name || ''
                state.role = action.payload?.data?.latest_version?.role || ''
                state.content = action.payload?.data?.latest_version?.content || ''
                state.fetchStatus = SUCCESS
            })
            .addCase(fetchConfig.rejected, (state, action) => {
                state.error = action.payload?.message || 'Something went wrong'
                state.fetchStatus = API_ERROR
            })
            .addCase(fetchCompareConfig.pending, (state, action) => {
                state.fetchStatus = LOADING
            })
            .addCase(fetchCompareConfig.fulfilled, (state, action) => {
                state.data = action.payload?.data || {}
                state.hostName = action.payload?.hostName || ''
                state.configType = action.payload.configType
                state.deviceType = action.payload?.data?.device_type || ''
                state.region = action.payload?.data?.region_name || ''
                state.siteName = action.payload?.data?.site_name || ''
                state.role = action.payload?.data?.role || ''
                state.content = action.payload?.data?.diff_file_content || ''
                state.fetchStatus = SUCCESS
            })
            .addCase(fetchCompareConfig.rejected, (state, action) => {
                state.error = action.payload?.message || 'Something went wrong'
                state.fetchStatus = API_ERROR
            })
    }
})
export const { setData, setLoading } = aegisBackupSlice.actions
export default aegisBackupSlice.reducer
