import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import ContextMenu from '../DataGrid/ContextMenu/ContextMenu.js'
import { MODIFY, DELETE, MORE_DETAILS } from '../../constants.js'
import { useDispatch, useSelector } from 'react-redux'
import { handleCreateForm, handleIsCreatePopupOpen } from '../../redux/features/sitePageSlice.js'
import { Icon } from '@anchor/react-components'
import PropTypes from 'prop-types'
import DetailModal from '../DetailsModal/DetailsModal.js'

const DataTable = ({ handleLeftClick, tableData, siteColumnNames, apiRef, contextMenuOptions, checkCartData = () => { } }) => {
  const dispatch = useDispatch()
  const [contextMenu, setContextMenu] = useState(null)
  const [selectedRow, setSelectedRow] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false);
  const createPopupForm = useSelector((state) => state.sitePage.createForm)
  const selectedTab = useSelector((state) => state.sitePage.selectedTab)

  const title = selectedTab.title
  tableData = title in tableData ? tableData[title] : tableData
  const handleClose = () => {
    setContextMenu(null)
  }
  const handleContextMenu = (event, row) => {
    event.preventDefault()

    // Do something with the rowData
    const _selectedRowId = event.currentTarget.getAttribute('data-id')
    const rowData = apiRef.current.getRow(_selectedRowId)
    setSelectedRow(rowData)
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    )
  }
  const isFieldDisabled = (key, primaryKeys, itemClicked) => {
    return primaryKeys.includes(key) || itemClicked === DELETE
  }

  const updateField = (key, itemClicked, primaryKeys) => {
    const updatedField = {
      ...createPopupForm[key],
      value: selectedRow?.[key] ?? createPopupForm[key].value,
      disabled: itemClicked === 'Create' ? false : isFieldDisabled(key, primaryKeys, itemClicked)
    }
    return updatedField
  }

  const onMenuClick = (itemClicked) => {
    if (itemClicked === MODIFY || itemClicked === DELETE) {
      const updatedObj = {}
      const primaryKeys = selectedTab.primary_field.split(',')
      for (const key in createPopupForm) {
        updatedObj[key] = updateField(key, itemClicked, primaryKeys)
      }
      dispatch(handleCreateForm(updatedObj))
      const heading = itemClicked === MODIFY ? 'Modify' : 'Delete'
      dispatch(handleIsCreatePopupOpen({ open: true, heading }))
    } else if (itemClicked === MORE_DETAILS) {
      setIsModalOpen(true)
    }
    handleClose()
  }

  const handleRowClick = (item) => {
    setSelectedRow(item)
    handleLeftClick(item)
  }

  const renderCell = (params) => {
    let icon
    // Determine which icon to display based on the value of a certain parameter
    switch (params.row.method) {
      case 'Create':
        icon = (
          <div style={{ backgroundColor: 'green', borderRadius: '50%', width: '24px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Icon name="plus" style={{ color: 'white' }} />
          </div>
        )
        break
      case 'Modify':
        icon = (
          <div>
            <Icon name="pencil" style={{ color: 'orange' }} />
          </div>
        )
        break
      case 'Delete':
        icon = (
          <div style={{ backgroundColor: 'red', borderRadius: '50%', width: '24px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Icon name="minus" style={{ color: 'white' }} />
          </div>
        )
        break
      default:
        icon = null
    }

    return icon || null
  }

  const adjustColumn = (item) => {
    if (siteColumnNames.length > 3) {
      const { flex, ...rest } = item;
      const headerText = item.headerName || item.field || '';
      const dynamicWidth = Math.max((headerText.length + 5) * 10, 100);
      return { ...rest, width: dynamicWidth };
    }
    return item;
  };

  const columns = tableData.some(item => Object.hasOwn(item, 'method'))
    ? [
      { field: 'method', headerName: '', width: 100, renderCell },
      ...siteColumnNames.map(adjustColumn),
    ]
    : siteColumnNames.map(adjustColumn);


  return <>
    <Box
      sx={{
        // height: height,
        width: '100%',
        overflow: 'auto',
        '.MuiDataGrid-columnHeader': {
          background: 'var(--mds_brand_appearance_neutral_subtle_background-color)',
          color: '#141414',
          fontWeight: 700,
          fontSize: '16px',
          fontFamily: ' Maersk Text',
          fontStyle: 'normal',
          lineHeight: '24px',
          borderRight: '2px solid #0000000f',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          textAlign: 'center',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          lineHeight: 'normal',
        },
        '.MuiDataGrid-row': {
          color: '#141414',
          fontWeight: 400,
          fontSize: '14px',
          fontFamily: ' Maersk Text',
          lineHeight: '20px'
        },
        '.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        '& .MuiDataGrid-cell': {
          borderRight: '1px solid #ddd'
        },
        '& .MuiDataGrid-cell:last-child': {
          borderRight: 'none'
        }
      }}
    >
      <DataGrid
        apiRef={apiRef}
        rows={siteColumnNames.length ? tableData : []}
        columns={columns}
        onRowClick={handleRowClick}
        getRowId={(row) => {
          return row[selectedTab?.primary_field] || row?.id || row[selectedTab?.columnNames[0]?.field] || 1
        }}
        slotProps={{
          row: {
            onContextMenu: handleContextMenu,
            style: { cursor: 'context-menu' }
          }
        }}
        showCellRightBorder
        showColumnRightBorder
        disableExtendRowFullWidth
      />
    </Box>
    <ContextMenu handleClose={handleClose} contextMenu={contextMenu} contextMenuList={contextMenuOptions} onMenuClick={onMenuClick} />
    <DetailModal
      isOpen={isModalOpen}
      handleClose={() => { setIsModalOpen(false) }}
      data={selectedRow}
      fields={siteColumnNames.map((item) => ({ field: item.field, headerName: item.headerName || item.header_name }))}
    />
  </>
}
export default DataTable

DataTable.propTypes = {
  handleLeftClick: PropTypes.func,
  tableData: PropTypes.array,
  siteColumnNames: PropTypes.array,
  apiRef: PropTypes.any,
  contextMenuOptions: PropTypes.array,
  checkCartData: PropTypes.func
}
