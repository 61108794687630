export const pieChartOptions = {
  chart: {
    width: 380,
    type: 'pie'
  },
  plotOptions: {
    pie: {
      expandOnClick: true
    }
  },
  dataLabels: {
    style: {
      colors: ['#141414']
    },
    dropShadow: {
      enabled: false
    },
    formatter: function (val, opts) {
      return opts.w.config.series[opts.seriesIndex]
    }
  },
  legend: {
    show: false
  },
  stroke: {
    show: false
  },
  tooltip: {
    fillSeriesColor: false
  },
  colors: ['#B5B5D1', '#69EED6', '#6CBCFF', '#EADE70', '#D197FF', '#FFA979', '#d690ad', '#9CFFA0', '#78a2eb', '#91b38e']
}

export const lineChartOptions = {
  chart: {
    type: 'line',
    height: 200,
    toolbar: { show: false }
  },
  stroke: {
    width: 2,
    colors: ['#40739E', '#B53471']
  },
  legend: {
    show: false
  },

  grid: {
    show: true,
    xaxis: {
      lines: {
        show: true
      }
    },
    yaxis: {
      lines: {
        show: true
      }
    }

  },
  tooltip: { fillSeriesColor: false }
}
