import React, { useEffect, useState } from 'react'
import styles from './MigrateWarehouse.css'
import { Button, Input, ReactSelect } from '@anchor/react-components'
import Toast from '../../commonComponents/Toast/Toast.js'
import axiosInstance from '../../utility/axiosInstance.js'

const MigrateWarehouse = () => {
  const [selectValue, setSelectValue] = useState([])
  const [warehouseId, setWarehouseId] = useState('')
  const [file, setFile] = useState(null)
  const [toggleGenerateConfig, setToggleGenerateConfig] = useState(false)
  const [showToastObj, setShowToastObj] = useState({ show: false, type: '', message: '' })

  useEffect(() => {
    if (selectValue && warehouseId && file) {
      setToggleGenerateConfig(true)
    } else {
      setToggleGenerateConfig(false)
    }
  }, [selectValue, warehouseId, file])

  const selectDropdown = [
    {
      id: 'Shanghai',
      label: 'Shanghai',
      value: 'Shanghai'
    },
    {
      id: 'Shenzhen',
      label: 'Shenzhen',
      value: 'Shenzhen'
    },
    {
      id: 'UAE',
      label: 'UAE',
      value: 'UAE'
    },
    {
      id: 'Japan',
      label: 'Japan',
      value: 'Japan'
    },
    {
      id: 'Philippines',
      label: 'Philippines',
      value: 'Philippines'
    },
    {
      id: 'Thailand',
      label: 'Thailand',
      value: 'Thailand'
    },
    {
      id: 'Taiwan',
      label: 'Taiwan',
      value: 'Taiwan'
    }
  ]

  const handleGenerateConfig = async () => {
    const formData = new FormData()
    formData.append('file', file)
    let response = null

    try {
      response = await axiosInstance.post(`${process.env.MIGRATE_WAREHOUSE}?warehouse=${warehouseId}&cloud_edge_site=${selectValue.value}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Expose-Headers': 'Content-Disposition'
        },
        responseType: 'blob'

      })
      const contentType = response.headers['content-type']
      if (contentType === 'application/zip') {
        const contentDisposition = response.headers.get('content-disposition')
        let filename = 'download.zip'
        // Default filename if not found
        if (contentDisposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(contentDisposition)
          filename = matches?.[1]?.replace(/['"]/g, '') || filename
        }
        const blob = new Blob([response.data], { type: 'application/zip' })
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }
    } catch (err) {
      const errorObj = await err.response?.data?.text()
      const errorText = JSON.parse(errorObj)?.errorText
      setShowToastObj({ show: true, type: 'error', message: errorText || 'Error Occurred' })
    }
  }

  const handleChange = (event) => {
    setSelectValue(event)
  }
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    setFile(selectedFile)
  }
  const handleWarehouseChange = (event) => {
    setWarehouseId(event.target.value)
  }
  return <>
    <div className={styles.title}>
      Migrate Warehouse
    </div>
    <div className={`${styles.formCtn}`}>
      <div className={styles.inputCtn}>
        <Input
          fit="medium"
          id="textInput"
          label="Warehouse"
          className={styles.inputField}
          maxLength={10}
          onChange={handleWarehouseChange}
          placeholder="Enter Warehouse Site ID"
          required
          type="text"
          value={warehouseId}
          variant="default"
          disabled={false}
        />
        <ReactSelect
          closeMenuOnSelect
          customNoOptionsMessage={<p>Not a valid option</p>}
          fit="medium"
          isClearable
          label="Cloud Edge Site"
          onChange={handleChange}
          onInputChange={function noRefCheck() { }}
          onMenuClose={function noRefCheck() { }}
          options={selectDropdown}
          orientation="vertical"
          placeholder="Select Site"
          required
          value={[selectValue?.id ? selectValue : '']}
          className={styles.inputField}
          variant="default"
        />
        <div className={styles.inputFileCtn}>
          <div className={styles.inputFileLabel}>File <span className={styles.requiredStar}>*</span></div>

          <div className={styles.inputFieldCtn}>
            <label htmlFor="file-upload" className={styles.labelCtn}>
              {file
                ? <span className={styles.fileName} title={file.name}> {file.name?.slice(0, 25) + '...'} </span>
                : <span className={styles.placeholderFile}>Upload Excel File</span>}
              <div className={styles.uploadBtn}><img src='/assets/whiteUpChevron.svg' alt='whiteUpChevron' />Upload</div>
            </label>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="file-upload"
              disabled={false}
            />
          </div>
        </div>
      </div>
      {/* <div className={styles.generateCtn}> */}
      <Button
        appearance="default"
        justifyItems="center"
        label="Generate Config"
        name="primary"
        className={styles.generateBtn}
        onClick={handleGenerateConfig}
        title="Generate Config"
        variant="filled"
        disabled={!toggleGenerateConfig}
      />
    </div>
    {/* </div> */}
    {showToastObj.show
      ? <Toast
        showToast={showToastObj.show}
        setShowToast={setShowToastObj}
        message={showToastObj.message}
        type={showToastObj.type} />
      : null}
  </>
}
export default MigrateWarehouse
