import React, { useEffect, useRef, useState } from "react";
import styles from "./Chatbot.css";
import chatbotIcon from '../../../public/assets/ChatbotIcon.svg'
import { Avatar, Icon } from '@anchor/react-components/dist/lib/components/index.js'
import axiosInstance from "../../utility/axiosInstance";
import { formatTerraformOutput } from "../../utility/formatReviewData";

const Chatbot = () => {
    const [messages, setMessages] = useState([
        { sender: "bot", text: "Hello! How can I assist you today?" },
    ]);
    const [input, setInput] = useState("");
    const fileInputRef = useRef(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isListening, setIsListening] = useState(false); // State to track voice recognition status
    const recognitionRef = useRef(null);
    const [copied, setCopied] = useState(false)
    const [loading, setLoading] = useState(false)
    const [apiData, setApiData] = useState([]);
    const aiAssistRef = useRef(null);
    const userInputsRef = useRef([]);

    useEffect(() => {
        if (aiAssistRef.current) {
            aiAssistRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [messages, loading]);

    useEffect(() => {
        const newMessages = [
            ...messages,
            ...apiData.map(item => [
                { sender: "bot", text: item.chatboat_summary }
            ]).flat()
        ];
        setMessages(newMessages)
    }, [apiData])

    const handleApi = async (content = input) => {
        try {
            setLoading(true)
            const response = await axiosInstance.post(`${process.env.API_GATEWAY}/chatbot`, { content, chat_history: userInputsRef.current })
            setApiData(response.data)
        } catch (error) {
            setMessages((prevMessages) => [
                ...prevMessages,
                { sender: "bot", text: 'Something went wrong! Please try again.' }
            ]);
        } finally {
            setLoading(false)
        }
    }

    const handleSend = () => {
        handleApi()
        if (input.trim()) {
            if (recognitionRef.current && isListening) {
                // Stop the recognition if it's already running
                recognitionRef.current.stop();
                setIsListening(false);
            }
            const newMessages = [...messages, { sender: "user", text: input }];
            setMessages(newMessages);
            setInput("");

            setTimeout(() => {
                setMessages((prevMessages) => [
                    ...prevMessages,
                ]);
            }, 1000);
        }
        userInputsRef.current.unshift(input)
    };

    const initializeSpeechRecognition = () => {
        if (!window.SpeechRecognition && !window.webkitSpeechRecognition) {
            alert("Your browser does not support speech recognition.");
            return;
        }

        const SpeechRecognition =
            window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognition = new SpeechRecognition();
        recognition.lang = "en-US";
        recognition.interimResults = true; // Show live transcription
        recognition.continuous = true; // Stops automatically after speaking

        recognition.onresult = (event) => {
            const transcript = Array.from(event.results)
                .map((result) => result[0].transcript)
                .join("");
            setInput(transcript);  // Append transcript to the input field
        };

        recognition.onerror = (event) => {
            console.error("Speech recognition error:", event.error);
            setIsListening(false);
        };

        recognition.onend = () => {
            setIsListening(false); // Stop listening when recognition ends
        };

        recognitionRef.current = recognition; // Store recognition instance in ref
    };

    const handleVoiceClick = () => {
        if (!recognitionRef.current) {
            initializeSpeechRecognition();
        }

        const recognition = recognitionRef.current;

        if (isListening) {
            // Stop the recognition if it's already running
            recognition.stop();
            setIsListening(false);
        } else {
            // Start recognition if it's not running
            setIsListening(true);
            recognition.start();
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") handleSend();
    };

    const handlePaste = (e) => {
        if (e.clipboardData.files.length > 0) {
            const file = e.clipboardData.files[0];
            handleFileUpload(file);
            e.preventDefault();
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            handleFileUpload(file);
        }
    };
    const handleFileUpload = (file) => {
        if (file?.type?.startsWith('image/')) {
            setUploadedFile(file);
        } else {
            console.error("Invalid file type. Only images are allowed.");
        }
    };

    const handleAttachmentClick = () => {
        fileInputRef.current.click();
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            handleFileUpload(file);
        }
    };

    const handleRemoveFile = () => {
        setUploadedFile(null);
    };

    const copyText = (text) => {
        navigator.clipboard.writeText(text)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    const refreshResponse = () => {
        const secondLastMessage = messages[messages.length - 2]?.text;
        handleApi(secondLastMessage);
        setMessages(prevMessages => {
            if (prevMessages[prevMessages.length - 1]?.sender === "bot") {
                return prevMessages.slice(0, -1);
            }
            return prevMessages;
        });
    }


    return (
        <div className={styles.chatbotContainer}>
            <div className={styles.chatbotContent}>
                {messages.map((message, index) => {
                    return <div className={`${message.sender === "bot" ? styles.botContainer : styles.userContainer}`} key={index + 1} ref={aiAssistRef}>
                        {message.sender === "bot" && <div className={styles.chatAvatar}>
                            <img src={chatbotIcon} className={styles.chatBotIcon} alt='chat-bot' />
                        </div>}
                        <div
                            key={`${index + 1}`}
                            className={`${styles.chatbotMessage} ${message.sender === "bot" ? styles.bot : styles.user}`}
                        >
                            {message.sender === "bot" && (
                                <div>
                                    <strong>AI Assist</strong>
                                    <br />
                                </div>
                            )}
                            <pre className={styles.textArea} dangerouslySetInnerHTML={{ __html: formatTerraformOutput(message?.text) }} />
                            {(message.sender === "bot" && index === messages.length - 1 && index !== 0) &&
                                <div className={styles.actionButtonContainer}>
                                    <div className={styles.copyButton} onClick={() => { copyText(message.text) }} title="Copy">
                                        <Icon name={`${copied ? 'check' : 'copy'}`} />
                                    </div>
                                    <div>
                                        <Icon name='arrow-clockwise' onClick={refreshResponse} title="Reload" />
                                    </div>
                                </div>
                            }
                        </div>
                        {message.sender !== "bot" && <div className={styles.chatAvatar}>
                            <Avatar
                                appearance="color1"
                                fit="medium"
                                hiddentooltip
                                imagesrc=""
                                info=""
                                initials={'AA'}
                                className={styles.avatar}
                            />
                        </div>}
                    </div>
                })}
                {loading &&
                    <div className={styles.botContainer}>
                        <div className={styles.chatAvatar}>
                            <img src={chatbotIcon} className={styles.chatBotIcon} alt='chat-bot' />
                        </div>
                        <div className={`${styles.chatbotMessage} ${styles.bot}`} >
                            <strong>AI Assist</strong>
                            <span className={styles.loading}>Loading</span>
                        </div>

                    </div>
                }
            </div>
            <div
                className={styles.chatbotInputContainer}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
            >
                {uploadedFile && (
                    <div className={styles.filePreview}>
                        <img
                            src={URL.createObjectURL(uploadedFile)}
                            alt={uploadedFile.name}
                            className={styles.thumbnail}
                        />
                        <button className={styles.removeFileButton} onClick={handleRemoveFile}>
                            ✕
                        </button>
                    </div>
                )}
                <div className={styles.inputWrapper}>
                    <textarea
                        value={input}
                        onChange={(e) => {
                            setInput(e.target.value);
                            e.target.style.height = "40px";
                            e.target.style.height = `${e.target.scrollHeight}px`;
                        }}
                        onKeyDown={handleKeyDown}
                        onPaste={handlePaste}
                        placeholder={!uploadedFile ? "Type a message or drop a file" : ""}
                        className={styles.input}
                    />
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                    />
                    <div className={styles.inputActions}>
                        <button className={styles.attachmentButton} onClick={handleAttachmentClick} disabled>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M12.6724 2.845C12.5161 2.68877 12.3042 2.60101 12.0832 2.60101C11.8622 2.60101 11.6503 2.68877 11.4941 2.845L4.46072 9.87833L3.87155 9.28917L10.9049 2.25583C11.2174 1.94338 11.6413 1.76785 12.0832 1.76785C12.5252 1.76785 12.949 1.94338 13.2615 2.25583L16.9107 5.905C17.2232 6.21754 17.3987 6.64139 17.3987 7.08333C17.3987 7.52527 17.2232 7.94912 16.9107 8.26166L8.05322 17.1192C7.74067 17.4316 7.31682 17.6071 6.87488 17.6071C6.43294 17.6071 6.0091 17.4316 5.69655 17.1192L4.33905 15.7617C4.0266 15.4491 3.85107 15.0253 3.85107 14.5833C3.85107 14.1414 4.0266 13.7175 4.33905 13.405L11.5807 6.16333L12.1699 6.75333L4.92822 13.9942C4.77199 14.1504 4.68423 14.3624 4.68423 14.5833C4.68423 14.8043 4.77199 15.0162 4.92822 15.1725L6.28572 16.53C6.44199 16.6862 6.65391 16.774 6.87488 16.774C7.09585 16.774 7.30778 16.6862 7.46405 16.53L16.3216 7.6725C16.4778 7.51622 16.5655 7.3043 16.5655 7.08333C16.5655 6.86236 16.4778 6.65044 16.3216 6.49416L12.6724 2.845Z" fill="#00243D" />
                            </svg>
                        </button>
                        <button className={`${styles.voiceButton} ${isListening ? styles.listening : styles.notListening}`} onClick={handleVoiceClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 2C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V10C8 10.5304 8.21071 11.0391 8.58579 11.4142C8.96086 11.7893 9.46957 12 10 12C10.5304 12 11.0391 11.7893 11.4142 11.4142C11.7893 11.0391 12 10.5304 12 10V4C12 3.46957 11.7893 2.96086 11.4142 2.58579C11.0391 2.21071 10.5304 2 10 2ZM7 4C7 3.20435 7.31607 2.44129 7.87868 1.87868C8.44129 1.31607 9.20435 1 10 1C10.7956 1 11.5587 1.31607 12.1213 1.87868C12.6839 2.44129 13 3.20435 13 4V10C13 10.7956 12.6839 11.5587 12.1213 12.1213C11.5587 12.6839 10.7956 13 10 13C9.20435 13 8.44129 12.6839 7.87868 12.1213C7.31607 11.5587 7 10.7956 7 10V4ZM11 6.5V7.5H9V6.5H11ZM5 10C5 11.3261 5.52678 12.5979 6.46447 13.5355C7.40215 14.4732 8.67392 15 10 15C11.3261 15 12.5979 14.4732 13.5355 13.5355C14.4732 12.5979 15 11.3261 15 10H16C16.0002 11.5049 15.4349 12.9549 14.4162 14.0626C13.3974 15.1702 11.9997 15.8546 10.5 15.98V19H9.5V15.98C8.00034 15.8546 6.60256 15.1702 5.58383 14.0626C4.56509 12.9549 3.99978 11.5049 4 10H5Z" fill="#00243D" />
                            </svg>
                        </button>
                        <button onClick={handleSend} className={`${styles.sendButton} ${input || uploadedFile ? styles.activeBtn : ""}`} disabled={!input && !uploadedFile}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.29945 8.17597C8.54315 7.98048 8.87836 7.94475 9.1578 8.08447L23.5578 15.2845C23.8288 15.42 24 15.697 24 16C24 16.303 23.8288 16.58 23.5578 16.7155L9.1578 23.9155C8.87836 24.0553 8.54315 24.0195 8.29945 23.824C8.05575 23.6285 7.94814 23.3091 8.02392 23.006L9.77541 16L8.02392 8.99403C7.94814 8.69094 8.05575 8.37147 8.29945 8.17597ZM11.2246 16.8L9.998 21.7066L19.8112 16.8H11.2246ZM19.8112 15.2H11.2246L9.998 10.2934L19.8112 15.2Z" fill="white" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Chatbot;
