import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { InputDate } from '@anchor/react-components'

export default function DateRangePicker ({ dateValues, handleApplyDate, className }) {
  const [dates, setDates] = useState({
    startDate: dateValues.start_date,
    endDate: dateValues.end_date
  })

  useEffect(() => {
    setDates({
      startDate: dateValues.start_date,
      endDate: dateValues.end_date
    })
  }, [dateValues])

  const formatDate = (date) => {
    const d = new Date(date)
    const dd = String(d.getDate()).padStart(2, '0')
    const mm = String(d.getMonth() + 1).padStart(2, '0')
    const yyyy = d.getFullYear()
    return `${dd}/${mm}/${yyyy}`
  }

  const handleApply = ([startDate, endDate]) => {
    if (startDate === null || endDate === null) return
    handleApplyDate({
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    })
  }

  return (
    <div className={className}>
      <InputDate
        dateFormat="dd/MM/yyyy"
        endDate={dates.endDate}
        footerType="null"
        id="dateInput"
        label=""
        max={formatDate(new Date())}
        min="08/01/2023"
        onRangeChange={handleApply}
        selectsRange
        startDate={dates.startDate}
        tooltipDescription=""
      >
      </InputDate>
    </div>
  )
}
DateRangePicker.propTypes = {
  dateValues: PropTypes.object,
  handleApplyDate: PropTypes.func,
  className: PropTypes.string
}
