import React, { useState, useEffect, useRef } from 'react'
import styles from './LeftbarSubmenu.css'
import { Link } from 'react-router-dom'
import InputField from '../../commonComponents/InputField/InputField.js'
import { useDispatch, useSelector } from 'react-redux'
import { clickSubMenuItem, toggleSubmenu } from '../../redux/features/leftNavbarSlice.js'
import { handleCatalogBreadcrumb, handleSitePageStatus } from '../../redux/features/sitePageSlice.js'
import { LOADING } from '../../constants'
import PropTypes from 'prop-types'

const LeftbarSubmenu = ({ navItem, subMenuItems, permissions, currentItemId }) => {
  const dispatch = useDispatch()
  const [filteredSubmenuItems, setFilteredSubmenuItems] = useState([])
  const selectedSubmenuItem = useSelector((state) => state.leftNavbar.selectedSubmenuItem)
  const submenuRef = useRef(null)

  useEffect(() => {
    setFilteredSubmenuItems(subMenuItems)
  }, [subMenuItems, navItem])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target) && currentItemId !== event.target.id) {
        dispatch(toggleSubmenu(false))
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dispatch])

  const handleSearchChange = (searchQuery) => {
    if (searchQuery !== '') {
      const filteredItems = subMenuItems.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      setFilteredSubmenuItems(filteredItems)
    } else {
      setFilteredSubmenuItems(subMenuItems)
    }
  }

  const handleSubmenu = (submenuItem) => {
    sessionStorage.setItem('catalogBreadcrumb', `${submenuItem.path}`)
    if (submenuItem.id !== selectedSubmenuItem.id) {
      dispatch(handleSitePageStatus(LOADING))
    }
    dispatch(handleCatalogBreadcrumb(submenuItem.path))
    dispatch(toggleSubmenu(false))
    dispatch(clickSubMenuItem({ item: submenuItem }))
  }

  return (
    <div ref={submenuRef} className={styles.parentCtn}>
      <div className={styles.paddingCtn}>
        <InputField
          placeholderText='Search'
          name='leftSubmenu'
          icon={'/assets/map-search.svg'}
          onChangeCallback={handleSearchChange}
        />
      </div>
      {filteredSubmenuItems?.map((submenuItem, index) => (
        <Link to={submenuItem.path} key={`${index}-${submenuItem.name}`} onClick={() => handleSubmenu(submenuItem)} data-testid={submenuItem.name}>
          <div className={`${selectedSubmenuItem.id === submenuItem.id ? styles.selectedSubmenuItem : ''} ${styles.submenuItem}`}>
            {submenuItem.name}
          </div>
        </Link>
      ))}
    </div>
  )
}

export default LeftbarSubmenu

LeftbarSubmenu.propTypes = {
  navItem: PropTypes.string,
  subMenuItems: PropTypes.array,
  permissions: PropTypes.array,
  currentItemId: PropTypes.string
}
