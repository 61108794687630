// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputCtn_NmR0D {
  display: flex;
  align-items: end;
}

input[type="file"] {
  display: none;
}

.inputField_tQJrV {
  max-width: 360px;
  min-width: 25%;
  margin-right: 14px;
}

.generateBtn_PYhCE {
  margin-top: 20px;
}

.prefixInput_yoCNx {
  padding-bottom: 1.5%;
}

.multiInput_wojyc {
  margin-bottom: -5px;
  max-width: 360px;
  margin-right: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/BaseBuild/BaseBuild.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".inputCtn {\n  display: flex;\n  align-items: end;\n}\n\ninput[type=\"file\"] {\n  display: none;\n}\n\n.inputField {\n  max-width: 360px;\n  min-width: 25%;\n  margin-right: 14px;\n}\n\n.generateBtn {\n  margin-top: 20px;\n}\n\n.prefixInput {\n  padding-bottom: 1.5%;\n}\n\n.multiInput {\n  margin-bottom: -5px;\n  max-width: 360px;\n  margin-right: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputCtn": `inputCtn_NmR0D`,
	"inputField": `inputField_tQJrV`,
	"generateBtn": `generateBtn_PYhCE`,
	"prefixInput": `prefixInput_yoCNx`,
	"multiInput": `multiInput_wojyc`
};
export default ___CSS_LOADER_EXPORT___;
