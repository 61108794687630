import React from "react";
import PropTypes from 'prop-types'
import styles from "./ConfirmationPopup.css";

const ConfirmationPopup = ({ isOpen, onConfirm, onCancel, message, subMessage }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.overlay}>
            <div className={styles.popup}>
                <h2 className={styles.title}>{message}</h2>
                {subMessage && <p className={styles.subMessage}>{subMessage}</p>}
                <div className={styles.buttons}>
                    <button className={styles.noButton} onClick={onCancel}>
                        Cancel
                    </button>
                    <button className={styles.yesButton} onClick={onConfirm}>
                        Yes, Reconcile
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPopup;

ConfirmationPopup.propTypes = {
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    message: PropTypes.string,
    subMessage: PropTypes.string
}