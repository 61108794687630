export const DASHBOARD = 'DASHBOARD'
export const RESOURCE = 'RESOURCE'
export const METADATA = 'METADATA'
export const DEVICE_CONFIG = 'DEVICE_CONFIG'
export const TRACK = 'TRACK'
export const REPORTS = 'REPORTS'
export const SUMMARY = 'SUMMARY'
export const EVENTS = 'EVENTS'
export const CATALOG = 'CATALOG'
export const DIAGNOSE = 'DIAGNOSE'
export const AIOPS = 'AIOPS'
export const ANALYSE = 'ANALYSE'
export const ADMIN = 'ADMIN'
export const TOPOLOGY = 'TOPOLOGY'
export const DEFAULT_DASHBOARD = 'DEFAULT_DASHBOARD'
export const ALARMS = 'ALARMS'
export const MIGRATE_WAREHOUSE = 'MIGRATE_WAREHOUSE'
export const LOGS = 'LOGS'
export const ACKNOWLEDGE = 'ACKNOWLEDGE'
export const RAISE_INCIDENT = 'RAISE_INCIDENT'
export const MORE_DETAILS = 'MORE_DETAILS'
export const SEARCH_LOGS = 'SEARCH_LOGS'
export const HISTORY = 'HISTORY'
export const ONE_MINUTE = 'ONE_MINUTE'
export const FIVE_MINUTES = 'FIVE_MINUTES'
export const TEN_MINUTES = 'TEN_MINUTES'
export const THIRTY_MINUTES = 'THIRTY_MINUTES'
export const ONE_HOUR = 'ONE_HOUR'
export const ADD_NOTES = 'ADD_NOTES'
export const MODIFY = 'MODIFY'
export const DELETE = 'DELETE'
export const PRODUCTS = 'PRODUCTS'
export const ORDERS = 'ORDERS'
export const MANAGED_WAN = 'MANAGED_WAN'
export const MANAGED_LAN = 'MANAGED_LAN'
export const MANAGED_PARAMETERS = 'MANAGED_PARAMETERS'
export const MANAGED_CN = 'MANAGED_CN'
export const TRACK_ORDERS = 'TRACK_ORDERS'
export const USAGE_REPORT = 'USAGE_REPORT'
export const WORKFLOW_MANAGER = 'WORKFLOW_MANAGER'
export const FIREWALL_CONFIG = 'FIREWALL_CONFIG'
export const COIN_MODEL = 'COIN_MODEL'
export const SAMPLE_MODEL = 'SAMPLE_MODEL'
export const IP_VPN = 'IP_VPN'
export const SUCCESS = 'SUCCESS'
export const AUDIT = 'AUDIT'
export const PLAYBOOK = 'PLAYBOOK'
export const AEGIS_BACKUP = 'AEGIS_BACKUP'
export const UTILITIES = 'UTILITIES'
export const CHAT_BOT = 'CHAT_BOT'
export const ONBOARD_EXISTING_WAREHOUSE_TO_CNN = 'ONBOARD_EXISTING_WAREHOUSE_TO_CNN'
export const ASA_FIREWALL_UPGRADE = 'ASA_FIREWALL_UPGRADE'
export const CLOUD_RESOURCE_NAMING = 'CLOUD_RESOURCE_NAMING'
export const RESOURCE_GROUP_NAMING = 'RESOURCE_GROUP_NAMING'
export const CUSTOM_AUTOMATION = 'CUSTOM_AUTOMATION'
export const BUILD = 'BUILD'
// summary page customization
export const layoutConstant = [
  { i: 'map', x: 0, y: 0, w: 12, h: 12, minW: 4, maxW: 12, minH: 6, maxH: 12 },
  { i: 'event', x: 1, y: 0, w: 12, h: 12, minW: 4, maxW: 12, minH: 6, maxH: 12 }
]
export const API_ERROR = 'API_ERROR'
export const alarmHeading = {
  perceivedSeverity: 'Severity',
  state: 'State',
  alarmedObjectType: 'Alarm Object Type',
  alarmedObject: 'Alarmed Object',
  alarmType: 'Alarm Type',
  specificProblem: 'Specific Problem',
  alarmDetails: 'Alarm Details',
  alarmReportingTime: 'Alarm Reporting Time',
  alarmRaisedTime: 'Alarm Raised Time',
  alarmChangedTime: 'Alarm Changed Time',
  alarmClearedTime: 'Alarm Cleared Time',
  externalAlarmId: 'External Alarm ID',
  probableCause: 'Probable Cause',
  reportingSystemId: 'Reporting System ID',
  sourceSystemId: 'Source System ID'
}

export const auditContextMenulist = [
  {
    icon: '../assets/infoCircle.svg',
    name: 'More Details',
    value: 'moreDetails'
  }
]

export const orderContextMenulist = [
  {
    icon: '../assets/infoCircle.svg',
    name: 'More Details',
    value: 'moreDetails'
  }
]

export const orderModalFields = [
  { field: 'order_placed_on', headerName: 'Order Placed On' },
  { field: 'order_placed_by', headerName: 'Order Placed By' },
  { field: 'order_id', headerName: 'Order Id' },
  { field: 'current_stage', headerName: 'Order Stage' },
  { field: 'current_status', headerName: 'Order Status' }
]

export const LOADING = 'loading'
export const IN_PROGRESS = 'In-progress'
export const SUBMIT = 'submit'
export const APPROVE = 'approve'
export const DRY_RUN = 'dryrun'
export const REVIEW = 'review'
export const COMMIT = 'commit'
export const PRECHECK = 'precheck'
export const FTP = 'ftp'
export const UPGRADE = 'upgrade'
export const POSTCHECK = 'postcheck'

// dry run status
export const APPROVED = 'Approved'
export const PENDING = 'Pending'
export const PARTIAL = 'Partial'
export const REJECTED = 'Rejected'
export const SKIPPED = 'skipped'
export const QUEUED = 'queued'
export const FAILED = 'Failed'
export const SUCCESS_STAGE = 'Success'
export const CLASS_ONE = 'Class 1'
export const CLASS_TWO = 'P2P'

export const baseBuildTabData = [
  { title: 'Select Class', completed: false, active: true },
  { title: 'Details', completed: false, active: false },
  { title: 'Download', completed: false, active: false },
]

export const baseBuildClasses = [CLASS_ONE, CLASS_TWO]

export const DEVICE_ROLES = ["DC_GATEWAYS", "TRANSIT_EDGES", "LEAFS"]

