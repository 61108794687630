import React, { useState } from 'react'
import styles from './ResetPasswordPage.css'
import { Button, Input } from '@anchor/react-components'
import { useNavigate } from 'react-router-dom'

const ResetPasswordPage = () => {
  const navigate = useNavigate()
  const [passwords, setPasswords] = useState({
    newPassword: '',
    confirmPassword: ''
  })
  const [errorObj, setErrorObj] = useState({
    isFailed: false,
    message: ''
  })
  const [resetSuccessFull, setResetSuccessFull] = useState(false)
  const handlePasswordChange = (event) => {
    const { name, value } = event.target
    setPasswords(prevState => ({
      ...prevState,
      [name]: value
    }))
  }
  const handleLogin = () => {
    navigate('/')
  }
  const arePasswordsFilled = passwords.newPassword.trim() !== '' && passwords.confirmPassword.trim() !== ''
  const handleResetPassword = () => {
    setErrorObj({
      isFailed: true,
      message: 'Password are not matching'
    })
    setResetSuccessFull(false)
  }
  return <div className={styles.unAuthenticatedCtn}>
    <div className={styles.appTitle}>ProviGenius</div>
    <div className={styles.loginCtn}>
      {resetSuccessFull
        ? <>
          <img src='/assets/greenCircle.svg' className={styles.successIcon} alt='greenCircle' />
          <div className={styles.forgotTitle}>Password Changed!</div>
          <div className={styles.resetSuccess}>Your password has been changed successfully.</div>
          <div className={styles.resetSuccess}>Click here to <buttton className={styles.loginHref} onClick={handleLogin}>login</buttton></div>
        </>
        : <>
          <div className={styles.logoCtn}>
            <img src='/assets/maerskLogo.svg' className={styles.logoImg} alt='logo' />
          </div>
          {errorObj.isFailed
            ? <div className={styles.errorCtn}>
              <img src='/assets/redExClamation.svg' className={styles.errorIcon} alt='redExclamation' />
              {errorObj.message}
            </div>
            : null}
          <div className={styles.forgotTitle}>Reset Password</div>
          <Input
            fit="medium"
            icon="lock"
            label="New Password"
            onChange={handlePasswordChange}
            placeholder="Enter Password"
            type="password"
            value={passwords.newPassword}
            variant="default"
            name="newPassword"
          /><Input
            fit="medium"
            icon="lock"
            label="Confirm Password"
            onChange={handlePasswordChange}
            placeholder="Confirm Password"
            type="password"
            value={passwords.confirmPassword}
            variant="default"
            name="confirmPassword"
          />
          <div className={styles.loginButton}>
            <Button
              appearance="default"
              disabled={!arePasswordsFilled}
              justifyItems="center"
              label="Reset"
              onClick={handleResetPassword}
              title="Button"
              variant="filled"
            />
          </div>
        </>}
    </div>

  </div>
}

export default ResetPasswordPage
