import React, { useEffect, useState } from 'react'
import Snackbar from '@mui/material/Snackbar';
import closeIcon from '../../../public/assets/crossIcon.svg'
import successIcon from '../../../public/assets/toastSuccess.svg'
import styles from './Toast.css'
import PropTypes from 'prop-types'

function Toast({
  showToast,
  setShowToast,
  message = 'No message',
  type = 'success'
}) {
  const [bgColor, setBgColor] = useState('')
  useEffect(() => {
    if (type === 'success') {
      setBgColor('--mds_brand_appearance_success_subtle_background-color')
    } else if (type === 'warning') {
      setBgColor('--mds_brand_appearance_warning_subtle_background-color')
    } else if (type === 'error') {
      setBgColor('--mds_brand_appearance_error_subtle_background-color')
    }
  }, [])
  const handleClose = () => {
    setShowToast({ show: false })
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={showToast}
      autoHideDuration={3000}
      onClose={handleClose}
      data-testid='toast'
      message={
        <div className={styles.toastMessage}>
          {type === 'success' ? <img src={successIcon} alt='close Icon' className={styles.statusIcon} /> : null}
          <div>{message}</div>
          <button onClick={handleClose} className={styles.closeIcon} aria-label="Close">
            <img src={closeIcon} alt="Close Icon" />
          </button>
        </div>
      }
      sx={{
        '.MuiPaper-root.MuiSnackbarContent-root': {
          background: `var(${bgColor})`,
          color: 'var(--mds_brand_appearance_neutral_default_text-color)',
          padding: '10px 16px'
        },
        '.MuiSnackbarContent-message': {
          width: '100%',
          padding: '0px'
        }
      }}
    />
  )
}

export default Toast

Toast.propTypes = {
  showToast: PropTypes.bool,
  setShowToast: PropTypes.func,
  message: PropTypes.string,
  type: PropTypes.string
}
