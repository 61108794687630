// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_Y_wQB {
  color: #141414 !important;
  border-color: #878787 !important;
  font-family: "Maersk Text", sans-serif !important;
  font-style: normal !important;
  text-transform: none !important;
  height: 32px;
}

.button_Y_wQB:hover {
  border-color: #272727;
}

.tableContainer_kDsIj {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.tableRow_0mepP {
  display: flex;
  border-bottom: 1px solid #eee;
}

.tableCell_krMam {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
}

.name_ZV46D {
  text-align: left;
  flex: 2;
}

.value_rCiz1 {
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/DashboardPage/DashboardPage.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gCAAgC;EAChC,iDAAiD;EACjD,6BAA6B;EAC7B,+BAA+B;EAC/B,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,OAAO;EACP,aAAa;EACb,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,OAAO;AACT;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".button {\n  color: #141414 !important;\n  border-color: #878787 !important;\n  font-family: \"Maersk Text\", sans-serif !important;\n  font-style: normal !important;\n  text-transform: none !important;\n  height: 32px;\n}\n\n.button:hover {\n  border-color: #272727;\n}\n\n.tableContainer {\n  width: 100%;\n  border: 1px solid #ccc;\n  border-collapse: collapse;\n}\n\n.tableRow {\n  display: flex;\n  border-bottom: 1px solid #eee;\n}\n\n.tableCell {\n  flex: 1;\n  padding: 10px;\n  font-size: 14px;\n  line-height: 24px;\n}\n\n.name {\n  text-align: left;\n  flex: 2;\n}\n\n.value {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_Y_wQB`,
	"tableContainer": `tableContainer_kDsIj`,
	"tableRow": `tableRow_0mepP`,
	"tableCell": `tableCell_krMam`,
	"name": `name_ZV46D`,
	"value": `value_rCiz1`
};
export default ___CSS_LOADER_EXPORT___;
