import React, { useEffect } from 'react'
import styles from './Breadcrumbs.css'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { insertChildNode, updateCurrentBreadCrumb } from '../../redux/features/breadcrumbSlice_v1.js'
import { handleCatalogBreadcrumb, handleSitePath } from '../../redux/features/sitePageSlice.js'
import isEqual from 'lodash.isequal'

const Breadcrumbs = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const navItems = useSelector((state) => state.leftNavbar.navItems)
  const breadcrumbsObj = useSelector((state) => state.breadcrumb.breadcrumbsObj)
  const currentPath = useSelector((state) => state.breadcrumb.currentPath || '')
  const catalogBreadcrumb = useSelector((state) => state.sitePage.catalogBreadcrumb)
  const selectedTab = useSelector((state) => state.sitePage.selectedTab)

  // Memoized flattenMenuItems function to avoid recalculating
  const flattenMenuItems = (navItems) => {
    return navItems.reduce((acc, navItem) => {
      acc.push(navItem)
      if (navItem.subMenuItems) {
        acc.push(...flattenMenuItems(navItem.subMenuItems))
      }
      return acc
    }, [])
  }

  const generateBreadcrumbs = (routes, selectedTab) => {
    const breadcrumbs = {}
    let breadcrumbPath = ''
    let previousPath = ''
    const currentPath = []

    routes.forEach((route) => {
      const decodedRoute = decodeURIComponent(route)
      breadcrumbPath += decodedRoute

      const menuItem = findMenuItem(decodedRoute)
      if (menuItem) {
        addBreadcrumb(menuItem, decodedRoute, currentPath, breadcrumbs, breadcrumbPath)
        previousPath = menuItem.path
      } else {
        addDecodedRouteBreadcrumb(decodedRoute, previousPath, breadcrumbPath, currentPath, breadcrumbs, selectedTab?.title)
        previousPath = previousPath || catalogBreadcrumb
      }

      breadcrumbPath += '/'
    })

    if (!currentPath.length) currentPath.push('Dashboard')

    return { breadcrumbs, currentPath }
  }

  const findMenuItem = (decodedRoute) => {
    const flattenedNavItems = flattenMenuItems(navItems)
    return flattenedNavItems.find((item) => item.bredCrumbName === decodedRoute)
  }

  const addBreadcrumb = (menuItem, decodedRoute, currentPath, breadcrumbs, breadcrumbPath) => {
    currentPath.push(`${menuItem.name}`)
    if (!breadcrumbsObj[menuItem.name]) {
      breadcrumbs[menuItem.name] = {
        id: decodedRoute,
        name: menuItem.name,
        path: menuItem.path
      }
    }
  }

  const addDecodedRouteBreadcrumb = (decodedRoute, previousPath, breadcrumbPath, currentPath, breadcrumbs, tabName) => {
    currentPath.push(`${decodedRoute}`)
    const path = catalogBreadcrumb === breadcrumbPath ? catalogBreadcrumb : previousPath
    if (!breadcrumbsObj[decodedRoute]) {
      breadcrumbs[decodedRoute] = {
        id: decodedRoute,
        name: decodedRoute,
        path,
        tab: tabName === decodedRoute
      }
    }
  }

  useEffect(() => {
    const routes = location.pathname.split('/').filter((item) => item)
    if ((routes.includes('catalog') || routes.includes('metadata') || routes.includes('build')) && selectedTab?.title) {
      routes.push(selectedTab?.title)
    }

    const { breadcrumbs: newBreadcrumbs, currentPath: newCurrentPath } = generateBreadcrumbs(routes, selectedTab)

    // Dispatch only if there are actual changes
    if (!isEqual(newBreadcrumbs, breadcrumbsObj)) {
      dispatch(insertChildNode(newBreadcrumbs))
    }

    if (!isEqual(newCurrentPath, currentPath)) {
      dispatch(updateCurrentBreadCrumb(newCurrentPath))
    }
  }, [location.pathname, selectedTab?.title, catalogBreadcrumb, navItems])

  const breadcrumbOnClick = (path) => {
    if (path?.includes('catalog') || path?.includes('metadata') || path?.includes('build')) {
      dispatch(handleSitePath(path))
      dispatch(handleCatalogBreadcrumb(path))
    }
    if (path) navigate(path)
  }

  return (
    <div className={styles.parentCtn}>
      {currentPath.map((item, index) => {
        const breadcrumb = breadcrumbsObj[item]
        const isLast = index === currentPath.length - 1
        const nextItem = currentPath[index + 1]
        const nextBreadcrumb = breadcrumbsObj[nextItem]
        const nextLastTab = index === currentPath.length - 2 && nextBreadcrumb?.tab === true

        return (
          <button
            onClick={() => breadcrumbOnClick(isLast ? null : breadcrumb.path)}
            className={`${styles.item} ${breadcrumb.path && !nextLastTab ? styles.pathItem : ''} ${isLast ? styles.active : ''}`}
            key={`${index}-${item}`}
            title={item}
          >
            {index > 0 && '/'}
            <span className={styles.slash}>{item === 'orderdetails' ? 'Order Details' : item}</span>
          </button>
        )
      })}
    </div>
  )
}

export default Breadcrumbs
