import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'

export default function SwitchField({ label, onChange, required, id, value, disabled, dataTestId, error, errorMessage }) {
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    setChecked(value)
  }, [value])
  const handleChange = (event) => {
    setChecked(event.target.checked)
    onChange(event.target.checked)
  }
  return (
    <FormGroup
      sx={{ justifyItems: 'center' }} key={id}>
      <FormControlLabel
        sx={{
          margin: '35px 0px 0px 20px'
        }}
        label={label}
        required={required}
        control={
          <Switch
            data-testid={dataTestId}
            size='small'
            checked={checked}
            onChange={handleChange}
            disabled={disabled}
            value={value}
          />} />
    </FormGroup>
  )
}
SwitchField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
}
