// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parentCtn_e5wHm {
  display: flex;
  padding-bottom: 10px;
}

.slash_F8kWh {
  padding-left: 5px;
  padding-right: 5px;
}

.item_RlFQO {
  font-family: "Maersk Text", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #141414;
  cursor: default;
  border: none;
  background: inherit;
  padding: 0;
}

.item_RlFQO.active_QugDJ {
  color: gray;
}

.pathItem_CuvJy {
  cursor: pointer;
  color: #0073ab;
}
`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumbs/Breadcrumbs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".parentCtn {\n  display: flex;\n  padding-bottom: 10px;\n}\n\n.slash {\n  padding-left: 5px;\n  padding-right: 5px;\n}\n\n.item {\n  font-family: \"Maersk Text\", sans-serif;\n  font-style: normal;\n  font-size: 14px;\n  line-height: 20px;\n  font-weight: 500;\n  color: #141414;\n  cursor: default;\n  border: none;\n  background: inherit;\n  padding: 0;\n}\n\n.item.active {\n  color: gray;\n}\n\n.pathItem {\n  cursor: pointer;\n  color: #0073ab;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parentCtn": `parentCtn_e5wHm`,
	"slash": `slash_F8kWh`,
	"item": `item_RlFQO`,
	"active": `active_QugDJ`,
	"pathItem": `pathItem_CuvJy`
};
export default ___CSS_LOADER_EXPORT___;
