const zip = window.zip
// Function to convert JSON to structured text
const jsonToText = (obj, indent = '') => {
    let text = ''
    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            text += `${indent}${key}:\n`
            obj[key].forEach((item, index) => {
                text += `${indent}  Item ${index + 1}:\n`
                text += jsonToText(item, indent + '    ')
            })
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            text += `${indent}${key}:\n`
            text += jsonToText(obj[key], indent + '  ')
        } else {
            text += `${indent}${key}: ${obj[key]}\n`
        }
    }
    return text
}

// Function to create and download a ZIP file
export const createAndDownloadZip = async (files) => {
    const zipWriter = new zip.ZipWriter(new zip.BlobWriter('application/zip'))

    // Add each file to the ZIP
    for (const file of files) {
        await zipWriter.add(file.name, new zip.TextReader(file.content))
    }

    // Generate the ZIP file and trigger download
    const zipBlob = await zipWriter.close()
    const url = URL.createObjectURL(zipBlob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'files.zip'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
}