import React, { useEffect, useState } from 'react';
import { LoadingIndicator } from '@anchor/react-components'
import Toast from '../../commonComponents/Toast/Toast';
import { fetchResourceInfo } from '../../redux/features/cloudResourceNamingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ResourceGroupNamingFields } from '../../staticData/ResourceData';
import { LOADING } from '../../constants';
import axiosInstance from '../../utility/axiosInstance';
import ResourceGroupFields from '../ResourceGroupFields/ResourceGroupFields';

export default function ResourceGroupNaming() {

    const dispatch = useDispatch()
    const [showToastObj, setShowToastObj] = useState({ show: false, type: '', message: '' })
    const { resourceData, fetchResourceStatus } = useSelector((state) => state.cloudResourceNaming)

    useEffect(() => {
        dispatch(fetchResourceInfo())
    }, [])


    const setToast = ({ show, type, message }) => {
        setShowToastObj({ show, type, message })
    }

    const generateResourceName = async (payload, params) => {
        const response = await axiosInstance.post(`${process.env.API_GATEWAY}/cloud/resource-group-naming`, { ...payload }, { params })
        return response.data.resource_group_name
    }


    return <>
        {
            showToastObj.show && (
                <Toast
                    showToast={showToastObj.show}
                    setShowToast={setShowToastObj}
                    message={showToastObj.message}
                    type={showToastObj.type}
                />
            )
        }
        {fetchResourceStatus === LOADING ?
            <LoadingIndicator />
            : <ResourceGroupFields
                resourceData={resourceData}
                fieldsData={ResourceGroupNamingFields}
                setToast={setToast}
                generateItem={generateResourceName}
            />
        }
    </>
}   