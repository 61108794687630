import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LoginPage from '../components/LoginPage/LoginPage.js'
import ResetPasswordPage from '../components/ResetPasswordPage/ResetPasswordPage.js'
import PageNotFound from '../components/PageNotFound/PageNotFound.js'
import PropTypes from 'prop-types'

function UnauthenticatedRoutes ({ instance }) {
  return (
    <Routes>
      <Route path='/' element={<LoginPage instance={instance} />} />
      <Route path="/resetpassword/:id" element={<ResetPasswordPage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

export default UnauthenticatedRoutes

UnauthenticatedRoutes.propTypes = {
  instance: PropTypes.object
}
