import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@anchor/react-components'
import { Typography, Grid } from '@mui/material'
import styles from './AuditPage.css'

const AuditDetailPage = ({ isOpen, handleClose, auditData, openMessageView }) => {

    const renderStatus = () => {
        switch (auditData.status) {
            case 'failed':
                return (
                    <div className={styles.status}>
                        <div className={styles.redDot}></div>
                        <div>Failed</div>
                    </div>
                );
            case 'success':
                return (
                    <div className={styles.status}>
                        <div className={styles.greenDot}></div>
                        <div>Success</div>
                    </div>
                );
            case 'rejected':
                return (
                    <div className={styles.status}>
                        <div className={styles.redDot}></div>
                        <div>Rejected</div>
                    </div>
                );
            default:
                return (
                    <div className={styles.status}>
                        <div className={styles.redDot}></div>
                        <div>No Status</div>
                    </div>
                );
        }
    };

    return (
        <Modal
            open={isOpen}
            heading="More Details"
            onClose={handleClose}
            dimension='medium'
            showCloseIcon
            height='500px'
            zindex={998}
        >
            <Grid container spacing={2} className={styles.gridContainer}>
                <Grid item xs={12} className={styles.gridItem}>
                    <Typography className={styles.label} gutterBottom>User</Typography>
                    <Typography className={styles.colon} gutterBottom>:</Typography>
                    <Typography className={styles.value} gutterBottom>{auditData.user}</Typography>
                </Grid>
                <Grid item xs={12} className={styles.gridItem}>
                    <Typography className={styles.label} gutterBottom>Operation</Typography>
                    <Typography className={styles.colon} gutterBottom>:</Typography>
                    <Typography className={styles.value} gutterBottom>{auditData.operation}</Typography>
                </Grid>
                <Grid item xs={12} className={styles.gridItem}>
                    <Typography className={styles.label} gutterBottom>Entity Type</Typography>
                    <Typography className={styles.colon} gutterBottom>:</Typography>
                    <Typography className={styles.value} gutterBottom>{auditData.entity_type}</Typography>
                </Grid>
                <Grid item xs={12} className={styles.gridItem}>
                    <Typography className={styles.label} gutterBottom>Request Time</Typography>
                    <Typography className={styles.colon} gutterBottom>:</Typography>
                    <Typography className={styles.value} gutterBottom>{auditData.request_time}</Typography>
                </Grid>
                <Grid item xs={12} className={styles.gridItem}>
                    <Typography className={styles.label} gutterBottom>Response Time</Typography>
                    <Typography className={styles.colon} gutterBottom>:</Typography>
                    <Typography className={styles.value} gutterBottom>{auditData.response_time}</Typography>
                </Grid>
                <Grid item xs={12} className={styles.gridItem}>
                    <Typography className={styles.label} gutterBottom>Status</Typography>
                    <Typography className={styles.colon} gutterBottom>:</Typography>
                    <div className={styles.value}>
                        {renderStatus()}
                    </div>
                </Grid>
                <Grid item xs={12} className={styles.gridItem}>
                    <Typography className={styles.label} gutterBottom>Unique Identifier</Typography>
                    <Typography className={styles.colon} gutterBottom>:</Typography>
                    <Typography className={styles.value} gutterBottom>{auditData.unique_identifier}</Typography>
                </Grid>
                {auditData.status === 'failed' &&
                    <Grid item xs={12} className={styles.gridItem}>
                        <Typography className={styles.label} gutterBottom>Message</Typography>
                        <Typography className={styles.colon} gutterBottom>:</Typography>
                        <Typography className={styles.value} gutterBottom>
                            <button onClick={openMessageView} className={styles.message}>
                                Click to view Message
                            </button>
                        </Typography>
                    </Grid>}
            </Grid>
        </Modal>
    )
}

export default AuditDetailPage

AuditDetailPage.propTypes = {
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    auditData: PropTypes.object,
    openMessageView: PropTypes.func
}
