import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_ERROR, LOADING, SUCCESS } from '../../constants'
import axiosInstance from '../../utility/axiosInstance'

export const fetchResourceInfo = createAsyncThunk(
    'fetchResourceInfo',
    async (_, { rejectWithValue }) => {
        try {
            const resource = await axiosInstance.get(`${process.env.API_GATEWAY}/cloud/resource-info`)
            const response = resource.data || {}
            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const cloudResourceNamingSlice = createSlice({
    name: 'cloudResourceNamingSlice',
    initialState: {
        resourceData: {},
        fetchResourceStatus: LOADING
    },
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(fetchResourceInfo.pending, (state, action) => {
                state.fetchResourceStatus = LOADING
            })
            .addCase(fetchResourceInfo.fulfilled, (state, action) => {
                state.fetchResourceStatus = SUCCESS
                state.resourceData = action.payload || {}
            })
            .addCase(fetchResourceInfo.rejected, (state, action) => {
                state.fetchResourceStatus = API_ERROR
            })
    }
})
export default cloudResourceNamingSlice.reducer
