import React, { useEffect, useState } from 'react';
import styles from './AegisBackup.css'
import { Button, LoadingIndicator } from '@anchor/react-components'
import TextField from '../Common/TextField/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchCompareConfig, fetchConfig } from '../../redux/features/aegisBackupSlice.js';
import Toast from '../../commonComponents/Toast/Toast.js';
import { API_ERROR, LOADING } from '../../constants.js';
import { Group } from '@anchor/react-components/dist/lib/components/Radio/Group.js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export default function AegisBackup() {
    const { fetchStatus, error } = useSelector(state => state.aegisBackup)
    const [hostName, setHostName,] = useState('')
    const [configType, setConfigType] = useState('Get Config')
    const [compareDate, setCompareDate] = useState({ from: '', to: '' })
    const [toDateRange, setToDateRange] = useState({ min: dayjs(), max: dayjs() });

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showToastObj, setShowToastObj] = useState({ show: false, type: '', message: '' })

    const handleSearch = async () => {
        if (configType === 'Get Config') {
            dispatch(fetchConfig({ hostName: hostName.trim(), configType })).then((action) => {
                if (action.type.endsWith('/fulfilled')) {
                    navigate('/aegis/detail');
                }
            });
        } else {
            dispatch(fetchCompareConfig({ hostName: hostName.trim(), configType, compareDate })).then((action) => {
                if (action.type.endsWith('/fulfilled')) {
                    navigate('/aegis/detail');
                }
            });
        }
    }

    useEffect(() => {
        if (fetchStatus === API_ERROR) {
            setShowToastObj({ show: true, type: 'error', message: error })
        }
    }, [fetchStatus])

    const handleDateChange = (key, date) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
        setCompareDate({ ...compareDate, [key]: formattedDate });

        if (key === 'from') {
            const newMinDate = dayjs(date).isValid() ? dayjs(date) : dayjs();
            setToDateRange({ min: newMinDate, max: dayjs() });

            if (compareDate.to && dayjs(compareDate.to).isBefore(newMinDate)) {
                setCompareDate((prev) => ({ ...prev, to: null }));
            }
        }
    };

    const changeRadioButton = (e) => {
        setHostName('')
        setConfigType(e.target.value)
    }

    return <>
        {fetchStatus === LOADING ? <LoadingIndicator /> :
            < div className={styles.container}>
                <div className={styles.firstSection}>
                    <div
                        className={styles.radioGroup}>
                        <Group
                            fit="medium"
                            id="fruit"
                            name="fruit"
                            onChange={changeRadioButton}
                            options={[
                                {
                                    id: 'Get Config',
                                    label: 'Get Config',
                                    value: 'Get Config'
                                },
                                {
                                    id: 'Compare Config',
                                    label: 'Compare Config',
                                    value: 'Compare Config'
                                }
                            ]}
                            orientation="horizontal"
                            value={configType}
                            disabled={false}
                        />
                    </div>
                    {configType === 'Compare Config' && (
                        <div className={styles.dateContainer}>
                            <div className={styles.fromDate}>
                                <label htmlFor="multi-select-input">From</label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={compareDate.from ? dayjs(compareDate.from) : null}
                                        onChange={(date) => handleDateChange('from', date)}
                                        maxDate={dayjs()}
                                        format="DD/MM/YYYY"
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                height: '40px',
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div>
                                <label htmlFor="multi-select-input">To</label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={compareDate.to ? dayjs(compareDate.to) : null}
                                        onChange={(date) => handleDateChange('to', date)}
                                        minDate={toDateRange.min}
                                        maxDate={toDateRange.max}
                                        format="DD/MM/YYYY"
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                height: '40px',
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    )}
                    <TextField
                        fit="medium"
                        className={`${styles.inputField}`}
                        label={`Host Name`}
                        onChange={(e) => { setHostName(e.target.value) }}
                        placeholder={`Enter Host Name`}
                        required={true}
                        error={false}
                        errorMessage="This field can't be empty"
                        type="text"
                        id={``}
                        value={hostName}
                        variant="default"
                        disabled={false}
                    />
                    {<Button
                        label="Search"
                        style={{ marginTop: '18px' }}
                        onClick={handleSearch}
                        disabled={
                            (configType === 'Compare Config' && (!compareDate.from || !compareDate.to || !hostName)) ||
                            (configType === 'Get Config' && !hostName)
                        }
                    />}
                </div>
            </div >}
        {
            showToastObj.show &&
            <Toast
                showToast={showToastObj.show}
                setShowToast={setShowToastObj}
                message={showToastObj.message}
                type={showToastObj.type}
            />
        }
    </>
}   