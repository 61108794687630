import React, { useEffect, useState } from 'react';
import RightTab from '../RightTab/RightTab.js';
import MultiInput from '../Common/MultiInput/MultiInput.js';
import Dropdown from '../Common/Dropdown/Dropdown.js';
import styles from './WarehouseProvision.css'
import TextField from '../Common/TextField/TextField';
import { Button, LoadingIndicator } from '@anchor/react-components'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSiteCodes, fetchWarehouseSchema, setLoading } from '../../redux/features/warehouseProvisionSlice.js';
import axiosInstance from '../../utility/axiosInstance.js';
import Toast from '../../commonComponents/Toast/Toast';
import SwitchField from '../Common/Switch/SwitchField.js';
import { handlePostCart } from '../../utility/utils.js';
import { useNavigate } from 'react-router-dom';
import { LOADING, SUCCESS } from '../../constants.js';

export default function WarehouseProvision() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { tabData, data, siteCodes, formData, fetchStatus } = useSelector((state) => state.warehouseProvision)
    const [isWarehouseOnboarded, setIsWarehouseOnboarded] = useState(false)
    const [isFetchedPrefixes, setIsFetchedPrefixes] = useState(false)
    const [rightTabData, setRightTabData] = useState(tabData)
    const [values, setValues] = useState({ ...data })
    const [showToastObj, setShowToastObj] = useState({ show: false, type: '', message: '' })

    useEffect(() => {
        dispatch(fetchSiteCodes())
    }, [])

    const handleInputChange = (key, value) => {
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
            setValues({ ...values, [key]: value.value })
        } else {
            setValues({ ...values, [key]: value })
        }
    }

    const handleTabClick = ({ title, completed }, position) => {
        if (!completed) return;

        setRightTabData(rightTabData.map((data, index) => ({
            ...data,
            active: data.title === title,
            completed: data.completed && index < position
        })));

        const shouldResetPrefixes = position <= 1;
        const shouldResetWarehouse = position === 0;

        if (shouldResetPrefixes) setIsFetchedPrefixes(false);
        if (shouldResetWarehouse) setIsWarehouseOnboarded(false);
    };

    const handleSiteCodeClick = async () => {
        try {
            dispatch(setLoading(LOADING))
            const params = { site: values?.site_code }
            const response = await axiosInstance.get(`${process.env.API_GATEWAY}/helios/site/data`, { params })
            const data = response?.data?.data
            setValues({
                ...values,
                ...data,
                warehouse_prefixes: data.prefixes
            })
            setIsWarehouseOnboarded(true)
            setRightTabData(rightTabData.map((item, index) => ({
                ...item,
                completed: index <= 0,
                active: index === 1
            })))
            dispatch(setLoading(SUCCESS))
            setShowToastObj({ show: true, type: 'success', message: 'Success' })
        } catch (err) {
            dispatch(setLoading(SUCCESS))
            setShowToastObj({ show: true, type: 'warning', message: err.message || 'Something went wrong' })
        }
    }

    const handlePrefixClick = async () => {
        try {
            dispatch(fetchWarehouseSchema())
            setIsFetchedPrefixes(true)
            setRightTabData(rightTabData.map((item, index) => ({
                ...item,
                completed: index <= 1,
                active: index === 2
            })))
        } catch (err) {
            setShowToastObj({ show: true, type: 'warning', message: err.message || 'Something went wrong' })
        }
    }

    const handleSubmit = async () => {
        try {
            dispatch(setLoading(LOADING))
            const updatedCreateForm = {
                _action_: {
                    url: formData.path,
                    title: 'Operation',
                    value: 'Create'
                },
            }
            Object.keys(formData.create_form).forEach(fieldName => {
                if (values[fieldName]) {
                    updatedCreateForm[fieldName] = {
                        ...formData.create_form[fieldName],
                        value: typeof values[fieldName] === 'string' ? values[fieldName].trim() : values[fieldName]
                    }
                }
            })
            await handlePostCart(
                formData.path,
                updatedCreateForm,
                [],
                'POST',
                formData.path,
            )
            let userId = sessionStorage.getItem('user_id')
            const res = await axiosInstance.post(`${process.env.API_GATEWAY}/checkout?user_id=${userId}`)
            setShowToastObj({ show: true, type: 'success', message: 'Order created successfully' })
            setTimeout(() => {
                dispatch(setLoading(SUCCESS))
                navigate(`/track/trackorders/orderdetails?orderid=${res?.data?.uuid}`)
            }, 1000)
        } catch (err) {
            dispatch(setLoading(SUCCESS))
            setShowToastObj({ show: true, type: 'warning', message: err?.message || 'Something went wrong' })
        }
    }


    return <>
        {
            showToastObj.show && (
                <Toast
                    showToast={showToastObj.show}
                    setShowToast={setShowToastObj}
                    message={showToastObj.message}
                    type={showToastObj.type}
                />
            )
        }
        <h1 className={styles.heading}>Onboard existing warehouse to CNN</h1>
        {fetchStatus === LOADING ? <LoadingIndicator /> :
            <div className={styles.container}>
                <div className={styles.firstSection}>
                    <div>
                        <Dropdown
                            fit="medium"
                            id="warehouseDropdown"
                            className={`${styles.dropdownField} }`}
                            errorMessage="This field can't be empty"
                            label={'Select the Warehouse to be onboarded'}
                            name={'Warehouse'}
                            onChange={(e) => handleInputChange('site_code', e)}
                            options={siteCodes}
                            placeholder={`Select`}
                            required={true}
                            value={values?.site_code}
                            disabled={isWarehouseOnboarded}
                        />
                        {!isWarehouseOnboarded && <Button
                            label="Next  "
                            onClick={handleSiteCodeClick}
                            disabled={!values?.site_code}
                        />}
                    </div>
                    {isWarehouseOnboarded && <div className={styles.prefixInput}>
                        <MultiInput
                            label={'We have fetched the warehouse prefixes from Helios CMDB. Please review and add or edit the prefixes.'}
                            placeholder={'Enter Prefixes'}
                            id={''}
                            value={Array.isArray(values?.warehouse_prefixes) ? values?.warehouse_prefixes : []}
                            onChange={(e) => { handleInputChange('warehouse_prefixes', e) }}
                            disabled={isFetchedPrefixes}
                            expandable={true}
                        />
                        {!isFetchedPrefixes && <Button
                            label="Next"
                            onClick={handlePrefixClick}
                            disabled={false}
                        />}
                    </div>}
                    {isFetchedPrefixes && <div className={styles.detailsSection}>
                        <div className={styles.insideContainer}>
                            <TextField
                                fit="medium"
                                className={`${styles.inputField}`}
                                label={`Primary CNN`}
                                onChange={() => { }}
                                placeholder={`CPrimary CNN`}
                                required={false}
                                error={false}
                                errorMessage="This field can't be empty"
                                type="text"
                                id={``}
                                value={values.cnn_primary}
                                variant="default"
                                disabled={true}
                            />
                            <TextField
                                fit="medium"
                                className={`${styles.inputField}`}
                                label={`Secondary CNN`}
                                onChange={() => { }}
                                placeholder={`Secondary CNN`}
                                required={false}
                                error={false}
                                errorMessage="This field can't be empty"
                                type="text"
                                id={``}
                                value={values.cnn_secondary}
                                variant="default"
                                disabled={true}
                            />
                            <SwitchField
                                label={`VPN`}
                                onChange={() => { }}
                                required={false}
                                value={values.vpn}
                                disabled={true}
                                id={``}
                                error={false}
                                errorMessage="This field can't be empty"
                            />
                        </div>
                        <TextField
                            fit="medium"
                            className={`${styles.inputField}`}
                            label={`Service Required`}
                            onChange={() => { }}
                            placeholder={`Enter Data`}
                            required={false}
                            error={false}
                            errorMessage="This field can't be empty"
                            type="text"
                            id={``}
                            value={values.service_required}
                            variant="default"
                            disabled={true}
                        />
                        <div className={styles.insideContainer}>
                            <TextField
                                fit="medium"
                                className={`${styles.inputField}`}
                                label={`Community Tag`}
                                onChange={() => { }}
                                placeholder={`Community Tag`}
                                required={false}
                                error={false}
                                errorMessage="This field can't be empty"
                                type="text"
                                id={``}
                                value={values.community_tag}
                                variant="default"
                                disabled={true}
                            />
                            <TextField
                                fit="medium"
                                className={`${styles.inputField}`}
                                label={`ISP Provider`}
                                onChange={() => { }}
                                placeholder={`ISP Provider`}
                                required={false}
                                error={false}
                                errorMessage="This field can't be empty"
                                type="text"
                                id={``}
                                value={values.isp_provider}
                                variant="default"
                                disabled={true}
                            />
                            <TextField
                                fit="medium"
                                className={`${styles.inputField}`}
                                label={`Location`}
                                onChange={() => { }}
                                placeholder={`Location`}
                                required={false}
                                error={false}
                                errorMessage="This field can't be empty"
                                type="text"
                                id={``}
                                value={values.location}
                                variant="default"
                                disabled={true}
                            />
                            <TextField
                                fit="medium"
                                className={`${styles.inputField}`}
                                label={`Communication address`}
                                onChange={() => { }}
                                placeholder={`Communication address`}
                                required={false}
                                error={false}
                                errorMessage="This field can't be empty"
                                type="text"
                                id={``}
                                value={values.communication_address}
                                variant="default"
                                disabled={true}
                            />
                        </div>
                        {<Button
                            label="Submit "
                            onClick={handleSubmit}
                            disabled={false}
                        />}
                    </div>}
                </div>

                <div className={styles.rightTabSection}>
                    <RightTab tabData={rightTabData} handleClick={handleTabClick} />
                </div>
            </div>}
    </>
}   