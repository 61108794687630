import React from 'react';
import PropTypes from 'prop-types'
import styles from './RightTab.css'

export default function RightTab({ tabData = [], handleClick }) {

    return <div className={styles.container}>
        {tabData.map((item, index) => {
            return <button key={`${index}-${item.title}`} className={styles.item} onClick={() => handleClick(item, index)}>
                <div className={styles.content}>
                    <div className={`${styles.svg} ${item.completed ? styles.white : styles.black}`}>
                        <img
                            src={item.completed ? '/assets/accordianGreen.svg' : '/assets/accordianGrey.svg'}
                            alt='accordian'
                        />
                        <span>{index + 1}</span>
                    </div>
                    <p>{item.title}</p>
                </div>
                {item.active && <img src='/assets/leftArrowBlue.svg' alt='leftArrow' />}
            </button>
        })}
    </div>
}

RightTab.propTypes = {
    tabData: PropTypes.array,
    handleClick: PropTypes.func
}