import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { config } from './config.js'

export const msalInstance = new PublicClientApplication(config)

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    msalInstance.setActiveAccount(event.payload.account)
  }
})
