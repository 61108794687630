import React, { useState, useEffect, useRef } from 'react'
import styles from './MultiDropdown.css'
import CollapseIcon from '../../../../public/assets/upChevron.svg'
import ExpandIcon from '../../../../public/assets/downChevron.svg'
import PropTypes from 'prop-types'

const MultiSelectDropdown = ({ className, options, label, placeholder, maxLength = 20, onChange, value, id, disabled, dataTestId, error, errorMessage }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const dropdownRef = useRef(null)
  const inputRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (dropdownVisible && inputRef.current) {
      inputRef.current.focus()
      if (dropdownRef.current && typeof dropdownRef.current.scrollIntoView === 'function') {
        dropdownRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [dropdownVisible])

  const toggleDropdown = () => {
    if (!disabled) {
      setDropdownVisible(!dropdownVisible)
    }
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleItemClick = (item) => {
    if (disabled) return
    const newSelectedItems = value.includes(item)
      ? value.filter((i) => i !== item)
      : [...value, item]
    onChange(newSelectedItems)
  }

  const handleSelectAll = () => {
    if (disabled) return
    const filteredOptions = options.filter((option) =>
      option.toLowerCase().includes(searchTerm.toLowerCase())
    )
    const allSelected = filteredOptions.every(option => value.includes(option))
    const newSelectedItems = allSelected
      ? value.filter(item => !filteredOptions.includes(item))
      : [...value, ...filteredOptions.filter(option => !value.includes(option))]
    onChange(newSelectedItems)
  }

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className={`${className} ${styles.multiSelectDropdown} ${disabled ? styles.disabled : ''}`} ref={dropdownRef} key={id}>
      <label htmlFor="multi-select-input">{label}</label>
      <button className={styles.inputBox} onClick={toggleDropdown} id="multi-select-input" data-testid={dataTestId}>
        {value.length === 0 && <span className={styles.placeholder}>{placeholder}</span>}
        {value.slice(0, 2).map((item, index) => (
          <span key={`${index}-${item}`} className={styles.itemTag}>
            {item}
            {!disabled && (<button onClick={(e) => { e.stopPropagation(); handleItemClick(item) }}>x</button>)}
          </span>
        ))}
        {value.length > 2 && <span>+{value.length - 2}</span>}
        <img
          src={dropdownVisible ? CollapseIcon : ExpandIcon}
          className={styles.collapseIcon}
          alt={dropdownVisible ? 'Collapse' : 'Expand'}
        />
      </button>
      {dropdownVisible && (
        <div className={styles.dropdown}>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search"
            ref={inputRef}
            maxLength={maxLength}
            disabled={disabled}
          />
          <div className={styles.dropdownItems}>
            {filteredOptions.length > 0 && (
              <div className={styles.dropdownItem}>
                <input
                  type="checkbox"
                  checked={value.length === filteredOptions.length && filteredOptions.length > 0}
                  onChange={handleSelectAll}
                  disabled={disabled}
                />
                <label htmlFor="select-all" >
                  <button onClick={handleSelectAll}>SELECT ALL</button>
                </label>
              </div>
            )}
            {filteredOptions.length
              ? filteredOptions.map((option, index) => (
                <div key={`{${index}-${option}}`} className={styles.dropdownItem}>
                  <input
                    type="checkbox"
                    checked={value.includes(option)}
                    onChange={() => handleItemClick(option)}
                    disabled={disabled}
                  />
                  <label htmlFor="option">
                    <button onClick={() => handleItemClick(option)}>{option}</button>
                  </label>
                </div>
              ))
              : <div className={styles.dropdownItem}>
                <span>No options found</span>
              </div>}
          </div>
        </div>
      )}
    </div>
  )
}

export default MultiSelectDropdown

MultiSelectDropdown.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  value: PropTypes.array,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
}
