import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ChartCard from "../Chart/ChartCard/ChartCard.js";
import Chart from "../Chart/Chart/Chart.js";
import { LoadingIndicator } from "@anchor/react-components/dist/lib/components";
import styles from "./DashboardPage.css"
import { lineChartOptions, pieChartOptions } from "../../staticData/ChartData.js";
import { useDispatch, useSelector } from "react-redux";
import { dateRangeSelector, fetchAuditReport } from "../../redux/features/reportSlice.js";
import { API_ERROR, LOADING, SUCCESS } from "../../constants.js";
import { fetchSummery, fetchWorkflowInsights } from "../../redux/features/dashboardSlice.js";
import DateRangePicker from "../Common/DateRangePicker/DateRangePicker.js";

export default function DashboardPage() {
    const dispatch = useDispatch()
    const [data, setData] = useState({
        allFeatures: { ...pieChartOptions, labels: [], series: [] },
        topTenFeatures: { ...pieChartOptions, labels: [], series: [] },
        userLoggedIn: { ...lineChartOptions, series: [{ name: 'User', data: [] }], xaxis: { categories: [] } },
    });
    const [dataInsight, setDataInsight] = useState([])
    const reportData = useSelector((state) => state.report.reportData);
    const reportParams = useSelector((state) => state.report.params);
    const fetchReportStatus = useSelector((state) => state.report.fetchReportStatus);
    const insightData = useSelector((state) => state.dashboard.insightData);
    const summeryData = useSelector((state) => state.dashboard.summeryData);

    useEffect(() => {
        dispatch(fetchAuditReport());
        dispatch(fetchWorkflowInsights())
        dispatch(fetchSummery())
    }, [reportParams]);

    useEffect(() => {
        setDataInsight(insightData.slice(0, 4))
    }, [insightData])

    useEffect(() => {
        if (fetchReportStatus === SUCCESS) {
            const allFeaturesLabels = Object?.keys(reportData?.top_10_features);
            const allFeaturesSeries = Object?.values(reportData?.top_10_features);

            const topTenFeaturesLabels = Object?.keys(reportData?.top_10_used_apis);
            const topTenFeaturesSeries = Object?.values(reportData?.top_10_used_apis);

            const userLoggedInCategories = Object.keys(reportData?.user_logged);
            const userLoggedInSeries = Object.values(reportData?.user_logged);

            setData({
                allFeatures: {
                    ...pieChartOptions,
                    labels: allFeaturesLabels.length ? allFeaturesLabels : ['No Data'],
                    series: allFeaturesSeries.length ? allFeaturesSeries : [0]
                },
                topTenFeatures: {
                    ...pieChartOptions,
                    labels: topTenFeaturesLabels.length ? topTenFeaturesLabels : ['No Data'],
                    series: topTenFeaturesSeries.length ? topTenFeaturesSeries : [0]
                },
                userLoggedIn: {
                    ...lineChartOptions,
                    series: [{ name: 'User', data: userLoggedInSeries.length ? userLoggedInSeries : [0] }],
                    xaxis: {
                        categories: userLoggedInCategories.length ? userLoggedInCategories : ['No Data'],
                        convertedCatToNumeric: true
                    }
                }
            });
        }
    }, [reportData]);

    const formatDate = (date) => {
        const [dd, mm, yyyy] = date.split('/')
        return `${yyyy}-${mm}-${dd}`
    }

    const handleApplyDate = (dates) => {
        dispatch(dateRangeSelector({
            startDate: formatDate(dates.startDate),
            endDate: formatDate(dates.endDate)
        }))
    }

    const renderContent = () => {
        if (fetchReportStatus === LOADING) {
            return <div className={styles.loader}>
                <LoadingIndicator />
            </div>
        } else if (fetchReportStatus === API_ERROR) {
            return <div className={styles.noSites}>
                No Data Found
            </div>
        } else if (fetchReportStatus === SUCCESS) {
            return <Grid container spacing={2} >
                <Grid item xs={4}>
                    <ChartCard title='Welcome' className={'chartHorizontal'}>
                        <p style={{ padding: '15px', fontSize: '14px', lineHeight: '24px' }}>
                            <strong>ProviGenius </strong>
                            is the Maersk in house developed tool to provide a unified, automated, and intelligent network configuration and provisioning system to manage and operate Maersk Network Infrastructure.
                        </p>
                    </ChartCard>
                </Grid>
                <Grid item xs={4}>
                    <ChartCard title='Summary' className={'chartHorizontal'}>
                        {Object.entries(summeryData)?.map(([name, count], index) => (
                            <div key={`${index}-${name}`} className={styles.tableRow}>
                                <div className={`${styles.tableCell} ${styles.name}`}>{name}</div>
                                <div className={`${styles.tableCell} ${styles.value}`}>{count}</div>
                            </div>
                        ))}
                    </ChartCard>
                </Grid>
                <Grid item xs={4}>
                    <ChartCard title='Workflow Insights' className={'chartHorizontal'}>
                        {dataInsight?.map((item, index) => (
                            <div key={`${index}-${item.name}`} className={styles.tableRow}>
                                <div className={`${styles.tableCell} ${styles.name}`}>{item.workflow_key}</div>
                                <div className={`${styles.tableCell} ${styles.value}`}>{item.workflow_count}</div>
                            </div>
                        ))}
                    </ChartCard>
                </Grid>
                <Grid item xs={6}>
                    <ChartCard title='Top 10 used APIs' className={'chartHorizontal'}>
                        <Chart
                            options={data.topTenFeatures}
                            className={'chartHorizontalPie'}
                        />
                    </ChartCard>
                </Grid>
                <Grid item xs={6}>
                    <ChartCard title='Top 10 Features' className={'chartHorizontal'}>
                        <Chart
                            options={data.allFeatures}
                            className={'chartHorizontalPie'}
                        />
                    </ChartCard>
                </Grid>
                <Grid item xs={12}>

                    <ChartCard title='Users LoggedIn' className={'chartHorizontal'}>
                        <Chart
                            options={data.userLoggedIn}
                            className={'chartHorizontal'}
                        />
                    </ChartCard>
                </Grid>
            </Grid >
        }
    }

    return <>
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'end', marginBottom: '10px' }}>
            <DateRangePicker dateValues={reportParams} handleApplyDate={handleApplyDate} />
        </div>
        {renderContent()}
    </>
}
