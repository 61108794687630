// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalCtn_BoMTf {
  max-height: 80vh;
  padding: 20px;
  z-index: 999;
  max-width: 100% !important;
}

.btnStyles_FOhv3 {
  display: flex;
  padding: 6px var(--Utility-Icon-corner, 8px);
  align-items: center;
  gap: var(--Utility-Icon-corner, 8px);
  margin-right: 16px;
  cursor: pointer;
}

.tableBordered_Rp0FU {
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.tableBordered_Rp0FU th {
  background-color: #f7f7f7;
}
.bulkText_copsW {
  color: var(--mds_brand_appearance_primary_default_text-color);

  /* Desktop/Text small/Regular */
  font-family: "Maersk Text", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.intentIcon_q67Ee {
  border: 1px solid;
  padding: 3px;
  border-radius: 3px;
}

.voidBtn_gievQ {
  background: none;
  border: none;
  cursor: pointer;
}

.tableRow_mNEFC {
  display: flex;
  width: 100%;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Rollback/Rollback.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,4CAA4C;EAC5C,mBAAmB;EACnB,oCAAoC;EACpC,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,6DAA6D;;EAE7D,+BAA+B;EAC/B,sCAAsC;EACtC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,uBAAuB;AACzB","sourcesContent":[".modalCtn {\n  max-height: 80vh;\n  padding: 20px;\n  z-index: 999;\n  max-width: 100% !important;\n}\n\n.btnStyles {\n  display: flex;\n  padding: 6px var(--Utility-Icon-corner, 8px);\n  align-items: center;\n  gap: var(--Utility-Icon-corner, 8px);\n  margin-right: 16px;\n  cursor: pointer;\n}\n\n.tableBordered {\n  border: 1px solid #ddd;\n  border-collapse: collapse;\n}\n\n.tableBordered th {\n  background-color: #f7f7f7;\n}\n.bulkText {\n  color: var(--mds_brand_appearance_primary_default_text-color);\n\n  /* Desktop/Text small/Regular */\n  font-family: \"Maersk Text\", sans-serif;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  /* 142.857% */\n}\n\n.intentIcon {\n  border: 1px solid;\n  padding: 3px;\n  border-radius: 3px;\n}\n\n.voidBtn {\n  background: none;\n  border: none;\n  cursor: pointer;\n}\n\n.tableRow {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalCtn": `modalCtn_BoMTf`,
	"btnStyles": `btnStyles_FOhv3`,
	"tableBordered": `tableBordered_Rp0FU`,
	"bulkText": `bulkText_copsW`,
	"intentIcon": `intentIcon_q67Ee`,
	"voidBtn": `voidBtn_gievQ`,
	"tableRow": `tableRow_mNEFC`
};
export default ___CSS_LOADER_EXPORT___;
