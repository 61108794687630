import React, { useEffect, useState } from 'react';
import style from './ResourceGroupFields.css'
import Dropdown from '../Common/Dropdown/Dropdown';
import TextField from '../Common/TextField/TextField';
import Password from '../Common/Password/Password';
import { Button, Icon } from '@anchor/react-components'
import encrypt from '../../utility/encryption';
import PropTypes from 'prop-types'

export default function ResourceGroupFields({ resourceData, fieldsData, setToast, generateItem }) {
    const [fields, setFields] = useState(fieldsData)
    const [isGenerate, setIsGenerate] = useState(false)
    const [copied, setCopied] = useState(false)
    const [cloudResourceNameValue, setCloudResourceNameValue] = useState('')

    useEffect(() => {
        setFields(fieldsData.map((item) => {
            const options = resourceData[item.name] || [];
            return {
                ...item,
                value: !Array.isArray(options) ? resourceData[item.name] : "",
                options: Array.isArray(options) ? options : undefined
            };
        }));
    }, [resourceData]);

    const copyToClipBoard = (text) => {
        navigator.clipboard.writeText(text)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    const handleInputChange = (title, value) => {
        setFields(prevFields =>
            prevFields.map(field =>
                field.title === title
                    ? { ...field, value: (typeof value === 'object' && !Array.isArray(value) && value !== null) ? value.value : value }
                    : field
            )
        );
    };
    const generate = async () => {
        if (!fields.filter(field => field.required).every(field => field.value && field.value !== undefined))
            return setShowToastObj({ show: true, type: 'warning', message: 'Fill all required fields' })

        try {
            const clientToken = encrypt({
                client_id: fields.find(field => field.fieldName === 'client_id').value,
                client_secret: fields.find(field => field.fieldName === 'client_secret').value
            });

            const payload = fields.reduce((acc, field) => {
                if (!['client_id', 'client_secret', 'resource_group', 'subscription_id', 'tenant_id'].includes(field.fieldName)) {
                    acc[field.fieldName] = field.value !== undefined && field.value !== null ? field.value : '';
                }
                return acc;
            }, {});

            const params = {
                subscription_id: fields.find(field => field.fieldName === 'subscription_id')?.value,
                tenant_id: fields.find(field => field.fieldName === 'tenant_id')?.value,
                client_token: clientToken
            };

            const resourceGroupField = fields.find(field => field.fieldName === 'resource_group');
            if (resourceGroupField) {
                params.resource_group_name = resourceGroupField.value;
            }

            const response = await generateItem(payload, params)
            setCloudResourceNameValue(response)
            setIsGenerate(true)
            setFields(fields.map((item) => ({ ...item, disabled: true })))
            setToast({ show: true, type: 'success', message: 'Success fully generated' })
        } catch (err) {
            setToast({ show: true, type: 'warning', message: err.message || 'Something went wrong' })
        }
    }

    const clearAll = () => {
        setFields(fields.map((item) => ({
            ...item,
            value: item.name !== 'TenantID' ? '' : item.value,
            disabled: false
        })));
        setIsGenerate(false)
    }

    function renderContent(item, index) {
        if (item.type === 'Text') {
            return (
                <TextField
                    fit="medium"
                    className={``}
                    label={item.title}
                    onChange={(e) => { handleInputChange(item.title, e.target.value) }}
                    placeholder={item.placeholder}
                    required={item.required}
                    error={item?.error || false}
                    errorMessage="This field can't be empty"
                    type="text"
                    id={`${index}-${item.title}`}
                    value={item?.value || ''}
                    variant="default"
                    disabled={item?.disabled || false}
                />
            )
        } else if (item.type === 'Dropdown') {
            return (<Dropdown
                fit="medium"
                id={`${index}-${item.title}`}
                className={``}
                errorMessage="This field can't be empty"
                label={item.title}
                name={item.title}
                onChange={(e) => { handleInputChange(item.title, e) }}
                options={item.options || []}
                placeholder={item.placeholder}
                required={item.required}
                value={item?.value || ''}
                disabled={item?.disabled || false}
                searchable={true}
            />)
        } else if (item.type === 'Password') {
            return (
                <Password
                    id={`${index}-${item.title}`}
                    label={item.title}
                    onChange={(e) => { handleInputChange(item.title, e.target.value) }}
                    placeholder={item.placeholder}
                    required={item.required}
                    value={item?.value || ''}
                    disabled={item?.disabled || false}
                    fit="medium"
                    className={``}
                    error={item?.error || false}
                    errorMessage="This field can't be empty"
                    type="text"
                    variant="default"
                />
            )
        }
    }

    return (
        <div className={style.container}>
            <div className={style.fields}>
                {fields.map((item, index) => renderContent(item, index))}
            </div>
            {isGenerate && <div className={style.secondSection}>
                <div className={style.fields}>
                    <TextField
                        fit="medium"
                        className={``}
                        label={'Cloud Resource Name'}
                        onChange={(e) => { }}
                        placeholder={''}
                        required={false}
                        error={false}
                        errorMessage="This field can't be empty"
                        type="text"
                        id={`0-Cloud Resource Name`}
                        value={cloudResourceNameValue}
                        variant="default"
                        disabled={true}
                    />
                    <div>
                        <button className={`${style.copyButton} ${style.tooltip}`} onClick={() => copyToClipBoard(cloudResourceNameValue)}>
                            <span className={style.tooltipText}>{copied ? '✓ Copied to clipboard' : 'Copy to clipboard'}</span>
                            <Icon className={style.copyIcon} name='copy' />
                        </button>
                        <Button
                            className={style.button}
                            variant='outlined'
                            label="Clear"
                            onClick={clearAll}
                            disabled={false}
                        />
                    </div>
                </div>
            </div>}
            {!isGenerate && <div className={style.buttons}>
                <Button
                    variant='outlined'
                    label="Clear"
                    onClick={clearAll}
                    disabled={false}
                />
                <Button
                    label="Generate"
                    onClick={generate}
                    disabled={!fields
                        .filter(field => field.required)
                        .every(field => field.value && field.value !== undefined)}
                />
            </div>}
        </div>
    )
}

ResourceGroupFields.propTypes = {
    resourceData: PropTypes.object,
    fieldsData: PropTypes.array,
    setToast: PropTypes.func,
    generateItem: PropTypes.func
}