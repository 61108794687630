import { Icon } from '@anchor/react-components/dist/lib/components/index.js'
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styles from './Button.css'

const Button = forwardRef(({ dataTestId, icon, appearance, label, onClick, className, disabled }, ref) => {
    return (
        <button
            data-testid={dataTestId}
            className={`${styles.button} ${styles[appearance]} ${className} ${disabled ? styles.disabled : ''}`}
            onClick={onClick}
            disabled={disabled}
            ref={ref}
        >
            <div className={`${styles.buttonContainer}`}>
                <Icon name={icon} />
                <span>{label}</span>
            </div>
        </button>
    );
});

Button.propTypes = {
    dataTestId: PropTypes.string,
    icon: PropTypes.string,
    appearance: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Button;