import React, { useState } from 'react';
import styles from './AegisBackup.css'
import { Button, LoadingIndicator } from '@anchor/react-components'
import TextField from '../Common/TextField/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utility/axiosInstance.js';
import { setData, setLoading } from '../../redux/features/aegisBackupSlice.js';
import Toast from '../../commonComponents/Toast/Toast.js';
import { LOADING, SUCCESS } from '../../constants.js';

export default function AegisBackup() {
    const { fetchStatus } = useSelector(state => state.aegisBackup)
    const [hostName, setHostName,] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showToastObj, setShowToastObj] = useState({ show: false, type: '', message: '' })

    const handleSearch = async () => {
        try {
            dispatch(setLoading(LOADING))
            const response = await axiosInstance.post(`${process.env.API_GATEWAY}/aegis-backup/get-backup`, {
                hostname_or_ip: hostName
            })
            dispatch(setData({ data: response.data, hostName }))
            dispatch(setLoading(SUCCESS))
            navigate(`/aegis/detail`)
        } catch (err) {
            dispatch(setLoading(SUCCESS))
            setShowToastObj({ show: true, type: 'error', message: err.message })
        }
    }

    return <>
        {fetchStatus === LOADING ? <LoadingIndicator /> :
            < div className={styles.container}>
                <div className={styles.firstSection}>
                    {<div className={styles.detailsSection}>
                        <div className={styles.insideContainer}>
                            <TextField
                                fit="medium"
                                className={`${styles.inputField}`}
                                label={`Host Name`}
                                onChange={(e) => { setHostName(e.target.value) }}
                                placeholder={`Enter Host Name`}
                                required={true}
                                error={false}
                                errorMessage="This field can't be empty"
                                type="text"
                                id={``}
                                value={hostName}
                                variant="default"
                                disabled={false}
                            />
                            {<Button
                                label="Search"
                                style={{ marginTop: '18px' }}
                                onClick={handleSearch}
                                disabled={!hostName}
                            />}
                        </div>
                    </div>}
                </div>
            </div >}
        {
            showToastObj.show &&
            <Toast
                showToast={showToastObj.show}
                setShowToast={setShowToastObj}
                message={showToastObj.message}
                type={showToastObj.type}
            />
        }
    </>
}   