import axios from 'axios'
import { msalInstance } from './msalInstance.js'

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(async (config) => {
  const account = msalInstance.getAllAccounts()[0]
  if (account) {
    const request = {
      scopes: ['openid'],
      account
    }

    try {
      const response = await msalInstance.acquireTokenSilent(request)
      if (!config.headers.token) config.headers.token = response.idToken
      if (!config.headers.accessToken) config.headers.accessToken = response.accessToken

      const userManagerPayload = {
        name: account.name,
        username: account.username,
        email: account.username
      }
      sessionStorage.setItem('name', userManagerPayload.name)
      sessionStorage.setItem('email', userManagerPayload.email)
    } catch (error) {
      console.error('Token acquisition failed:', error)
    }
  }
  return config
}, (error) => {
  return Promise.reject(new Error(error))
})

axiosInstance.interceptors.response.use((response) => {
  return response
}, async (error) => {
  const originalRequest = error?.config

  if (error?.response?.status === 401 && !originalRequest?._retry) {
    originalRequest._retry = true

    sessionStorage.clear()
    window.location.href = '/'
  }
  return Promise.reject(new Error(error?.response?.data?.message || error?.response?.data?.errorText || 'Something went wrong'));
})

export default axiosInstance
