// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_zIjq7 {
  display: flex;
  justify-content: space-between;
}

.firstSection_BzvRj {
  width: 70%;
}

.inputCtn_tOwyu {
  display: flex;
  align-items: end;
  gap: 24px;
  flex-wrap: wrap;
}

.inputCtn_tOwyu > * {
  flex: 1 1 calc((100% - 24%) / 3);
  max-width: calc((100% - 24%) / 3);
}

.dropdownField_YrDXq {
  width: 100%;
}

.heading_u_gNM {
  font-size: 32px;
  font-weight: 400;
}

.multiInput_bmCeI {
  margin-bottom: 0px;
  padding-top: 6px;
  width: 100%;
}

.button_cpILC {
  margin-top: 30px;
}

.textFieldHost_fhOyM {
  max-width: 500px;
}

.detailsSection_re_yk {
  padding-top: 2%;
  margin-top: 22px;
  margin-right: 20px;
  border-top: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);
}

.insideContainer_UzQNR {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  margin-bottom: 6px;
}

.rightTabSection_RcwVj {
  height: calc(100vh - 242px);
  border: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 4px 3px;
  width: 30%;
  overflow: auto;
}

.hostnameContainer_iZvWO {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/FirewallUpgrade/FirewallUpgrade.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,gCAAgC;EAChC,iCAAiC;AACnC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,+DAA+D;AACjE;;AAEA;EACE,aAAa;EACb,gDAAgD;EAChD,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,2DAA2D;EAC3D,+CAA+C;EAC/C,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,gDAAgD;EAChD,SAAS;AACX","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n}\n\n.firstSection {\n  width: 70%;\n}\n\n.inputCtn {\n  display: flex;\n  align-items: end;\n  gap: 24px;\n  flex-wrap: wrap;\n}\n\n.inputCtn > * {\n  flex: 1 1 calc((100% - 24%) / 3);\n  max-width: calc((100% - 24%) / 3);\n}\n\n.dropdownField {\n  width: 100%;\n}\n\n.heading {\n  font-size: 32px;\n  font-weight: 400;\n}\n\n.multiInput {\n  margin-bottom: 0px;\n  padding-top: 6px;\n  width: 100%;\n}\n\n.button {\n  margin-top: 30px;\n}\n\n.textFieldHost {\n  max-width: 500px;\n}\n\n.detailsSection {\n  padding-top: 2%;\n  margin-top: 22px;\n  margin-right: 20px;\n  border-top: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);\n}\n\n.insideContainer {\n  display: grid;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n  gap: 20px;\n  margin-bottom: 6px;\n}\n\n.rightTabSection {\n  height: calc(100vh - 242px);\n  border: 1px solid var(--Color-Border-Neutral-weak, #dbdbdb);\n  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 4px 3px;\n  width: 30%;\n  overflow: auto;\n}\n\n.hostnameContainer {\n  display: grid;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container_zIjq7`,
	"firstSection": `firstSection_BzvRj`,
	"inputCtn": `inputCtn_tOwyu`,
	"dropdownField": `dropdownField_YrDXq`,
	"heading": `heading_u_gNM`,
	"multiInput": `multiInput_bmCeI`,
	"button": `button_cpILC`,
	"textFieldHost": `textFieldHost_fhOyM`,
	"detailsSection": `detailsSection_re_yk`,
	"insideContainer": `insideContainer_UzQNR`,
	"rightTabSection": `rightTabSection_RcwVj`,
	"hostnameContainer": `hostnameContainer_iZvWO`
};
export default ___CSS_LOADER_EXPORT___;
