import React, { useState } from 'react'
import { Modal, TextArea, Button } from '@anchor/react-components'
import PropTypes from 'prop-types'

const RejectModal = ({ rejectModal, handleRejectModalClose, handleReject }) => {
  const [rejectNote, setRejectNote] = useState('')
  const [disabledBtn, setDisabledBtn] = useState(true)

  const handleNotesChange = (event) => {
    setRejectNote(event.target.value)
    setDisabledBtn(event.target.value === '')
  }

  return <Modal
        width='400px'
        open={rejectModal}
        onClose={handleRejectModalClose}
        actions={{
          primaryAction: <Button label="Submit" onClick={() => handleReject(rejectNote)} disabled={disabledBtn} />
        }}
        heading="Description">
        <TextArea
            required
            fit="medium"
            id="text-area"
            label="Notes"
            onChange={handleNotesChange}
            value={rejectNote}
        />
    </Modal>
}

export default RejectModal

RejectModal.propTypes = {
  rejectModal: PropTypes.bool,
  handleRejectModalClose: PropTypes.func,
  handleReject: PropTypes.func
}
