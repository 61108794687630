import React, { useState } from 'react'
import styles from './BasketPage.css'
import { Button } from '@anchor/react-components'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../utility/axiosInstance.js'
import { fetchCartData } from '../../redux/features/sitePageSlice.js'
import Toast from '../../commonComponents/Toast/Toast.js'
import { useNavigate } from 'react-router-dom'

const BasketPage = () => {
  const navigate = useNavigate()
  const [expandedRows, setExpandedRows] = useState([])
  const cartData = useSelector((state) => state.sitePage.cartData)
  const [showToastObj, setShowToastObj] = useState({ show: false, type: '', message: '' })
  const dispatch = useDispatch()

  let userId = sessionStorage.getItem('user_id')
  const name = sessionStorage.getItem('name')
  const email = sessionStorage.getItem('email')

  const handleUserCreation = async () => {
    let userId = sessionStorage.getItem('user_id')
    if (!userId) {
      const newUserResponse = await axiosInstance.post(process.env.USER_MANAGER, {
        name,
        username: name,
        email
      })
      userId = newUserResponse.data.user_id
      sessionStorage.setItem('user_id', userId)
    }
    return userId
  }

  const handleCheckout = async () => {
    userId = await handleUserCreation()
    try {
      const res = await axiosInstance.post(`${process.env.API_GATEWAY}/checkout?user_id=${userId}`)
      dispatch(fetchCartData())
      Object.keys(sessionStorage).forEach(key => {
        if (key.startsWith('data_for')) {
          sessionStorage.removeItem(key)
        }
      })
      sessionStorage.removeItem('catalogBreadcrumb')
      sessionStorage.removeItem('breadcrumb')
      setShowToastObj({ show: true, type: 'success', message: 'Cart checkout successfully' })
      setTimeout(() => {
        navigate(`/track/trackorders/orderdetails?orderid=${res?.data?.uuid}`)
      }, 1000)
    } catch (err) {
      setShowToastObj({ show: true, type: 'error', message: err.message })
    }
  }

  const handleClearAll = async () => {
    try {
      userId = await handleUserCreation()
      const res = await axiosInstance.delete(`${process.env.API_GATEWAY}/cart?user_id=${userId}`)
      dispatch(fetchCartData())
      if (!res.data) {
        setShowToastObj({ show: true, type: 'success', message: 'Cart cleared successfully' })
        Object.keys(sessionStorage).forEach(key => {
          if (key.startsWith('data_for')) {
            sessionStorage.removeItem(key)
          }
        })
        sessionStorage.removeItem('catalogBreadcrumb')
        sessionStorage.removeItem('breadcrumb')
      } else {
        setShowToastObj({ show: true, type: 'error', message: 'Error in clearing cart' })
      }
    } catch (err) {
      setShowToastObj({ show: true, type: 'error', message: err.message })
    }
  }

  const toggleRowExpansion = (index) => {
    const newExpandedRows = [...expandedRows]
    if (newExpandedRows.includes(index)) {
      newExpandedRows.splice(newExpandedRows.indexOf(index), 1)
    } else {
      newExpandedRows.push(index)
    }
    setExpandedRows(newExpandedRows)
  }

  function getTextColorClass(value) {
    if (value === 'Create') {
      return styles.greenText
    } else if (value === 'Modify') {
      return styles.orangeText
    } else if (value === 'Delete') {
      return styles.redText
    } else {
      return ''
    }
  }

  return <><div className={styles.parentCtn} style={{ width: '100%' }}>
    {cartData?.items?.length
      ? <div className={styles.btnCtn}>
        <Button
          appearance="error"
          id="primary"
          justifyItems="center"
          icon="trash"
          label="Clear All"
          name="primary"
          onClick={handleClearAll}
          title="Clear All"
          variant="filled"
          className={`${styles.clearAll} ${cartData?.items?.length ? '' : styles.disabled}`}
        />
        <Button
          appearance="default"
          id="primary"
          justifyItems="center"
          icon="check-circle"
          label="Checkout"
          name="primary"
          onClick={handleCheckout}
          title="Checkout"
          variant="filled"
          className={`${cartData?.items?.length ? '' : styles.disabled}`}
        />
      </div>
      : <div className={styles.btnCtn}></div>}
    {cartData?.items?.length
      ? cartData?.items?.map((detail, index) => {
        const items = Object.values(detail.form_data).sort((a, b) => a.title.localeCompare(b.title))
        const operationItem = items.find(item => item.title === 'Operation')
        const itemsWithOperation = items.filter(item => item.title !== 'Operation')
        const expandedRowItems = itemsWithOperation.slice(3)
        while (expandedRowItems.length % 5 !== 0) {
          expandedRowItems.push({ title: '', value: '' })
        }
        while (itemsWithOperation.length < 3) {
          itemsWithOperation.push({ title: '', value: '' })
        }
        return <div key={`${index}-${detail.url}`} className={styles.eachRow}>
          <div className={styles.flexRow}>
            <div className={styles.itemInRow}>
              <div className={styles.title}>Operation</div>
              <div className={`${styles.blacktext} ${getTextColorClass(operationItem?.value)}`}>{operationItem?.value}</div>
            </div>
            <div className={styles.itemInRow}>
              <div className={styles.title}>Url</div>
              <div className={`${styles.value} ${styles.tooltip}`}>
                {detail.url.split('/')[0]} /
                {detail.url.split('/').length > 1 && (
                  <>
                    <span className={styles.tooltipText}>
                      {detail.url.split('/').join('/')}
                    </span>
                    <span>{detail.url.split('/')[1].slice(0, 5)}...</span>
                  </>
                )}
              </div>
            </div>
            {itemsWithOperation.slice(0, 3).map((field, i) => (
              <div key={`${i}-${field.value}`} className={styles.itemInRow}>
                <div className={styles.title}>{field.title}</div>
                <div className={`${styles.value}`}>
                  {Array.isArray(field.value)
                    ? (
                      <div className={styles.tooltip}>
                        {field.value[0]}
                        {field.value.length > 1 && (
                          <>
                            <span className={styles.tooltipText}>
                              {field.value.join(', ')}
                            </span>
                            <span>, {field.value[1].slice(0, 3)}...</span>
                          </>
                        )}
                      </div>
                    )
                    : (
                      `${field.value}`
                    )}
                </div>
              </div>
            ))}

            {itemsWithOperation.length > 3 && !expandedRows.includes(index) && (
              <button className={styles.downChevron} onClick={() => toggleRowExpansion(index)}>
                <img src='/assets/downChevron.svg' alt='down chevron' />
              </button>
            )}
          </div>
          {expandedRows.includes(index) && (
            <div className={styles.flexRow}>
              {expandedRowItems.map((field, i) => (
                <div key={`${i}-${field.title}`} className={styles.itemInRow}>
                  <div className={styles.title}>{field.title}</div>
                  <div className={`${styles.value}`}>
                    {Array.isArray(field.value)
                      ? (
                        <div className={styles.tooltip}>
                          {field.value[0]}
                          {field.value.length > 1 && (
                            <>
                              <span className={styles.tooltipText}>
                                {field.value.join(', ')}
                              </span>
                              <span>, {field.value[1].slice(0, 3)}...</span>
                            </>
                          )}
                        </div>
                      )
                      : (
                        `${field.value}`
                      )}
                  </div>
                </div>
              ))}
              {expandedRows.includes(index) && (
                <button className={styles.downChevron} onClick={() => toggleRowExpansion(index)} >
                  <img src='/assets/upChevron.svg' alt='up chevron' />
                </button>
              )}
            </div>
          )}
        </div>
      })
      : <div className={styles.noData}>No cart data</div>}
  </div >
    {showToastObj.show
      ? <Toast
        showToast={showToastObj.show}
        setShowToast={setShowToastObj}
        message={showToastObj.message}
        type={showToastObj.type} />
      : null}
  </>
}
export default BasketPage
