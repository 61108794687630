import { ADMIN, ANALYSE, AUDIT, CATALOG, DASHBOARD, DEVICE_CONFIG, FIREWALL_CONFIG, METADATA, MIGRATE_WAREHOUSE, REPORTS, TRACK, TRACK_ORDERS, USAGE_REPORT, PROVIDE, ONBOARD_EXISTING_WAREHOUSE_TO_CNN, CLOUD_RESOURCE_NAMING, RESOURCE_GROUP_NAMING, UTILITIES, AEGIS_BACKUP, CUSTOM_AUTOMATION } from '../constants.js'

export const navItems = [
  {
    name: 'Dashboard',
    id: DASHBOARD,
    icon: '/assets/summary.svg',
    bredCrumbName: 'dashboard',
    showChevron: false,
    path: '/',
    order: 1
  }, {
    name: 'Catalog',
    id: CATALOG,
    icon: '/assets/catalogIcon.svg',
    bredCrumbName: 'catalog',
    showChevron: true,
    subMenuItems: [],
    order: 2
  },
  {
    name: 'Track',
    id: TRACK,
    icon: '/assets/trackIcon.svg',
    bredCrumbName: 'track',
    showChevron: true,
    order: 3,
    subMenuItems: [{
      name: 'Orders',
      path: '/track/trackorders',
      id: TRACK_ORDERS,
      bredCrumbName: 'trackorders'
    }, {
      name: 'Audit',
      path: '/track/audit',
      id: AUDIT,
      bredCrumbName: 'audit'
    }]
  }, {
    name: 'Reports',
    id: REPORTS,
    iconName: 'file-magnifying-glass',
    bredCrumbName: 'reports',
    showChevron: true,
    order: 4,
    subMenuItems: [
      {
        name: 'Usage Report',
        path: '/reports/usagereport',
        id: USAGE_REPORT,
        bredCrumbName: 'usagereport'
      }

    ]
  },
  {
    name: 'Metadata',
    id: METADATA,
    iconName: 'file-other',
    bredCrumbName: 'metadata',
    showChevron: true,
    subMenuItems: [],
    order: 5
  },
  {
    name: 'Custom',
    id: CUSTOM_AUTOMATION,
    iconName: 'clipboard-officer-text',
    bredCrumbName: 'customautomation',
    showChevron: true,
    order: 6,
    subMenuItems: [
      {
        name: 'Migrate Warehouse',
        id: MIGRATE_WAREHOUSE,
        bredCrumbName: 'migratewarehouse',
        path: '/customautomation/migratewarehouse',
      },
      {
        name: 'Firewall Config',
        id: FIREWALL_CONFIG,
        bredCrumbName: 'firewall',
        path: '/customautomation/firewall',
      }
    ]
  },
  {
    name: 'Provide',
    id: PROVIDE,
    iconName: 'office-2',
    bredCrumbName: 'provide',
    showChevron: true,
    order: 7,
    subMenuItems: [
      {
        name: 'Onboard existing warehouse to CNN',
        path: '/provide/onboardexistingwarehousetocnn',
        id: ONBOARD_EXISTING_WAREHOUSE_TO_CNN,
        bredCrumbName: 'onboardexistingwarehousetocnn'
      }

    ]
  },
  {
    name: 'Utilities',
    id: UTILITIES,
    iconName: 'folder-plus',
    bredCrumbName: 'Utilities',
    showChevron: true,
    order: 8,
    subMenuItems: [
      {
        name: 'Cloud Resource Naming',
        path: '/Utilities/cloudresourcenaming',
        id: CLOUD_RESOURCE_NAMING,
        bredCrumbName: 'cloudresourcenaming'
      }, {
        name: 'Resource Group Naming',
        path: '/Utilities/resourcegroupnaming',
        id: RESOURCE_GROUP_NAMING,
        bredCrumbName: 'resourcegroupnaming'
      }
    ]
  },
  {
    name: 'Analyse',
    id: ANALYSE,
    bredCrumbName: 'analyse',
    showChevron: true,
    icon: '/assets/analyse.svg',
    path: '/analyse',
    disabled: true,
    order: 9
  },
  {
    name: 'Admin',
    id: ADMIN,
    showChevron: true,
    bredCrumbName: 'admin',
    iconName: 'user-key',
    path: '/admin',
    disabled: true,
    order: 10
  },
  {
    name: 'Aegis',
    id: AEGIS_BACKUP,
    bredCrumbName: 'aegis',
    showChevron: false,
    icon: '/assets/cloud-backup.svg',
    path: '/aegis',
    order: 11
  },
]
