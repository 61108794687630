import React, { useEffect } from 'react'
import { BrowserRouter as Router, useNavigate } from 'react-router-dom'
import TopNavbar from './components/TopNavbar/TopNavbar.js'
import LeftNavbar from './components/LeftNavbar/LeftNavbar.js'
import styles from './App.css'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from '@azure/msal-react'
import { useDispatch, useSelector } from 'react-redux'
import { clickSidebarItem } from './redux/features/leftNavbarSlice.js'
import { BUILD, CATALOG, DASHBOARD, FIREWALL_CONFIG, METADATA, MIGRATE_WAREHOUSE, TRACK } from './constants.js'
import { handleCatalogBreadcrumb, handlePreviousSitePath, handleSitePath } from './redux/features/sitePageSlice.js'
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs.js'
import AuthenticatedRoutes from './Routes/AuthenticatedRoutes.js'
import UnauthenticatedRoutes from './Routes/UnauthenticatedRoutes.js'
import { ThemeProvider } from '@anchor/react-components/dist/lib/components/index.js'
import UserScopesModal from './components/TopNavbar/UserScopesModal.js'
import { msalInstance } from './utility/msalInstance.js'

const WrappedView = () => {
  const { instance } = useMsal()
  const selectedSidebarItem = useSelector((state) => state.leftNavbar.selectedSidebarItem)
  const showUserScopes = useSelector((state) => state.leftNavbar.showUserScopes)
  const isPageNotFound = useSelector((state) => state.app.isPageNotFound)
  const catalogBreadcrumb = useSelector((state) => state.sitePage.catalogBreadcrumb)
  const sitePagePath = useSelector((state) => state.sitePage.sitePagePath)
  const previousSitePagePath = useSelector((state) => state.sitePage.previousSitePagePath)
  const hostName = useSelector((state) => state.aegisBackup.hostName)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if ([CATALOG, METADATA, BUILD].includes(selectedSidebarItem) && catalogBreadcrumb !== selectedSidebarItem) {
      dispatch(handlePreviousSitePath(sitePagePath))
      dispatch(handleSitePath(catalogBreadcrumb))
      navigate(`/${catalogBreadcrumb}`)
    }
  }, [catalogBreadcrumb])

  function manageBreadCrumbs() {
    const _catalogPath = sessionStorage.getItem('catalogBreadcrumb')
    dispatch(handleCatalogBreadcrumb(_catalogPath))
    if (_catalogPath && (_catalogPath.includes('catalog') || _catalogPath.includes('metadata') || _catalogPath.includes('build'))) {
      dispatch(handleSitePath(_catalogPath))
    }

    const updateSelectedNavItemBasedOnRoute = () => {
      const { pathname } = location
      let selectedNavItem
      if (pathname.includes('catalog')) {
        selectedNavItem = CATALOG
      } else if (pathname.includes('metadata')) {
        selectedNavItem = METADATA
      } else if (pathname.includes('build')) {
        selectedNavItem = BUILD
      } else if (pathname.includes('trackorders')) {
        selectedNavItem = TRACK
      } else if (pathname === '/migratewarehouse') {
        selectedNavItem = MIGRATE_WAREHOUSE
      } else if (pathname === '/firewall') {
        selectedNavItem = FIREWALL_CONFIG
      } else {
        selectedNavItem = DASHBOARD
      }
      dispatch(clickSidebarItem(selectedNavItem))
    }
    updateSelectedNavItemBasedOnRoute()
  }

  useEffect(() => {
    manageBreadCrumbs()
  }, [])

  return (
    <ThemeProvider>
      <AuthenticatedTemplate>
        <>
          <TopNavbar />
          <UserScopesModal showUserScopes={showUserScopes} />
          <div className={styles.layout}>
            {isPageNotFound ? null : <LeftNavbar />}
            <div className={styles.rightCtn}>
              <div className={styles.contentCtn}>
                {isPageNotFound ? null : <Breadcrumbs />}
                <AuthenticatedRoutes
                  sitePagePath={sitePagePath}
                  previousSitePagePath={previousSitePagePath}
                  hostName={hostName} />
              </div>
              <div className={styles.footerCtn}>
                <img src="/assets/maerskLogo.svg" className={styles.maerskLogo} alt="Maersk Logo" />&nbsp;© ProviGenius
              </div>
            </div>
          </div>
        </>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedRoutes instance={instance} />
      </UnauthenticatedTemplate>
    </ThemeProvider>
  )
}
const App = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <WrappedView />
      </Router>
    </MsalProvider>
  )
}

export default App
