import React from 'react'
import { Modal } from '@anchor/react-components'
import PropTypes from 'prop-types'

function MessageView ({ isOpenModal, jsonData, handleCloseMessageView }) {
  return (
    <Modal
      open={isOpenModal}
      heading="Response Body"
      onClose={handleCloseMessageView}
      dimension='medium'
      showCloseIcon
      height='500px'
      zindex={999}
    >
      <pre>{JSON.stringify(jsonData, null, 2)}</pre>
    </Modal>

  )
}

export default MessageView

MessageView.propTypes = {
  isOpenModal: PropTypes.bool,
  jsonData: PropTypes.object,
  handleCloseMessageView: PropTypes.func
}
