import React from 'react'
import ReactApexChart from 'react-apexcharts'
import Style from './Chart.css'
import PropTypes from 'prop-types'

export default function Chart ({ options, className }) {
  function renderLegend () {
    if (options?.chart?.type === 'pie') {
      return <div className={Style.customLegend}>
                {options?.labels?.map((labels, index) => (
                    <div key={`${index}-${labels}`} className={Style.legendItem}>
                        <span
                            className={Style.legendColor}
                            style={{ backgroundColor: options?.colors[index] }}
                        ></span>
                        {labels}
                    </div>
                ))}
            </div>
    } else if (options?.chart?.type === 'line') {
      return <div className={Style.customLegend}>
                {options?.series?.map((series, index) => (
                    <div key={`${index}-${series?.name}`} className={Style.legendItem}>
                        <span
                            className={Style.legendColor}
                            style={{ backgroundColor: options?.stroke?.colors[index] }}
                        ></span>
                        {series?.name}
                    </div>
                ))}
            </div>
    }
  }
  return (
        <div className={Style[className]}>
            <div className={Style.chartContainer}>
                <ReactApexChart
                    options={options}
                    series={options?.series}
                    type={options?.chart?.type}
                    height={options?.chart?.height}
                />
            </div>
            {renderLegend()}
        </div>
  )
}

Chart.propTypes = {
  options: PropTypes.object,
  className: PropTypes.string
}
