import React, { useState, useEffect } from "react";
import { LoadingIndicator, Modal } from '@anchor/react-components'
import styles from './Rollback.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { LOADING } from "../../constants";
import { fetchRollbackData, setRollbackPagination } from "../../redux/features/sitePageSlice";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utility/axiosInstance";
import DataTable from '../DataGrid/DataGrid.js'

const RollbackModal = ({ open, handleClose, showToast }) => {
    const [rows, setRows] = useState([])
    const [intentShow, setIntentShow] = useState(false)
    const [intentData, setIntentData] = useState({})
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const rollbackData = useSelector(state => state.sitePage.rollbackList);
    const rollBackFetchStatus = useSelector(state => state.sitePage.rollBackFetchStatus);
    const { page, pageSize, total } = useSelector(state => state.sitePage.rolBackPagination);

    useEffect(() => {
        dispatch(fetchRollbackData());
    }, [dispatch, page, pageSize]);

    useEffect(() => {
        if (rollbackData?.length) {
            setRows(rollbackData);
        }
    }, [rollbackData]);


    const showIntent = (intentData) => {
        setIntentShow(true)
        setIntentData(intentData)
    }

    const closeIntent = () => {
        setIntentShow(false)
        setIntentData({})
    }

    const rollBackItem = async (uuid) => {
        try {

            const response = await axiosInstance.post(`${process.env.API_GATEWAY}/rollback-checkout?uuid=${uuid}`);
            showToast({ show: true, type: 'success', message: 'Time Travel successfully done' })
            setIntentShow(false)
            setIntentData({})
            setTimeout(() => {
                navigate(`/track/trackorders/orderdetails?orderid=${response.data.uuid}`)
            }, 1000)
        } catch (err) {
            showToast({ show: true, type: 'warning', message: err.message })
        }
    }

    const columnNames = [
        { field: 'date', headerName: 'Date', flex: 1 },
        {
            field: 'intent', headerName: 'Intent', flex: 1,
            renderCell: (params) => {
                return <div className={styles.tableRow}>
                    <button className={styles.voidBtn} onClick={() => showIntent(params.row)}>
                        <img src='/assets/Union.svg' className={styles.intentIcon} alt="rollback-icon" />
                    </button>
                </div>
            }
        },
        { field: 'username', headerName: 'Username', flex: 1 },
        { field: 'commit_uuid', headerName: 'commit_uuid', flex: 1 },
        {
            field: 'action', headerName: 'Action', flex: 1,
            renderCell: (params) => {
                return <div className={styles.tableRow}>
                    <button
                        className={`${styles.btnStyles}`}
                        onClick={() => { rollBackItem(params.row.commit_uuid) }}
                    >
                        <img src='/assets/rollback1.svg' className={styles.downloadIcon} alt="rollback-icon" />
                        <div className={styles.bulkText}>Time Travel</div>
                    </button>
                </div>
            }
        },
    ]

    const handlePaginationModelChange = (newPaginationModel) => dispatch(setRollbackPagination(newPaginationModel))

    return (
        rollBackFetchStatus === LOADING ? <LoadingIndicator /> :
            <Modal
                open={open}
                width="50%"
                height="fit-content"
                onClose={handleClose}
                heading="Time Travel"
                data-testid="rollback-modal"
            >
                <DataTable
                    data={rows}
                    columns={columnNames}
                    handlePaginationModelChange={handlePaginationModelChange}
                    rowCount={total}
                    pageSize={pageSize}
                    page={page}
                />
                <Modal
                    open={intentShow}
                    width="fit-content"
                    height="fit-content"
                    className={styles.dataModal}
                    onClose={closeIntent}
                    heading={intentData?.date}>
                    <div className={styles.contentCtn}>
                        <pre>{JSON.stringify(intentData?.intent, null, 2)}</pre>
                    </div>
                </Modal>
            </Modal>
    );
};

export default RollbackModal;

RollbackModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    showToast: PropTypes.func
}