import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UsageReport from '../components/UsageReport/UsageReport.js'
import SitePage from '../components/SitePage/SitePage.js'
import BasketPage from '../components/BasketPage/BasketPage.js'
import TrackOrdersPage from '../components/TrackOrdersPage/TrackOrdersPage.js'
import OrderDetailsPage from '../components/OrderDetailsPage/OrderDetailsPage.js'
import MigrateWarehouse from '../components/MigrateWarehousePage/MigrateWarehouse.js'
import FirewallPage from '../components/FirewallPage/FirewallPage.js'
import AuditPage from '../components/AuditPage/AuditPage.js'
import ResetPasswordPage from '../components/ResetPasswordPage/ResetPasswordPage.js'
import PageNotFound from '../components/PageNotFound/PageNotFound.js'
import DashboardPage from '../components/DashboardPage/DashboardPage.jsx'
import PropTypes from 'prop-types'
import WarehouseProvision from '../components/WarehouseProvision/WarehouseProvision'
import CloudResourceNaming from '../components/CloudResourceNaming/CloudResourceNaming.js'
import ResourceGroupNaming from '../components/ResourceGroupNaming/ResourceGroupNaming.js'
import AegisBackup from '../components/AegisBackup/AegisBackup.js'
import TextViewer from '../components/AegisBackup/DetailPage.js'
import BaseBuild from '../components/BaseBuild/BaseBuild.js'
import Chatbot from '../components/Chatbot/Chatbot.js'
import FirewallUpgrade from '../components/FirewallUpgrade/FirewallUpgrade.js'

function AuthenticatedRoutes({ sitePagePath, previousSitePagePath, hostName }) {
  return (
    <Routes>
      <Route path='/' element={<DashboardPage />} />
      <Route path={previousSitePagePath} element={<SitePage />} />
      <Route path={sitePagePath} element={<SitePage />} />
      <Route path='/basket' element={<BasketPage />} />
      <Route path='/track/trackorders' element={<TrackOrdersPage />} />
      <Route path="/track/trackorders/orderdetails" element={<OrderDetailsPage />} />
      <Route path="/customautomation/migratewarehouse" element={<MigrateWarehouse />} />
      <Route path="/customautomation/firewall" element={<FirewallPage />} />
      <Route path="/track/audit" element={<AuditPage />} />
      <Route path="/reports/usagereport" element={<UsageReport />} />
      <Route path="/playbook/onboardexistingwarehousetocnn" element={<WarehouseProvision />} />
      <Route path="/aegis" element={<AegisBackup />} />
      <Route path={`/aegis/detail`} element={<TextViewer />} />
      <Route path="/Utilities/cloudresourcenaming" element={<CloudResourceNaming />} />
      <Route path="/Utilities/resourcegroupnaming" element={<ResourceGroupNaming />} />
      <Route path="/resetpassword/:id" element={<ResetPasswordPage />} />
      <Route path="/playbook/basebuild" element={<BaseBuild />} />
      <Route path="/playbook/firewallupgrade" element={<FirewallUpgrade />} />
      <Route path="/chatbot" element={<Chatbot />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

export default AuthenticatedRoutes

AuthenticatedRoutes.propTypes = {
  sitePagePath: PropTypes.string,
  hostName: PropTypes.string,
  previousSitePagePath: PropTypes.string
}
