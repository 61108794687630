import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import { createFormDataUtil, handlePostCart } from '../../utility/utils.js'
import { API_ERROR, LOADING, SUCCESS } from '../../constants.js'
import axiosInstance from '../../utility/axiosInstance.js'


export const handleFormSubmission = createAsyncThunk(
  'handleFormSubmission',
  async ({ url, _actCreateForm, methodType, url2, cartData, addButtonRef, setToast, catalogBreadcrumb }, { dispatch }) => {
    const postUtil = await handlePostCart(url, _actCreateForm, cartData.items, methodType, url2, addButtonRef);
    if (postUtil.error) {
      dispatch(handleSitePageStatus(SUCCESS));
      setToast({ show: true, type: 'warning', message: postUtil.message || 'Something went wrong' });
      return;
    }

    dispatch(handleIsCreatePopupOpen({ open: false }));
    setToast({ show: true, type: 'success', message: 'Item added to the cart successfully' });
    dispatch(fetchCartData()).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        const breadcrumb = sessionStorage.getItem('breadcrumb').split('/');
        const currentTab = breadcrumb[breadcrumb.length - 1];
        dispatch(fetchSchema({ site: catalogBreadcrumb, currentTab }));
      }
    });
  }
);

export const fetchIntentData = createAsyncThunk(
  'fetchIntentData',
  async ({ path, reload = false }, { rejectWithValue, getState }) => {
    try {
      if (reload) {
        sessionStorage.removeItem(`data_for_${path}`)
      }
      if (sessionStorage.getItem(`data_for_${path}`)) {
        return JSON.parse(sessionStorage.getItem(`data_for_${path}`))
      }

      const state = getState()
      const uuid = state.sitePage.cartData.uuid ? state.sitePage.cartData.uuid : ''
      const intentResponse = await axiosInstance.get(`${process.env.API_GATEWAY}/intent`, {
        params: {
          path,
          uuid: uuid || ''
        }
      })
      const intentData = intentResponse.data?.columns_data || []
      return intentData
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)
export const fetchCartData = createAsyncThunk(
  'fetchCartData',
  async (_, { rejectWithValue }) => {
    try {
      let userId = sessionStorage.getItem('user_id')
      const name = sessionStorage.getItem('name')
      const email = sessionStorage.getItem('email')

      if (!userId) {
        const newUserResponse = await axiosInstance.post(process.env.USER_MANAGER, {
          name,
          username: name,
          email
        })
        userId = newUserResponse.data.user_id
        sessionStorage.setItem('user_id', userId)
      }

      const res = await axiosInstance.get(`${process.env.API_GATEWAY}/cart?user_id=${userId}`, { // userId hardcoded for temporary
      })
      return res.data ? res.data : {}
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fetchSchema = createAsyncThunk(
  'fetchSchema',
  async ({ site, currentTab }, { rejectWithValue, dispatch }) => {
    try {
      site = site.split('/')
      const newSite = []
      site.forEach(val => { if (val && val !== 'Undefined' && val !== 'undefined') { newSite.push(val) } })
      site = newSite.join('/')
      const userId = sessionStorage.getItem('user_id')
      const res = await axiosInstance.get(`${process.env.API_GATEWAY}/product-path-data?path=${site}&filters=user_id:${userId}`)
      const data = res.data.table_schema
      const isNested = data.tabs[0]?.is_nested || false
      const childInfo = res.data.child_table_data

      Object.keys(childInfo).forEach(key => {
        const val = childInfo[key]?.columns_data || []
        val.forEach(item => {
          item.is_nested = isNested
        })
        sessionStorage.setItem(`data_for_${key}`, JSON.stringify(val))
      })

      for (const item in data?.tabs) {
        if (!sessionStorage.getItem(data?.tabs[item]?.path)) sessionStorage.setItem(data?.tabs[item]?.path, data?.tabs[item]?.primary_field)
      }
      let schemaData
      if (data.tabs.length > 0) {
        schemaData = data.tabs.map((tab, index) => ({
          id: tab.id,
          title: tab.table_name,
          path: tab.path,
          primary_field: tab.primary_field,
          active: currentTab ? currentTab === tab.id : index === 0,
          is_nested: tab.is_nested,
          is_deletable: tab.is_deletable,
          is_modifiable: tab.is_modifiable,
          is_reconcile_available: tab.is_reconcile_available,
          is_rollback_available: tab.is_rollback_available,
          columnNames: tab.column_names_data?.map(column => ({
            ...column,
            id: 1,
            headerName: column.header_name
          })),
          createForm: createFormDataUtil(tab.create_form)
        }))
        const activeSchema = schemaData.find(schema => schema.active) || schemaData[0]
        dispatch(fetchIntentData({ path: activeSchema?.path, reload: false }))
      }
      return { schemaData }
    } catch (error) {
      const errorPayload = {
        message: error.message,
        code: error.code
        // Add more properties from the error object as needed
      }
      return rejectWithValue(errorPayload)
    }
  }
)

export const fetchRollbackData = createAsyncThunk(
  'fetchRollbackData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const { catalogBreadcrumb, rolBackPagination } = state.sitePage;

      const response = await axiosInstance.get(`${process.env.API_GATEWAY}/network-intent-history`, {
        params: { path: catalogBreadcrumb, page: rolBackPagination.page, per_page_entries: rolBackPagination.pageSize }
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postRollbackCheckout = createAsyncThunk(
  'postRollbackCheckout',
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${process.env.API_GATEWAY}/rollback-checkout?uuid=${uuid}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sitePageSlice = createSlice({
  name: 'sitePage',
  initialState: {
    selectedManageWan: 0,
    schemaData: [],
    columnNames: [],
    intentData: [],
    isCreatePopupOpen: false,
    createPopupHeading: 'Create',
    cartData: {},
    createForm: {},
    selectedTab: '',
    catalogBreadcrumb: '',
    fetchingSitePageStatus: LOADING,
    intentDataStatus: LOADING,
    cartDataStatus: LOADING,
    isNested: false,
    sitePagePath: '',
    previousSitePagePath: '',
    intentDataLoaded: false,
    cartDataLoaded: false,
    schemaLoaded: false,
    isModifiable: false,
    isDeletable: false,
    isRollBack: false,
    isReconcile: false,
    rollbackList: [],
    rollBackFetchStatus: LOADING,
    rollBackOrderId: '',
    createFormEmpty: true,
    rolBackPagination: {
      page: 1,
      pageSize: 4,
      total: 0
    }
  },
  reducers: {
    handleSitePageStatus: (state, action) => {
      state.fetchingSitePageStatus = action.payload
    },
    clickManageWanTab: (state, action) => {
      state.selectedManageWan = action.payload
    },
    setColumnNames: (state, action) => {
      state.columnNames = action.payload
    },
    handleCreateForm: (state, action) => {
      if (state.createFormEmpty) state.createForm = action.payload
    },
    handleCreateFormChange: (state, action) => {
      const { key, value } = action.payload
      state.createForm[key].value = value
    },
    handleFieldError: (state, action) => {
      const { key, error, errorMessage } = action.payload
      state.createForm[key].error = error
      state.createForm[key].errorMessage = errorMessage
    },
    handleIsCreatePopupOpen: (state, action) => {
      const { open, heading } = action.payload
      state.isCreatePopupOpen = open
      state.createPopupHeading = heading
    },
    handleCreateFormEmpty: (state, action) => {
      state.createFormEmpty = action.payload
    },
    handleSubmitForm: (state, action) => {
      const { fieldName, error } = action.payload
      state.createForm[fieldName].error = error.status
      state.createForm[fieldName].errorMessage = error.message
    },
    handleSelectedTabClick: (state, action) => {
      state.schemaData = current(state.schemaData).map((item) => {
        if (action.payload.id === item.id) {
          return {
            ...item, active: true
          }
        } else {
          return { ...item, active: false }
        }
      })
      state.selectedTab = action.payload
      state.isContainer = !action.payload.primary_field
    },
    handleCatalogBreadcrumb: (state, action) => {
      sessionStorage.setItem('catalogBreadcrumb', action.payload)
      state.catalogBreadcrumb = action.payload
    },
    handleSitePath: (state, action) => {
      state.sitePagePath = action.payload
    },
    handlePreviousSitePath: (state, action) => {
      state.previousSitePagePath = action.payload
    },
    handleCatalogBreadcrumbAndSitePath: (state, action) => {
      state.sitePagePath = action.payload
      state.catalogBreadcrumb = action.payload
    },
    setIntentFetchStatus: (state, action) => {
      state.intentDataStatus = action.payload
    },
    setRollbackPagination: (state, action) => {
      state.rolBackPagination.page = action.payload.page;
      state.rolBackPagination.pageSize = action.payload.pageSize;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchema.pending, (state, action) => {
        state.schemaLoaded = false
        state.fetchingSitePageStatus = LOADING
        state.selectedTab = ''
      })
      .addCase(fetchSchema.fulfilled, (state, action) => {
        const { schemaData } = action.payload
        const activeTab = schemaData.find(tab => tab.active)
        state.schemaData = schemaData
        state.columnNames = activeTab?.columnNames || []
        state.createForm = activeTab?.createForm || {}
        state.selectedTab = activeTab || ''
        state.isNested = activeTab?.is_nested || false
        state.isContainer = !(activeTab?.primary_field)
        state.isDeletable = activeTab?.is_deletable || false
        state.isModifiable = activeTab?.is_modifiable || false
        state.isRollBack = activeTab?.is_rollback_available || false
        state.isReconcile = activeTab?.is_reconcile_available || false
        state.schemaLoaded = true // Add this flag
        state.fetchingSitePageStatus = SUCCESS
      })
      .addCase(fetchSchema.rejected, (state, action) => {
        state.eventsPageData = []
        state.isNested = action.payload.is_nested
        state.schemaLoaded = false // Add this flag
        state.fetchingSitePageStatus = API_ERROR
      })
      .addCase(fetchIntentData.pending, (state, action) => {
        state.intentDataStatus = LOADING
      })
      .addCase(fetchIntentData.fulfilled, (state, action) => {
        state.intentData = action.payload
      })
      .addCase(fetchIntentData.rejected, (state, action) => {
        state.intentData = []
        state.intentDataStatus = API_ERROR
      })
      .addCase(fetchCartData.pending, (state, action) => {
        state.cartDataLoaded = false
        state.cartDataStatus = LOADING
      })
      .addCase(fetchCartData.fulfilled, (state, action) => {
        state.cartData = action.payload
        state.cartDataLoaded = true // Add this flag
        state.cartDataStatus = SUCCESS
      })
      .addCase(fetchCartData.rejected, (state, action) => {
        state.cartData = {}
        state.cartDataLoaded = false // Add this flag
        state.cartDataStatus = API_ERROR
      })
      .addCase(handleFormSubmission.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(handleFormSubmission.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(handleFormSubmission.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchRollbackData.pending, (state) => {
        state.rollBackFetchStatus = LOADING;
      })
      .addCase(fetchRollbackData.fulfilled, (state, action) => {
        state.rollbackList = action.payload?.list_intent || [];
        state.rolBackPagination.total = action.payload?.total_count || 0;
        state.rollBackFetchStatus = SUCCESS;
      })
      .addCase(fetchRollbackData.rejected, (state) => {
        state.rollbackList = [];
        state.rollBackFetchStatus = API_ERROR;
      })
      .addCase(postRollbackCheckout.pending, (state) => {
        state.rollBackFetchStatus = LOADING;
      })
      .addCase(postRollbackCheckout.fulfilled, (state, action) => {
        state.rollBackOrderId = action.payload.uuid;
        state.rollBackFetchStatus = SUCCESS;
      })
      .addCase(postRollbackCheckout.rejected, (state) => {
        state.rollBackFetchStatus = API_ERROR;
      });
  }

})

export const {
  clickManageWanTab,
  setColumnNames,
  handleCreateFormChange,
  handleIsCreatePopupOpen,
  handleSubmitForm,
  handleSelectedTabClick,
  handleCatalogBreadcrumb,
  handleSitePageStatus,
  handleCreateForm,
  handleSitePath,
  handleCatalogBreadcrumbAndSitePath,
  handlePreviousSitePath,
  handleFieldError,
  setIntentFetchStatus,
  setRollbackPagination,
  handleCreateFormEmpty
} = sitePageSlice.actions
export default sitePageSlice.reducer
