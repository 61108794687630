import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'appSlice',
  initialState: {
    isPageNotFound: false
  },
  reducers: {
    handleIsPageNotFound: (state, action) => {
      state.isPageNotFound = action.payload
    }
  }
})
export const { handleIsPageNotFound } = appSlice.actions
export default appSlice.reducer
