import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal } from '@anchor/react-components'
import { handlePostCart } from '../../utility/utils.js'
import TextField from '../Common/TextField/TextField.js'
import Dropdown from '../Common/Dropdown/Dropdown.js'
import MultiInput from '../Common/MultiInput/MultiInput.js'
import MultiSelectDropdown from '../Common/MultiDropdown/MultiDropdown.js'
import styles from './CreateSitePopup.css'
import PropTypes from 'prop-types'
import { fetchCartData, fetchSchema, handleCreateFormChange, handleFieldError, handleIsCreatePopupOpen, handleSitePageStatus, handleSubmitForm } from '../../redux/features/sitePageSlice.js'
import SwitchField from '../Common/Switch/SwitchField.js'
import { LOADING, SUCCESS } from '../../constants.js'

const CreateSitePopup = ({ setToast }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const addButtonRef = useRef(null)
  const createPopupForm = useSelector((state) => state.sitePage.createForm)
  const [createForm, setCreateForm] = useState(createPopupForm)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const cartData = useSelector((state) => state.sitePage.cartData)
  const selectedTab = useSelector((state) => state.sitePage.selectedTab)
  const isCreatePopupOpen = useSelector((state) => state.sitePage.isCreatePopupOpen)
  const catalogBreadcrumb = useSelector((state) => state.sitePage.catalogBreadcrumb)
  const createPopupHeading = useSelector((state) => state.sitePage.createPopupHeading)

  useEffect(() => {
    setCreateForm(createPopupForm)
  }, [createPopupForm])

  useEffect(() => {
    setIsButtonDisabled(hasRequiredValue(createForm))
  }, [createForm])

  const handleInputChange = (key, value) => {
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      dispatch(handleCreateFormChange({ key, value: value.value }))
    } else {
      dispatch(handleCreateFormChange({ key, value }))
    }
  }

  function hasRequiredValue(form) {
    for (const key in form) {
      if (form[key]?.is_required || form[key]?.error) {
        const value = form[key]?.value;
        const hasError = form[key]?.error;
        const isEmpty = !value || (Array.isArray(value) && value.length === 0) || (typeof value === 'string' && value.trim() === '');
        if (isEmpty || hasError) {
          return true;
        }
      }
    }
    return false
  }

  function updateBreadcrumb(len, path) {
    if (len <= path.split('/').length) {
      sessionStorage.setItem('breadcrumb', path)
    }
  }

  function isValueNonEmpty(value, fieldType) {
    return (
      (Array.isArray(value) && value.length > 0) ||
      (typeof value === 'object' && value !== null && Object.keys(value).length > 0) ||
      ((typeof value === 'string' && value.trim() !== '') || fieldType === 'Toggle')
    )
  }

  function getTrimmedValue(value) {
    return typeof value === 'string' ? value.trim() : value
  }

  const getBreadcrumbLength = () => {
    const breadcrumb = sessionStorage.getItem('breadcrumb')
    return breadcrumb ? breadcrumb.split('/').length : 0
  }

  const getUrlAndMethodType = (updatedCreateForm) => {
    const path = location.pathname.startsWith('/') ? location.pathname.slice(1) : location.pathname
    const url = path + (selectedTab?.title ? '/' + selectedTab.title : '')

    let methodType
    let url2

    if (createPopupHeading === 'Create') {
      methodType = 'POST'
      url2 = url
    } else if (createPopupHeading === 'Modify') {
      methodType = 'PATCH'
      const value = sessionStorage.getItem(url) || ''
      const itemValue = updatedCreateForm[value]?.value
      url2 = value === '' ? url : `${url}/${itemValue}`
    } else if (createPopupHeading === 'Delete') {
      methodType = 'DELETE'
      const value = sessionStorage.getItem(url) || ''
      const itemValue = updatedCreateForm[value]?.value
      url2 = value === '' ? url : `${url}/${itemValue}`
    }

    return { url, url2, methodType }
  }

  const handleFormSubmission = async (url, _actCreateForm, methodType, url2) => {
    const postUtil = await handlePostCart(url, _actCreateForm, cartData.items, methodType, url2, addButtonRef)
    if (postUtil.error) {
      dispatch(handleSitePageStatus(SUCCESS))
      setToast({ show: true, type: 'warning', message: postUtil.message || 'Something went wrong' })
      return
    }

    dispatch(handleIsCreatePopupOpen({ open: false }))
    setToast({ show: true, type: 'success', message: 'Item added to the cart successfully' })
    dispatch(fetchCartData()).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        const breadcrumb = sessionStorage.getItem('breadcrumb').split('/')
        const currentTab = breadcrumb[breadcrumb.length - 1]
        dispatch(fetchSchema({ site: catalogBreadcrumb, currentTab }))
      }
    })
  }

  const isPatternValid = (pattern, value) => {
    if (pattern !== '.*') { // Skip the wildcard pattern
      const regex = new RegExp(`^${pattern}$`);
      return regex.test(value); // Return true if the pattern matches
    }
    return true; // Wildcard pattern passes
  };

  const checkArrayValues = (patterns, values) => {
    return values.some(singleValue =>
      patterns.every(pattern => !isPatternValid(pattern, singleValue)) // All patterns must fail for a value to be invalid
    );
  };

  const checkStringValue = (patterns, value) => {
    return patterns.every(pattern => !isPatternValid(pattern, value)); // All patterns must fail for a string to be invalid
  };

  const fieldValidation = (key, patterns, value, title) => {
    if (Array.isArray(patterns) && patterns.length && value) {
      let allFail = false;

      if (Array.isArray(value) && value.length) {
        allFail = checkArrayValues(patterns, value);
      } else if (typeof value === 'string') {
        allFail = checkStringValue(patterns, value);
      }

      dispatch(handleFieldError({
        key,
        error: allFail,
        errorMessage: allFail ? `${title} is not a valid input` : ''
      }));
    } else {
      dispatch(handleFieldError({
        key,
        error: false,
        errorMessage: ''
      }));
    }
  };

  const handleAddToBasket = async () => {
    dispatch(handleSitePageStatus(LOADING))
    let validationFailed = false
    let validationMessage = ''
    const updatedCreateForm = {}

    Object.keys(createForm).forEach(fieldName => {
      let value = getTrimmedValue(createForm[fieldName]?.value)
      const isRequired = createForm[fieldName].is_required
      validationFailed = isRequired && value === ''
      validationMessage = validationFailed && `${createForm[fieldName].title} is required`

      const breadcrumbLength = getBreadcrumbLength()
      updateBreadcrumb(breadcrumbLength, selectedTab.path)

      if (validationFailed) dispatch(handleSubmitForm({ fieldName, error: { status: validationFailed, message: validationMessage } }))

      if (isValueNonEmpty(value, createForm[fieldName]?.field_type)) {
        value = (createForm[fieldName]?.field_type === 'Toggle' && (value === '' || value === null)) ? false : value
        updatedCreateForm[fieldName] = {
          ...createForm[fieldName],
          value: Array.isArray(value) ? value : value.value ?? value
        }
      }
    })

    if (!validationFailed) {
      const { url, url2, methodType } = getUrlAndMethodType(updatedCreateForm)
      const _actCreateForm = {
        _action_: {
          url: url2,
          title: 'Operation',
          value: createPopupHeading
        },
        ...updatedCreateForm
      }

      await handleFormSubmission(url, _actCreateForm, methodType, url2)
    }
  }

  function renderComponent(key, index) {
    if (createForm[key].field_type === 'Text' || createForm[key].field_type === 'Number') {
      return (
        <TextField
          fit="medium"
          className={`${styles.inputField} ${(index + 1) % 2 !== 0 ? styles.marginRight : ''}`}
          label={createForm[key].title}
          onChange={(e) => handleInputChange(key, e.target.value)}
          placeholder={`Enter ${createForm[key].title}`}
          required={createForm[key].is_required}
          error={createForm[key].error}
          errorMessage={createForm[key].errorMessage}
          type="text"
          id={`${index}-${createForm[key].title}`}
          value={createForm[key].value}
          variant="default"
          disabled={createForm[key].disabled || false}
          onBlur={() => fieldValidation(key, createForm[key].pattern, createForm[key].value, createForm[key].title)}
        />
      )
    } else if (createForm[key].field_type === 'Array[Text]') {
      return <MultiInput
        label={createForm[key].title}
        placeholder={`Enter ${createForm[key].title}`}
        id={`${index}-${createForm[key].title}`}
        value={Array.isArray(createForm[key].value) ? createForm[key].value : []}
        onChange={(e) => handleInputChange(key, e)}
        disabled={createForm[key].disabled || false}
        error={createForm[key].error}
        errorMessage={createForm[key].errorMessage}
        onBlur={(e) => fieldValidation(key, createForm[key].pattern, e, createForm[key].title)}
      />
    } else if (createForm[key].field_type === 'Dropdown') {
      return <Dropdown
        fit="medium"
        id={`${index}-${createForm[key].title}`}
        className={`${styles.dropdownField} ${(index + 1) % 2 !== 0 ? styles.marginRight : ''}`}
        label={createForm[key].title}
        name={createForm[key].title}
        onChange={(e) => handleInputChange(key, e)}
        options={createForm[key].values}
        placeholder={`Select a ${createForm[key].title} from the list`}
        required={createForm[key].is_required}
        value={createForm[key].value}
        disabled={createForm[key].disabled || false}
        error={createForm[key].error}
        errorMessage={createForm[key].errorMessage}
        onOptionUpdate={(e) => fieldValidation(key, createForm[key].pattern, e, createForm[key].title)}
      />
    } else if (createForm[key].field_type === 'Array[Enumeration]') {
      return <MultiSelectDropdown
        label={createForm[key].title}
        className={`${styles.multiSelectDropdown}`}
        placeholder={`Enter ${createForm[key].title}`}
        id={`${index}-${createForm[key].title}`}
        options={createForm[key].values}
        value={Array.isArray(createForm[key].value) ? createForm[key].value : []}
        onChange={(e) => handleInputChange(key, e)}
        disabled={createForm[key].disabled || false}
        error={createForm[key].error}
        errorMessage={createForm[key].errorMessage}
      />
    } else if (createForm[key].field_type === 'Toggle') {
      return <SwitchField
        label={createForm[key].title}
        onChange={(e) => handleInputChange(key, e)}
        required={createForm[key].is_required}
        value={createForm[key].value}
        disabled={createForm[key].disabled || false}
        id={`${index}-${createForm[key].title}`}
        error={createForm[key].error}
        errorMessage={createForm[key].errorMessage}
      />
    }
  }

  return (
    <Modal
      width='800'
      height="500"
      open={isCreatePopupOpen}
      className={styles.createModal}
      onClose={() => dispatch(handleIsCreatePopupOpen({ open: false }))}
      actions={{
        primaryAction: (
          <Button
            ref={addButtonRef}
            label="Add to basket"
            icon="shopping-cart"
            onClick={handleAddToBasket}
            disabled={isButtonDisabled}
            data-testid="create-site-form"
          />
        )
      }}
      showCloseIcon
    >
      <div className={styles.PopupHeader}>
        <div>{createPopupHeading || 'Create'}</div>
      </div>
      <div className={styles.inputCtn} >
        {createForm && Object.keys(createForm).map((key, index) => (
          renderComponent(key, index)
        ))}
      </div>
    </Modal >
  )
}

export default CreateSitePopup

CreateSitePopup.propTypes = {
  setToast: PropTypes.func
}
