import axiosInstance from './axiosInstance.js'

export const createFormDataUtil = (createForm) => {
  return Object.keys(createForm).reduce((acc, key) => {
    acc[key] = { ...createForm[key], error: false, value: '', disabled: false }
    return acc
  }, {})
}

export const handlePostCart = async (url, createForm, cartData, methodType, url2, addButtonRef) => {
  try {
    let userId = sessionStorage.getItem('user_id')

    const name = sessionStorage.getItem('name')
    const email = sessionStorage.getItem('email')
    if (!userId) {
      const newUserResponse = await axiosInstance.post(process.env.USER_MANAGER, {
        name,
        username: name,
        email
      })
      userId = newUserResponse.data.user_id
      sessionStorage.setItem('user_id', userId)
    }

    const payload = {}
    for (const key in createForm) {
      if (Object.hasOwn(createForm, key) && key !== '_action_') {
        if (createForm[key]?.field_type === 'Toggle') {
          payload[key] = createForm[key].value || false
        } else {
          payload[key] = createForm[key].value || ''
        }
      }
    }

    const cartItems = [
      ...(Array.isArray(cartData) ? cartData : []),
      {
        url: url2,
        method: methodType,
        payload,
        form_data: createForm
      }
    ]

    const _data = {
      user_id: userId,
      breadcrumb: ["PATCH", "DELETE"].includes(methodType) ?
        cartItems.reduce((longest, item) => item.url.length > longest.url.length ? item : longest, { url: '' }).url
        : url,
      items: cartItems
    }

    const postCartUrl = `${process.env.POST_CART}?user_id=${userId}`
    const response = await axiosInstance.post(postCartUrl, _data)
    return response
  } catch (error) {
    return {
      error: true,
      message: error?.response?.data?.message || error?.message || 'An error occurred while posting the cart data',
      status: error?.response?.status || null
    }
  }
}

export const handleOrderStageService = async (stage, status, orderDetailsObj) => {
  try {
    let userId = sessionStorage.getItem('user_id')
    const response = await axiosInstance.post(`${process.env.API_GATEWAY}/order-stage`, {
      uuid: orderDetailsObj.order_id,
      stage,
      status,
      updated_by_user: userId
    })
    console.log('handleOrderStageService ', response)
  } catch (err) {
    console.log('err', err)
  }
}
export const handleDryRunService = async (orderDetailsObj) => {
  try {
    const response = await axiosInstance.post(`${process.env.API_GATEWAY}/dryrun`, {
      conf: {
        uuid: orderDetailsObj.order_id,
        path: 'catalog/sample_model/sample_edge_site/BN-007/sample_vpn'
      },
      dag_run_id: orderDetailsObj.order_id,
      note: ''
    })
    console.log('handleDryRunService', response)
    return response
  } catch (err) {
    console.log('err handleDryRunService', err)
  }
}
export const handleTaskStatusService = async (dag, runId) => {
  try {
    const response = await axiosInstance.post(`${process.env.API_GATEWAY}/full-task-run-status?dag=${dag}&run_id=${runId}`)
    return response
  } catch (err) {
    console.log('err handleDryRunService', err)
  }
}

export const handleDownloadFileService = async (path) => {
  const response = await axiosInstance.post(
    `${process.env.API_GATEWAY}/download-cart?path=${path}`,
    {},
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.ms-excel',
      }
    }
  );
  return response
}

export const handleUploadFileService = async (path, userId, formData) => {
  const response = await axiosInstance.post(`${process.env.API_GATEWAY}/upload-cart?path=${path}&user_id=${userId}`, formData)
  return response
}
