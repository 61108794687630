import { Input } from '@anchor/react-components/dist/lib/components/index.js'
import React from 'react'
import PropTypes from 'prop-types'

export default function TextField({ className, fit, label, onChange, placeholder, required, error, errorMessage, type, id, value, variant, disabled, textRef, dataTestId, onBlur = () => { } }) {
  return (
    <Input
      className={className}
      fit={fit}
      label={label}
      key={id}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      type={type}
      value={value}
      variant={variant}
      disabled={disabled}
      error={error}
      errorMessage={errorMessage}
      ref={textRef}
      onBlur={onBlur}
      data-testid={dataTestId}
    />
  )
}

TextField.propTypes = {
  className: PropTypes.string,
  fit: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  textRef: PropTypes.any,
  dataTestId: PropTypes.string,
  onBlur: PropTypes.func
}
