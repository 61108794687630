import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '../Common/TextField/TextField.js';
import Dropdown from '../Common/Dropdown/Dropdown.js';
import MultiInput from '../Common/MultiInput/MultiInput.js';
import MultiSelectDropdown from '../Common/MultiDropdown/MultiDropdown.js';
import styles from './CreateSitePopup.css';
import PropTypes from 'prop-types';
import { handleFormSubmission, handleCreateFormChange, handleFieldError, handleIsCreatePopupOpen, handleSitePageStatus, handleSubmitForm, handleCreateFormEmpty } from '../../redux/features/sitePageSlice.js';
import SwitchField from '../Common/Switch/SwitchField.js';
import { LOADING } from '../../constants.js';
import Button from '../Common/Button/Button.js';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CreateSitePopup = ({ setToast }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const addButtonRef = useRef(null);
  const createPopupForm = useSelector((state) => state.sitePage.createForm);
  const [createForm, setCreateForm] = useState(createPopupForm);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const cartData = useSelector((state) => state.sitePage.cartData);
  const selectedTab = useSelector((state) => state.sitePage.selectedTab);
  const isCreatePopupOpen = useSelector((state) => state.sitePage.isCreatePopupOpen);
  const catalogBreadcrumb = useSelector((state) => state.sitePage.catalogBreadcrumb);
  const createPopupHeading = useSelector((state) => state.sitePage.createPopupHeading);

  useEffect(() => {
    // Initialize createForm on first render
    setCreateForm(createPopupForm);
    setIsButtonDisabled(hasRequiredValue(createPopupForm));
  }, []);

  const handleInputChange = (key, value) => {
    const actualValue = typeof value === 'object' && !Array.isArray(value) && value !== null ? value.value : value;

    const updatedForm = { ...createForm, [key]: { ...createForm[key], value: actualValue } };
    setCreateForm(updatedForm);
    setIsButtonDisabled(hasRequiredValue(updatedForm));
    dispatch(handleCreateFormChange({ key, value: actualValue }));
  };

  function hasRequiredValue(form) {
    for (const key in form) {
      if (form[key]?.is_required || form[key]?.error) {
        const value = form[key]?.value;
        const hasError = form[key]?.error;
        const isEmpty = !value || (Array.isArray(value) && value.length === 0) || (typeof value === 'string' && value.trim() === '');
        if (isEmpty || hasError) {
          return true;
        }
      }
    }
    return false;
  }

  function updateBreadcrumb(len, path) {
    if (len <= path.split('/').length) {
      sessionStorage.setItem('breadcrumb', path);
    }
  }

  function isValueNonEmpty(value, fieldType) {
    return (
      (Array.isArray(value) && value.length > 0) ||
      (typeof value === 'object' && value !== null && Object.keys(value).length > 0) ||
      ((typeof value === 'string' && value.trim() !== '') || fieldType === 'Toggle')
    );
  }

  function getTrimmedValue(value) {
    return typeof value === 'string' ? value.trim() : value;
  }

  const getBreadcrumbLength = () => {
    const breadcrumb = sessionStorage.getItem('breadcrumb');
    return breadcrumb ? breadcrumb.split('/').length : 0;
  };

  const getUrlAndMethodType = (updatedCreateForm) => {
    const path = location.pathname.startsWith('/') ? location.pathname.slice(1) : location.pathname;
    const url = path + (selectedTab?.title ? '/' + selectedTab.title : '');

    let methodType;
    let url2;

    if (createPopupHeading === 'Create') {
      methodType = 'POST';
      url2 = url;
    } else if (createPopupHeading === 'Modify') {
      methodType = 'PATCH';
      const value = sessionStorage.getItem(url) || '';
      const itemValue = updatedCreateForm[value]?.value;
      url2 = value === '' ? url : `${url}/${itemValue}`;
    } else if (createPopupHeading === 'Delete') {
      methodType = 'DELETE';
      const value = sessionStorage.getItem(url) || '';
      const itemValue = updatedCreateForm[value]?.value;
      url2 = value === '' ? url : `${url}/${itemValue}`;
    }

    return { url, url2, methodType };
  };

  const isPatternValid = (pattern, value) => {
    if (pattern !== '.*') { // Skip the wildcard pattern
      const regex = new RegExp(`^${pattern}$`);
      return regex.test(value); // Return true if the pattern matches
    }
    return true; // Wildcard pattern passes
  };

  const checkArrayValues = (patterns, values) => {
    return values.some(singleValue =>
      patterns.every(pattern => !isPatternValid(pattern, singleValue)) // All patterns must fail for a value to be invalid
    );
  };

  const checkStringValue = (patterns, value) => {
    return patterns.every(pattern => !isPatternValid(pattern, value)); // All patterns must fail for a string to be invalid
  };

  const fieldValidation = (key, patterns, value, title) => {
    if (!Array.isArray(patterns) || !patterns.length || !value) {
      updateFieldError(key, false, '');
      return;
    }

    const allFail = Array.isArray(value) && value.length
      ? checkArrayValues(patterns, value)
      : typeof value === 'string'
        ? checkStringValue(patterns, value)
        : false;

    const errorMessage = allFail ? `${title} is not a valid input` : '';
    updateFieldError(key, allFail, errorMessage);
  };

  const updateFieldError = (key, error, errorMessage) => {
    setCreateForm(prevForm => ({
      ...prevForm,
      [key]: { ...prevForm[key], error, errorMessage },
    }));
    dispatch(handleFieldError({ key, error, errorMessage }));
  };

  const handleAddToBasket = async () => {
    dispatch(handleSitePageStatus(LOADING));
    let validationFailed = false;
    let validationMessage = '';
    const updatedCreateForm = {};

    Object.keys(createForm).forEach(fieldName => {
      let value = getTrimmedValue(createForm[fieldName]?.value);
      const isRequired = createForm[fieldName].is_required;
      validationFailed = isRequired && value === '';
      validationMessage = validationFailed && `${createForm[fieldName].title} is required`;

      const breadcrumbLength = getBreadcrumbLength();
      updateBreadcrumb(breadcrumbLength, selectedTab.path);

      if (validationFailed) {
        dispatch(handleSubmitForm({ fieldName, error: { status: validationFailed, message: validationMessage } }));
        return
      }

      if (isValueNonEmpty(value, createForm[fieldName]?.field_type)) {
        value = (createForm[fieldName]?.field_type === 'Toggle' && (value === '' || value === null)) ? false : value;
        updatedCreateForm[fieldName] = {
          ...createForm[fieldName],
          value: Array.isArray(value) ? value : value.value ?? value
        };
      }
    });

    if (!validationFailed) {
      const { url, url2, methodType } = getUrlAndMethodType(updatedCreateForm);
      const _actCreateForm = {
        _action_: {
          url: url2,
          title: 'Operation',
          value: createPopupHeading
        },
        ...updatedCreateForm
      };

      dispatch(handleFormSubmission({
        url,
        _actCreateForm,
        methodType,
        url2,
        cartData,
        addButtonRef,
        setToast,
        catalogBreadcrumb
      }));
    }
  };

  function renderComponent(key, index) {
    const uniqueKey = `${key}-${index}`;

    if (createForm[key].field_type === 'Text' || createForm[key].field_type === 'Number') {
      return (
        <TextField
          fit="medium"
          className={`${styles.inputField} ${(index + 1) % 2 !== 0 ? styles.marginRight : ''}`}
          label={createForm[key].title}
          onChange={(e) => handleInputChange(key, e.target.value)}
          placeholder={`Enter ${createForm[key].title}`}
          required={createForm[key].is_required}
          error={createForm[key].error}
          errorMessage={createForm[key].errorMessage}
          type="text"
          id={`${uniqueKey}`}
          value={createForm[key].value}
          variant="default"
          disabled={createForm[key].disabled || false}
          onBlur={() => {
            console.log("validation")
            fieldValidation(key, createForm[key].pattern, createForm[key].value, createForm[key].title)
          }}
        />
      );
    } else if (createForm[key].field_type === 'Array[Text]') {
      return <MultiInput
        label={createForm[key].title}
        placeholder={`Enter ${createForm[key].title}`}
        id={`${uniqueKey}`}
        value={Array.isArray(createForm[key].value) ? createForm[key].value : []}
        onChange={(e) => handleInputChange(key, e)}
        disabled={createForm[key].disabled || false}
        error={createForm[key].error}
        errorMessage={createForm[key].errorMessage}
        onBlur={(e) => fieldValidation(key, createForm[key].pattern, e, createForm[key].title)}
      />;
    } else if (createForm[key].field_type === 'Dropdown') {
      return <Dropdown
        fit="medium"
        id={`${uniqueKey}`}
        className={`${styles.dropdownField} ${(index + 1) % 2 !== 0 ? styles.marginRight : ''}`}
        label={createForm[key].title}
        name={createForm[key].title}
        onChange={(e) => handleInputChange(key, e)}
        options={createForm[key].values}
        placeholder={`Select a ${createForm[key].title} from the list`}
        required={createForm[key].is_required}
        value={createForm[key].value}
        disabled={createForm[key].disabled || false}
        error={createForm[key].error}
        errorMessage={createForm[key].errorMessage}
        onOptionUpdate={(e) => fieldValidation(key, createForm[key].pattern, e, createForm[key].title)}
      />;
    } else if (createForm[key].field_type === 'Array[Enumeration]') {
      return <MultiSelectDropdown
        label={createForm[key].title}
        className={`${styles.multiSelectDropdown}`}
        placeholder={`Enter ${createForm[key].title}`}
        id={`${uniqueKey}`}
        options={createForm[key].values}
        value={Array.isArray(createForm[key].value) ? createForm[key].value : []}
        onChange={(e) => handleInputChange(key, e)}
        disabled={createForm[key].disabled || false}
        error={createForm[key].error}
        errorMessage={createForm[key].errorMessage}
      />;
    } else if (createForm[key].field_type === 'Toggle') {
      return <SwitchField
        label={createForm[key].title}
        onChange={(e) => handleInputChange(key, e)}
        required={createForm[key].is_required}
        value={createForm[key].value}
        disabled={createForm[key].disabled || false}
        id={`${uniqueKey}`}
        error={createForm[key].error}
        errorMessage={createForm[key].errorMessage}
      />;
    }
  }

  const handleCLose = () => {
    dispatch(handleCreateFormEmpty(true));
    dispatch(handleIsCreatePopupOpen({ open: false }))
  }

  return (<Dialog
    open={isCreatePopupOpen}
    onClose={(event, reason) => {
      if (reason === "backdropClick") {
        dispatch(handleCreateFormEmpty(false));
        dispatch(handleIsCreatePopupOpen({ open: false }));
      }
    }}
    PaperProps={{
      sx: {
        width: "70vw",
        height: "70vh",
        maxWidth: "800px",
        maxHeight: "500px",
      },
    }}
    aria-labelledby='alert-dialog-title'
  >
    <DialogTitle id="create-form-dialog-title" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      {createPopupHeading || 'Create'}
      <IconButton
        aria-label="close"
        onClick={handleCLose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <div className={styles.inputCtn}>
        {createForm &&
          Object.keys(createForm).map((key, index) => (
            <div key={key}>
              {renderComponent(key, index)}
            </div>
          ))}
      </div>
    </DialogContent>
    <DialogActions>
      <Button
        appearance="default"
        ref={addButtonRef}
        label="Add to basket"
        icon="shopping-cart"
        onClick={handleAddToBasket}
        disabled={isButtonDisabled}
        dataTestId="add-to-basket"
      />
    </DialogActions>
  </Dialog>);
};

export default CreateSitePopup;

CreateSitePopup.propTypes = {
  setToast: PropTypes.func
};