import React, { useEffect, useRef, useState } from 'react'
import styles from './SiteTable.css'
import { useDispatch, useSelector } from 'react-redux'
import { useGridApiRef } from '@mui/x-data-grid'
import { createFormDataUtil, handleDownloadFileService, handleUploadFileService } from '../../utility/utils.js'
import InputField from '../../commonComponents/InputField/InputField.js'
import DataTable from '../DataTable/DataTable.js'
import { fetchCartData, fetchSchema, handleCatalogBreadcrumb, handleCreateForm, handleIsCreatePopupOpen, setIntentFetchStatus } from '../../redux/features/sitePageSlice.js'
import { Icon, LoadingIndicator } from '@anchor/react-components'
import { catalogBreadCrumb } from '../../redux/features/breadcrumbsSlice.js'
import { DELETE, LOADING, MODIFY, MORE_DETAILS, SUCCESS } from '../../constants.js'
import Toast from '../../commonComponents/Toast/Toast.js'
import PropTypes from 'prop-types'
import RollbackModal from '../Rollback/Rollback.js'
import axiosInstance from '../../utility/axiosInstance.js'
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup.js'

const CreateButton = ({ intentData, isContainer, handleCreate }) => (
  <button
    className={` ${intentData?.length > 0 && isContainer ? styles.disabledBtn : styles.createBtn} ${styles.btnStyles}`}
    onClick={intentData?.length > 0 && isContainer ? null : handleCreate} data-testid='create button'
  >
    <img src='/assets/whiteBgPlus.svg' className={styles.createIcon} alt='white-bg-plus' />
    <span className={styles.createText}>Create</span>
  </button>
)

export const UploadButton = ({ fileInputRef, handleFileChange, handleButtonClick }) => (
  <button className={`${styles.downloadBtn} ${styles.btnStyles}`} onClick={handleButtonClick}>
    <img src='/assets/uploadsIcon.svg' className={styles.downloadIcon} alt='upload-icon' data-testid='upload button' />
    <div className={styles.bulkText}>Upload</div>
    <input
      data-testid="file-upload-input"
      type="file"
      ref={fileInputRef}
      style={{ display: 'none' }}
      accept=".xlsx"
      onChange={handleFileChange}
      id="file-upload-input"
    />
  </button>
)

const DownloadButton = ({ intentData, handleDownload }) => (
  <button
    className={` ${intentData?.length > 0 ? styles.downloadBtn : styles.disabledBtn} ${styles.btnStyles}`}
    onClick={handleDownload} data-testid='download button'
  >
    <img src='/assets/downloadIcon.svg' className={styles.downloadIcon} alt="download-icon" />
    <div className={styles.bulkText}>Download</div>
  </button>
)

const RoleBackButton = ({ handleShow }) => (
  <button
    className={` ${styles.btnStyles}`}
    onClick={handleShow} data-testid='roleback button'
  >
    <img src='/assets/rollback1.svg' className={styles.downloadIcon} alt="rollback-icon" />
    <div className={styles.bulkText}>Time Travel</div>
  </button>
)

const ReconcileButton = ({ handleReconcile, isReconcileClick }) => (
  <button
    className={` ${styles.btnStyles}`}
    onClick={handleReconcile}
    disabled={isReconcileClick}
    data-testid='reconcile button'
  >
    <Icon name='rotate' className={`${styles.icon} ${isReconcileClick ? styles.rotate : ""}`} />
    <div className={styles.bulkText}>Reconcile</div>
  </button>
)

const SiteTable = () => {
  const dispatch = useDispatch()
  const apiRef = useGridApiRef()
  const fileInputRef = useRef(null)
  const intentData = useSelector((state) => state.sitePage.intentData)
  const cartData = useSelector((state) => state.sitePage.cartData)
  const isNested = useSelector((state) => state.sitePage.isNested)
  const isContainer = useSelector((state) => state.sitePage.isContainer)
  const [filterData, setFilterData] = useState(intentData)
  const createPopupForm = useSelector((state) => state.sitePage.createForm)
  const columnNamesData = useSelector((state) => state.sitePage.columnNames)
  const selectedTab = useSelector((state) => state.sitePage.selectedTab)
  const currentPath = useSelector((state) => state.breadcrumb?.currentPath || '');
  const [showToastObj, setShowToastObj] = useState({ show: false, type: '', message: '' })
  const catalogBreadcrumb = useSelector((state) => state.sitePage.catalogBreadcrumb)
  const isModifiable = useSelector((state) => state.sitePage.isModifiable)
  const isDeletable = useSelector((state) => state.sitePage.isDeletable)
  const isRollBack = useSelector((state) => state.sitePage.isRollBack)
  const isReconcile = useSelector((state) => state.sitePage.isReconcile)
  const intentDataStatus = useSelector((state) => state.sitePage.intentDataStatus)
  const rollbackList = useSelector((state) => state.sitePage.rollbackList)
  const tabsData = useSelector((state) => state.sitePage.schemaData)
  const [showRollBack, setShowRollBack] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isReconcileClick, setIsReconcileClick] = useState(false)
  const visibleRows = ''

  const contextMenuOptions = [
    { name: 'Modify', value: MODIFY, icon: '/assets/edit.svg' },
    { name: 'Delete', value: DELETE, icon: '/assets/delete.svg' },
    { name: 'More Details', value: MORE_DETAILS, icon: '/assets/infoCircle.svg' }
  ]

  const handleSearchChange = (value) => {
    if (value?.length >= 1) {
      let data = [...intentData]
      if (visibleRows) {
        // filter only visible rows
        data = data.filter((item, index) => Object.values(visibleRows)[index])
      }
      // filter search input matched
      data = data.filter((item) => {
        const allColumns = Object.keys(item).filter((column) => {
          return (
            !['resource_group', 'method', 'is_nested'].includes(column) &&
            item[column].toString().toLowerCase().includes(value.toString().toLowerCase())
          )
        })

        return allColumns?.length > 0
      })
      setFilterData(data)
    } else {
      setFilterData(intentData)
    }
  }

  useEffect(() => {
    setFilterData(intentData);
    setTimeout(() => {
      dispatch(setIntentFetchStatus(SUCCESS))
    }, 1000)
  }, [intentData]);

  useEffect(() => {
    if (showRollBack && !rollbackList.length) {
      setShowToastObj({ show: true, type: 'warning', message: 'No Time Travel available' })
      setShowRollBack(false)
    }
  }, [rollbackList])

  const checkCartData = () => {
    const url = currentPath.join('/').toLowerCase().replace(/ /g, '_')
    const itemInCart = cartData?.items?.find((item) => item.url === url)
    if (cartData?.items?.length > 0) {
      const isChildOfExistingItem = cartData?.items?.every((item) => {
        const itemUrl = ["PATCH", "DELETE"].includes(item.method) && item.url.includes('/')
          ? item.url.split('/').slice(0, -1).join('/')
          : item.url;
        return url.startsWith(itemUrl);
      });

      if (!url.split('/').length > 4 && itemInCart) {
        setShowToastObj({ show: true, type: 'warning', message: 'New item must be a child of an item already in the cart' })
        return true
      }
      if (!isChildOfExistingItem && !url.split('/').length > 4 && !itemInCart) {
        setShowToastObj({ show: true, type: 'warning', message: 'New item must be a child of an item already in the cart' })
        return true
      }
    }
  }

  const handleCreate = () => {
    const _resetCreateForm = createFormDataUtil(createPopupForm)
    dispatch(handleCreateForm(_resetCreateForm))
    dispatch(handleIsCreatePopupOpen({ open: true, heading: 'Create' }))
  }

  const handleDownload = async () => {
    try {
      dispatch(setIntentFetchStatus(LOADING))
      const response = await handleDownloadFileService(catalogBreadcrumb)
      const outputFilename = `${catalogBreadcrumb}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      dispatch(setIntentFetchStatus(SUCCESS))
      setShowToastObj({ show: true, type: 'success', message: 'File downloaded successfully' })
    } catch (error) {
      dispatch(setIntentFetchStatus(SUCCESS))
      setShowToastObj({ show: true, type: 'warning', message: error?.response?.data?.errorText || error?.message })
    }
  }
  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0]
      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        dispatch(setIntentFetchStatus(LOADING))
        await handleUploadFileService(catalogBreadcrumb, sessionStorage.getItem('user_id'), formData)
        dispatch(setIntentFetchStatus(SUCCESS))
        setShowToastObj({ show: true, type: 'success', message: 'File uploaded successfully' })
        dispatch(fetchCartData()).then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            dispatch(fetchSchema({ site: catalogBreadcrumb, currentTab: selectedTab.id }))
          }
        })
      }
    } catch (error) {
      dispatch(setIntentFetchStatus(SUCCESS))
      setShowToastObj({ show: true, type: 'warning', message: error?.response?.data?.errorText || error?.message })
    }
  }

  const handleLeftClick = (selectedRow) => {
    if (isNested) {
      if (selectedRow.row.id && selectedRow.row.id !== 'undefined' && selectedRow.row.id !== 'Undefined') {
        dispatch(handleCatalogBreadcrumb(`${selectedTab.path}/${selectedRow.row.id}`))
        dispatch(catalogBreadCrumb({ path: `${selectedTab.path}/${selectedRow.row.id}`, tab: selectedTab.id }))
      } else if (selectedRow.id && selectedRow.id !== 'undefined' && selectedRow.id !== 'Undefined') {
        dispatch(handleCatalogBreadcrumb(`${selectedTab.path}/${selectedRow.id}`))
        dispatch(catalogBreadCrumb({ path: `${selectedTab.path}/${selectedRow.id}`, tab: selectedTab.id }))
      } else {
        dispatch(handleCatalogBreadcrumb(`${selectedTab.path}`))
        dispatch(catalogBreadCrumb({ path: `${selectedTab.path}`, tab: selectedTab.id }))
      }
    }
  }

  const showToast = (toastObj) => {
    setShowToastObj(toastObj)
  }

  function renderContent() {
    if (intentDataStatus === LOADING) {
      return <div className={styles.loader}><LoadingIndicator /></div>
    } else if (filterData?.length > 0) {
      return (
        <DataTable
          apiRef={apiRef}
          handleLeftClick={handleLeftClick}
          showColumnsToggle={false}
          siteColumnNames={columnNamesData}
          tableData={filterData}
          contextMenuOptions={contextMenuOptions.filter((item) =>
            (item.value !== MODIFY || isModifiable) && (item.value !== DELETE || isDeletable)
          )}
          checkCartData={checkCartData}
        />
      )
    } else {
      return <div className={styles.noSites}>No Sites Found</div>
    }
  }

  const handleReconcile = async () => {
    try {
      setIsPopupOpen(false)
      setIsReconcileClick(true)
      const userId = sessionStorage.getItem('user_id');
      await axiosInstance.get(`${process.env.API_GATEWAY}/reconcile?path=${catalogBreadcrumb}&user=${userId}`)
      sessionStorage.setItem(
        "toastData",
        JSON.stringify({ show: true, type: "success", message: "Reconcile success" })
      );
      window.location.reload();
    } catch (error) {
      setShowToastObj({ show: true, type: 'warning', message: error?.response?.data?.errorText || error?.message })
    } finally {
      setIsReconcileClick(false)
    }
  }

  useEffect(() => {
    const toastData = sessionStorage.getItem("toastData");
    if (toastData) {
      setShowToastObj(JSON.parse(toastData));
      sessionStorage.removeItem("toastData"); // Clear after showing
    }
  }, []);

  return (
    <div className={`${tabsData?.length < 4 ? styles.siteFullCtn : styles.siteCtn}`} data-testid="site-table">
      <div className={styles.siteContainer}>
        <div className={styles.siteHeader}>
          {isReconcile && <ReconcileButton
            handleReconcile={() => { setIsPopupOpen(true) }}
            isReconcileClick={isReconcileClick}
          />}
          <CreateButton
            intentData={intentData}
            isContainer={isContainer}
            handleCreate={handleCreate}
          />
          <UploadButton
            fileInputRef={fileInputRef}
            handleFileChange={handleFileChange}
            handleButtonClick={handleButtonClick}
          />
          <DownloadButton
            intentData={intentData}
            handleDownload={handleDownload}
          />
          {isRollBack && <RoleBackButton
            handleShow={() => { setShowRollBack(true) }}
          />}
          <InputField
            placeholderText='Search'
            icon={'/assets/map-search.svg'}
            onChangeCallback={handleSearchChange}
            name='siteTable'
          />
        </div>
        <div className={styles.dataTableCtn}>
          {renderContent()}
        </div>
        <ConfirmationPopup
          isOpen={isPopupOpen}
          onConfirm={handleReconcile}
          onCancel={() => { setIsPopupOpen(false) }}
          message="Are you sure to reconcile?"
          subMessage="This will refresh the data with real time source data."
          dataTestId={'reconcile-confirmation-popup'}
        />
        {showToastObj.show && <Toast
          showToast={showToastObj.show}
          setShowToast={setShowToastObj}
          message={showToastObj.message}
          type={showToastObj.type}
        />}
      </div>
      {showRollBack && <RollbackModal open={showRollBack} handleClose={() => { setShowRollBack(false) }} showToast={showToast} />}
    </div>
  )
}
export default SiteTable

CreateButton.propTypes = {
  intentData: PropTypes.array,
  isContainer: PropTypes.bool,
  handleCreate: PropTypes.func
}

UploadButton.propTypes = {
  fileInputRef: PropTypes.object,
  handleFileChange: PropTypes.func,
  handleButtonClick: PropTypes.func
}

DownloadButton.propTypes = {
  intentData: PropTypes.array,
  handleDownload: PropTypes.func
}

RoleBackButton.propTypes = {
  handleShow: PropTypes.func
}

ReconcileButton.prototypes = {
  handleReconcile: PropTypes.func,
  isReconcileClick: PropTypes.bool
}