import React from 'react'
import { Menu, MenuItem, ListItemIcon, Typography } from '@mui/material'
import styles from './ContextMenu.css'
import { DELETE } from '../../../constants.js'
import PropTypes from 'prop-types'

export default function ContextMenu ({ contextMenu, contextMenuList, handleClose, onMenuClick }) {
  return (
    <div className={styles.contextmenuCtn}>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        slotProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault()
              handleClose()
            }
          }
        }}
        sx={{
          '.MuiList-root.MuiMenu-list': {
            padding: '4px 0px',
            width: '200px',
            borderRadius: 'var(--mds_brand_border_medium_radius)',
            border: '1px solid var(--mds_brand_appearance_neutral_subtle_border-color)',
            background: 'var(--mds_brand_appearance_neutral_default_background-color)',
            boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.20)'
          },
          '.MuiButtonBase-root.MuiMenuItem-root': {
            color: '#141414',
            fontFamily: 'Maersk Text',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
            padding: '6px 14px',
            height: '32px',
            borderRadius: 'var(--radius-none, 0px)',
            background: '#FFF'
          },
          '.MuiButtonBase-root.MuiMenuItem-root:hover': {
            background: '#E9F6FC'
          }
        }}
      >
        {contextMenuList.map((menu) => {
          return <MenuItem key={menu.value} onClick={() => onMenuClick(menu.value)} >
            <ListItemIcon>
              <img
                src={menu.icon}
                alt={'Icon'}
                style={{ width: 24, height: 24, marginRight: 10 }}
              />
            </ListItemIcon>
            <Typography variant="inherit" className={`${menu.value === DELETE ? styles.contxtMenuItem : ''} `}>{menu.name}</Typography>
          </MenuItem>
        })}
      </Menu>
    </div >
  )
}

ContextMenu.propTypes = {
  contextMenu: PropTypes.object,
  contextMenuList: PropTypes.array,
  handleClose: PropTypes.func,
  onMenuClick: PropTypes.func
}
