import React, { useState } from 'react'
import styles from './FirewallUpgrade.css'
import TextField from '../Common/TextField/TextField'
import { Button, LoadingIndicator } from '@anchor/react-components'
import Toast from '../../commonComponents/Toast/Toast'
import RightTab from '../RightTab/RightTab'
import axiosInstance from '../../utility/axiosInstance'
import Dropdown from '../Common/Dropdown/Dropdown'
import { useNavigate } from 'react-router-dom'

function FirewallUpgrade() {
    const [values, setValues] = useState({ ip_address: '', manufacturer: '', ticket: '', firmware: '', hostname: '', product: '', platform: '', role: '', })
    const [showToastObj, setShowToastObj] = useState({ show: false, type: '', message: '' })
    const [hostNameTriggered, setHostNameTriggered] = useState(false)
    const [isDetailsCompleted, setIsDetailsCompleted] = useState(false)
    const [options, setOptions] = useState({ firewall: [], image: [] })
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [rightTabData, setRightTabData] = useState([
        { title: 'Hostname', completed: false, active: true },
        { title: 'Provide Details', completed: false, active: false },
        { title: 'Review', completed: false, active: false },
    ])

    const handleInputChange = (key, value) => {
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
            setValues({ ...values, [key]: value.value })
        } else {
            setValues({ ...values, [key]: value })
        }
    }

    const handleHostnameClick = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`${process.env.API_GATEWAY}/helios/hostnames/${values?.hostname}`)
            const { ip, firmware, connection_app, ...rest } = response?.data ?? {}
            setValues({
                ...values,
                ...rest,
                ip_address: ip
            })
            setOptions({
                firewall: firmware.split(',')
            })
            setHostNameTriggered(true)
            setRightTabData(rightTabData.map((item, index) => ({
                ...item,
                completed: index <= 0,
                active: index === 1
            })))
            setShowToastObj({ show: true, type: 'success', message: 'Success' })
        } catch (err) {
            setShowToastObj({ show: true, type: 'warning', message: err.message || 'Something went wrong' })
        } finally {
            setLoading(false)
        }
    }

    const handleTabClick = ({ title, completed }, position) => {
        if (!completed) return;

        setRightTabData(rightTabData.map((data, index) => ({
            ...data,
            active: data.title === title,
            completed: data.completed && index < position
        })));

        const shouldResetHost = position === 0;
        const shouldResetDetails = position <= 1;

        if (shouldResetHost) setHostNameTriggered(false);
        if (shouldResetDetails) setIsDetailsCompleted(false);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true)
            const userId = sessionStorage.getItem('user_id')
            const response = await axiosInstance.post(`${process.env.API_GATEWAY}/orders/event-driven`,
                { user_id: userId, inputs: { ticket: values?.ticket, firmware: values?.firmware, product: values?.product, hostname: values?.hostname, ip_address: values?.ip_address } }
            )
            setShowToastObj({ show: true, type: 'success', message: 'Order created successfully' })
            setTimeout(() => {
                navigate(`/track/trackorders/orderdetails?orderid=${response?.data?.uuid}`)
            }, 1000)
        } catch (err) {
            setLoading(false)
            setShowToastObj({ show: true, type: 'warning', message: err?.message || 'Something went wrong' })
        }
    }

    const handleDetails = () => {
        setIsDetailsCompleted(true);
        setRightTabData(rightTabData.map((item, index) => ({
            ...item,
            completed: index <= 1,
            active: index === 2
        })))
    }

    return <>
        {
            showToastObj.show && (
                <Toast
                    showToast={showToastObj.show}
                    setShowToast={setShowToastObj}
                    message={showToastObj.message}
                    type={showToastObj.type}
                />
            )
        }
        <h1 className={styles.heading}>Firewall Upgrade</h1>
        {loading ? <LoadingIndicator /> :
            <div className={styles.container}>
                <div className={`${styles.firstSection}`}>
                    <div className={styles.hostnameContainer}>
                        <TextField
                            fit="medium"
                            label="Host name"
                            className={`${styles.textFieldHost} `}
                            maxLength={10}
                            onChange={(e) => { handleInputChange('hostname', e.target.value) }}
                            placeholder="Enter Hostname"
                            required
                            type="text"
                            value={values.hostname}
                            variant="default"
                            disabled={hostNameTriggered}
                        />
                    </div>
                    {!hostNameTriggered && <Button
                        label="Next"
                        className={styles.button}
                        onClick={handleHostnameClick}
                        disabled={!values?.hostname}
                    />}
                    {hostNameTriggered && <div className={styles.detailsSection}>
                        <div className={styles.insideContainer}>
                            <Dropdown
                                fit="medium"
                                label={'firmware'}
                                name={'firmware'}
                                onChange={(e) => handleInputChange('firmware', e)}
                                options={options.firewall}
                                placeholder={`Select firmware`}
                                required={true}
                                value={values.firmware}
                                disabled={isDetailsCompleted}
                            />
                            <TextField
                                fit="medium"
                                label="Ticket"
                                className={`${styles.textField} `}
                                maxLength={10}
                                onChange={(e) => { handleInputChange('ticket', e.target.value) }}
                                placeholder="Enter Ticket"
                                required
                                type="text"
                                value={values.ticket}
                                variant="default"
                                disabled={isDetailsCompleted}
                            />
                        </div>
                        {!isDetailsCompleted && <Button
                            label="Next "
                            onClick={handleDetails}
                            className={styles.button}
                            disabled={!values.firmware ||
                                !values.ticket}
                        />}
                    </div>}
                    {isDetailsCompleted && <div className={styles.detailsSection}>
                        <div className={styles.insideContainer}>

                            <TextField
                                fit="medium"
                                label="IP Address"
                                className={`${styles.textField} `}
                                maxLength={10}
                                onChange={(e) => { handleInputChange('ip_address', e.target.value) }}
                                placeholder="Enter IP Address"
                                required
                                type="text"
                                value={values.ip_address}
                                variant="default"
                                disabled={false}
                            />
                            <TextField
                                fit="medium"
                                label="Role"
                                className={`${styles.textField} `}
                                maxLength={10}
                                onChange={(e) => { handleInputChange('role', e.target.value) }}
                                placeholder="Enter Role"
                                required
                                type="text"
                                value={values.role}
                                variant="default"
                                disabled={false}
                            />
                            <TextField
                                fit="medium"
                                label="Platform"
                                className={`${styles.textField} `}
                                maxLength={10}
                                onChange={(e) => { handleInputChange('platform', e.target.value) }}
                                placeholder="Enter Platform"
                                required
                                type="text"
                                value={values.platform}
                                variant="default"
                                disabled={false}
                            />
                            <TextField
                                fit="medium"
                                label="Manufacture"
                                className={`${styles.textField} `}
                                maxLength={10}
                                onChange={(e) => { handleInputChange('manufacturer', e.target.value) }}
                                placeholder="Enter Manufacture"
                                required
                                type="text"
                                value={values.manufacturer}
                                variant="default"
                                disabled={false}
                            />

                        </div>
                        <Button
                            label="Submit "
                            onClick={handleSubmit}
                            className={styles.button}
                            disabled={!values.ip_address ||
                                !values.role ||
                                !values.platform ||
                                !values.manufacturer}
                        />
                    </div>}
                </div>
                <div className={styles.rightTabSection}>
                    <RightTab tabData={rightTabData} handleClick={handleTabClick} />
                </div>
            </div >}
    </>
}

export default FirewallUpgrade