import CryptoJS from 'crypto-js';

export default function encrypt(payload) {
    const secretKey = process.env.ENCRYPTION_KEY;
    const iv = CryptoJS.lib.WordArray.random(16);

    const jsonPayload = JSON.stringify(payload);

    const encrypted = CryptoJS.AES.encrypt(jsonPayload, CryptoJS.enc.Base64.parse(secretKey), {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    const ivBase64 = CryptoJS.enc.Base64.stringify(iv);
    const encryptedToken = `${ivBase64}:${encrypted.toString()}`;

    return encryptedToken;
}
