import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import leftNavbarReducer from './features/leftNavbarSlice.js'
import breadcrumbsSlice from './features/breadcrumbsSlice.js'
import sitePageSlice from './features/sitePageSlice.js'
import trackOrdersSlice from './features/trackOrdersSlice.js'
import appSlice from './features/appSlice.js'
import auditSlice from './features/auditSlice.js'
import reportSlice from './features/reportSlice.js'
import breadcrumbsSlices from './features/breadcrumbSlice_v1.js'
import dashboardSlice from './features/dashboardSlice.js'
import warehouseProvisionSlice from './features/warehouseProvisionSlice.js'
import cloudResourceNamingSlice from './features/cloudResourceNamingSlice.js'
import aegisBackupSlice from './features/aegisBackupSlice.js'

export default configureStore({
  reducer: {
    leftNavbar: leftNavbarReducer,
    breadcrumbs: breadcrumbsSlice,
    sitePage: sitePageSlice,
    trackOrders: trackOrdersSlice,
    app: appSlice,
    audit: auditSlice,
    report: reportSlice,
    breadcrumb: breadcrumbsSlices,
    dashboard: dashboardSlice,
    warehouseProvision: warehouseProvisionSlice,
    cloudResourceNaming: cloudResourceNamingSlice,
    aegisBackup: aegisBackupSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
})
