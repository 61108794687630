import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_ERROR, LOADING, SUCCESS } from '../../constants.js'
import axiosInstance from '../../utility/axiosInstance.js'

export const fetchTrackOrders = createAsyncThunk(
  'fetchTrackOrders',
  async (_, { rejectWithValue, getState }) => {
    const state = getState()
    let userId = sessionStorage.getItem('user_id')
    const name = sessionStorage.getItem('name')
    const email = sessionStorage.getItem('email')

    if (!userId) {
      const newUserResponse = await axiosInstance.post(process.env.USER_MANAGER, {
        name,
        username: name,
        email
      })
      userId = newUserResponse.data.user_id
      sessionStorage.setItem('user_id', userId)
    }
    try {
      const res = await axiosInstance.get(`${process.env.API_GATEWAY}/orders`, {
        params: {
          ...state.trackOrders.params,
          user_id: userId
        }
      })
      const data = res.data
      return data
    } catch (error) {
      console.error('Error fetching initial data:', error)
      return rejectWithValue({
        message: error.message,
        code: error.code
      })
    }
  },
)
export const fetchOrderDetails = createAsyncThunk(
  'fetchOrderDetails',
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`${process.env.API_GATEWAY}/order-details?uuid=${orderId}`)
      const data = res.data
      return data
    } catch (error) {
      console.error('Error fetching initial data:', error)
      return rejectWithValue({
        message: error.message,
        code: error.code
      })
    }
  }
)

export const trackOrdersSlice = createSlice({
  name: 'trackOrders',
  initialState: {
    ordersList: [],
    fetchOrdersStatus: LOADING,
    orderDetails: {},
    fetchOrderDetailsStatus: LOADING,
    tableLoading: LOADING,
    totalCount: 0,
    params: {
      page: 1,
      per_page: 10
    }
  },
  reducers: {
    paginationSelector: (state, action) => {
      state.params.page = action.payload.page
      state.params.per_page = action.payload.pageSize
    },
    fullFillTableLoading: (state, action) => {
      state.tableLoading = SUCCESS
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrackOrders.pending, (state, action) => {
        state.fetchOrdersStatus = LOADING
        state.tableLoading = LOADING
      })
      .addCase(fetchTrackOrders.fulfilled, (state, action) => {
        state.ordersList = action.payload.list_orders || []
        state.totalCount = action.payload?.count?.total_count || 0
        state.fetchOrdersStatus = SUCCESS
      })
      .addCase(fetchTrackOrders.rejected, (state, action) => {
        state.fetchOrdersStatus = API_ERROR
        console.error('Error fetching track orders:', action.payload)
      })
      .addCase(fetchOrderDetails.pending, (state, action) => {
        state.fetchOrderDetailsStatus = LOADING
      })
      .addCase(fetchOrderDetails.fulfilled, (state, action) => {
        if (action.payload.errorText) {
          state.fetchOrderDetailsStatus = LOADING
        } else {
          state.orderDetails = action.payload
          state.fetchOrderDetailsStatus = SUCCESS
        }
      })
      .addCase(fetchOrderDetails.rejected, (state, action) => {
        state.fetchOrderDetailsStatus = API_ERROR
        console.error('Error fetching order details:', action.payload)
      })
  }
})
export const { paginationSelector, fullFillTableLoading } = trackOrdersSlice.actions
export default trackOrdersSlice.reducer
