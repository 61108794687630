import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_ERROR, LOADING, SUCCESS } from '../../constants.js'
import axiosInstance from '../../utility/axiosInstance.js'
const today = new Date()
const yesterday = new Date(today)
yesterday.setDate(yesterday.getDate() - 6)

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

export const fetchWorkflowInsights = createAsyncThunk(
  'fetchWorkflowInsights',
  async (_, { rejectWithValue }) => {
    try {
      const intentResponse = await axiosInstance.get(`${process.env.API_GATEWAY}/workflow-insights`)
      const response = intentResponse.data || {}
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const fetchSummery = createAsyncThunk(
  'fetchSummery',
  async (_, { rejectWithValue }) => {
    try {
      const intentResponse = await axiosInstance.get(`${process.env.API_GATEWAY}/helios/summary`)
      const response = intentResponse.data || {}
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    insightData: [],
    summeryData: {},
    fetchWorkflowStatus: LOADING,
    params: {
      start_date: formatDate(yesterday),
      end_date: formatDate(today)
    }
  },
  reducers: {
    dateRangeSelector: (state, action) => {
      state.params.start_date = action.payload.startDate
      state.params.end_date = action.payload.endDate
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkflowInsights.pending, (state, action) => {
        state.fetchWorkflowStatus = LOADING
      })
      .addCase(fetchWorkflowInsights.fulfilled, (state, action) => {
        state.fetchWorkflowStatus = SUCCESS
        state.insightData = action.payload || {}
      })
      .addCase(fetchWorkflowInsights.rejected, (state, action) => {
        state.fetchWorkflowStatus = API_ERROR
      })
      .addCase(fetchSummery.pending, (state, action) => {
        state.fetchWorkflowStatus = LOADING
      })
      .addCase(fetchSummery.fulfilled, (state, action) => {
        state.fetchWorkflowStatus = SUCCESS
        state.summeryData = action.payload || {}
      })
      .addCase(fetchSummery.rejected, (state, action) => {
        state.fetchWorkflowStatus = API_ERROR
      })
  }
})

export const { dateRangeSelector } = dashboardSlice.actions
export default dashboardSlice.reducer
