import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../utility/axiosInstance'
import { API_ERROR, LOADING, SUCCESS } from '../../constants'

export const fetchSiteCodes = createAsyncThunk(
    'fetchSiteCodes',
    async (_, { rejectWithValue }) => {
        try {
            const resource = await axiosInstance.get(`${process.env.API_GATEWAY}/helios/sites`)
            const response = resource.data || {}
            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const fetchWarehouseSchema = createAsyncThunk(
    'fetchWarehouseSchema',
    async (_, { rejectWithValue }) => {
        try {
            const userId = sessionStorage.getItem('user_id')
            const { data } = await axiosInstance.get(`${process.env.API_GATEWAY}/product-path-data?path=catalog/warehouse_service&filters=user_id:${userId}`)
            const schema = data.table_schema.tabs[0] || {}
            return schema
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const warehouseProvisionSlice = createSlice({
    name: 'warehouseProvisionSlice',
    initialState: {
        data: {},
        formData: {},
        tabData: [
            { title: 'Warehouse', completed: false, active: true },
            { title: 'Prefix', completed: false, active: false },
            { title: 'All other information', completed: false, active: false },
        ],
        api: null,
        params: null,
        path: null,
        fetchStatus: LOADING,
        siteCodes: []
    },
    reducers: {
        completeWarehouseOnboard: (state, action) => {
            state.isWarehouseOnboarded = action.payload
            state.tabData.forEach((item, index) => {
                item.completed = index <= 0;
                item.active = index === 1;
            });
        },
        completeFetchedPrefixes: (state, action) => {
            state.isFetchedPrefixes = action.payload
            state.tabData.forEach((item, index) => {
                item.completed = index <= 1;
                item.active = index === 2;
            });
        },
        completeConfirmDetails: (state, action) => {
            state.isConfirmDetails = action.payload
            state.tabData.forEach((item, index) => {
                item.completed = index <= 2;
                item.active = index === 3;
            });
        },
        setLoading: (state, action) => {
            state.fetchStatus = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSiteCodes.pending, (state, action) => {
                state.fetchStatus = LOADING
            })
            .addCase(fetchSiteCodes.fulfilled, (state, action) => {
                state.fetchStatus = SUCCESS
                state.siteCodes = action.payload.site_codes || []
            })
            .addCase(fetchSiteCodes.rejected, (state, action) => {
                state.fetchStatus = API_ERROR
            })
            .addCase(fetchWarehouseSchema.pending, (state, action) => {
                state.fetchStatus = LOADING
            })
            .addCase(fetchWarehouseSchema.fulfilled, (state, action) => {
                state.fetchStatus = SUCCESS
                state.formData = action.payload
                // state.api = action.payload.api
                // state.params = action.payload.params
                // state.path = action.payload.path
            })
            .addCase(fetchWarehouseSchema.rejected, (state, action) => {
                state.fetchStatus = API_ERROR
            })
    }
})
export const { completeWarehouseOnboard, completeFetchedPrefixes, completeConfirmDetails, setLoading } = warehouseProvisionSlice.actions
export default warehouseProvisionSlice.reducer
