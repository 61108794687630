import React, { useEffect, useState } from 'react'
import styles from './TrackOrdersPage.css'
import { Input, LoadingIndicator } from '@anchor/react-components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTrackOrders, fullFillTableLoading, paginationSelector } from '../../redux/features/trackOrdersSlice.js'
import { useNavigate } from 'react-router-dom'
import { API_ERROR, LOADING, orderModalFields, orderContextMenulist } from '../../constants.js'
import DataTable from '../DataGrid/DataGrid.js'
import DetailModal from '../DetailsModal/DetailsModal.js'

const TrackOrdersPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [contextIsOpen, setContextIsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [orderData, setOrderData] = useState({})
  const orderParams = useSelector((state) => state.trackOrders.params)
  const ordersList = useSelector((state) => state.trackOrders.ordersList)
  const totalCount = useSelector((state) => state.trackOrders.totalCount)
  const tableLoading = useSelector((state) => state.trackOrders.tableLoading)
  const fetchOrdersStatus = useSelector((state) => state.trackOrders.fetchOrdersStatus)
  const successTableLoading = () => dispatch(fullFillTableLoading())
  const handlePaginationModelChange = (newPaginationModel) => dispatch(paginationSelector(newPaginationModel))

  useEffect(() => {
    dispatch(fetchTrackOrders())
  }, [orderParams])

  const filteredRows = ordersList.length
    ? ordersList?.filter(row =>
      Object.values(row).some(value =>
        value?.toString().toLowerCase().includes(searchQuery?.toLowerCase())
      )
    )
    : []

  const TrackOrdersColumnNames = [
    { field: 'order_placed_on', headerName: 'Date', flex: 1 },
    { field: 'order_placed_by', headerName: 'Requester', flex: 1 },
    { field: 'order_id', headerName: 'Id', flex: 1 },
    { field: 'catalog', headerName: 'Catalog', flex: 1 },
    { field: 'current_stage', headerName: 'Stage', flex: 1 },
    {
      field: 'current_status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        switch (params.row.current_status) {
          case 'inProgress':
            return <div className={styles.orange} >
              <img src="/assets/progress.svg" className={styles.statusIcon} alt="In Progress" />&nbsp;In Progress
            </div>
          case 'success':
            return <div className={styles.green} >
              <img src="/assets/greenCircle.svg" className={styles.statusIcon} alt="Success" />&nbsp;Success
            </div>
          default:
            return <div>{params.row.current_status}</div>
        }
      }
    },
    {
      field: 'actions',
      headerName: '',
      width: 130,
      renderCell: () => (
        <div className={styles.rowChevron}><img src='/assets/chevron-right.svg' alt="Chevron" /></div>
      )
    }
  ]

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
  }
  const handleRowClick = (event) => {
    navigate(`/track/trackorders/orderdetails?orderid=${event.row.order_id}`)
  }
  const handleClose = () => setContextIsOpen(false)

  const handleContextMenuClick = (row, menu) => {
    setOrderData(row[0])
    setContextIsOpen(true)
  }

  const renderContent = () => {
    if (fetchOrdersStatus === LOADING) {
      return (
        <div className={styles.loader}>
          <LoadingIndicator data-testid="loading-indicator" />
        </div>
      )
    }

    if (fetchOrdersStatus === API_ERROR || !filteredRows.length) {
      return (
        <div className={styles.noSites}>
          No Data Found
        </div>
      )
    }

    return (
      <div className={styles.trackOrdersCtn}>
        <DataTable
          data={filteredRows}
          columns={TrackOrdersColumnNames}
          handleContextMenuClick={handleContextMenuClick}
          handleRowClick={handleRowClick}
          handlePaginationModelChange={handlePaginationModelChange}
          rowCount={totalCount}
          pageSize={orderParams.per_page}
          page={orderParams.page}
          tableLoading={tableLoading}
          successTableLoading={successTableLoading}
          contextMenuList={orderContextMenulist}
        />
      </div>
    )
  }

  return <>
    <div className={styles.titleCtn}>
      <div>Orders</div>
      <DetailModal
        isOpen={contextIsOpen}
        handleClose={handleClose}
        data={orderData}
        fields={orderModalFields}
      />
      <div>
        <Input
          clearButton={true}
          onClear={handleSearchChange}
          fit="medium"
          icon="magnifying-glass"
          id="textInput"
          maxLength={10}
          onChange={handleSearchChange}
          onIconClick={function noRefCheck() { }}
          placeholder="Search"
          type="text"
          value={searchQuery}
          variant="default"
        />
      </div>
    </div >
    {renderContent()}
  </>
}
export default TrackOrdersPage
