// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toastMessage_ZdomH {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.statusIcon__lKDv {
  margin-right: 12px;
}
.closeIcon_MqNC1 {
  cursor: pointer;
  margin-left: 12px;
  border: none;
  background: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/commonComponents/Toast/Toast.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".toastMessage {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.statusIcon {\n  margin-right: 12px;\n}\n.closeIcon {\n  cursor: pointer;\n  margin-left: 12px;\n  border: none;\n  background: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastMessage": `toastMessage_ZdomH`,
	"statusIcon": `statusIcon__lKDv`,
	"closeIcon": `closeIcon_MqNC1`
};
export default ___CSS_LOADER_EXPORT___;
