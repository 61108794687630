import { createSlice } from '@reduxjs/toolkit'
import { SUCCESS } from '../../constants'

export const aegisBackupSlice = createSlice({
    name: 'aegisBackupSlice',
    initialState: {
        data: {},
        hostName: '',
        fetchStatus: SUCCESS,
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload.data
            state.hostName = action.payload.hostName
        },
        setLoading: (state, action) => {
            state.fetchStatus = action.payload
        }
    },
})
export const { setData, setLoading } = aegisBackupSlice.actions
export default aegisBackupSlice.reducer
