// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabsColor_Q4nDV {
  background-color: "#00243D";
}

.tabContainer_KAibm {
  display: flex;
}

.tabList_mhEUI {
  width: 250px;
  border-right: 1px solid #ddd;
}

.tabItem_VXMu1 {
  padding: 12px 15px;
  cursor: pointer;
  border: 0px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  color: #333;
  display: block;
  position: relative;
  background: initial;
  width: 100%;
  text-align: start;
}

.tabItem_VXMu1.active_RjSQY {
  background: var(--Color-Background-Primary-weak, #e9f6fc);
  color: #000;
}

.tabItem_VXMu1.active_RjSQY::after {
  content: "";
  position: absolute;
  right: -10px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #e6f2fa;
}

.tabItem_VXMu1:hover {
  background-color: #eaeaea;
}

.tabItem_VXMu1.active_RjSQY:hover {
  background: var(--Color-Background-Primary-weak, #e9f6fc);
  cursor: default;
}

.tabContent_DMwtX {
  padding: 20px;
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/SitePageTabs/SitePageTabs.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,6BAA6B;EAC7B,eAAe;EACf,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,yDAAyD;EACzD,WAAW;AACb;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,MAAM;EACN,SAAS;EACT,YAAY;EACZ,QAAQ;EACR,SAAS;EACT,kCAAkC;EAClC,qCAAqC;EACrC,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yDAAyD;EACzD,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,YAAY;AACd","sourcesContent":[".tabsColor {\n  background-color: \"#00243D\";\n}\n\n.tabContainer {\n  display: flex;\n}\n\n.tabList {\n  width: 250px;\n  border-right: 1px solid #ddd;\n}\n\n.tabItem {\n  padding: 12px 15px;\n  cursor: pointer;\n  border: 0px;\n  border-bottom: 1px solid #ddd;\n  font-size: 14px;\n  color: #333;\n  display: block;\n  position: relative;\n  background: initial;\n  width: 100%;\n  text-align: start;\n}\n\n.tabItem.active {\n  background: var(--Color-Background-Primary-weak, #e9f6fc);\n  color: #000;\n}\n\n.tabItem.active::after {\n  content: \"\";\n  position: absolute;\n  right: -10px;\n  top: 0;\n  bottom: 0;\n  margin: auto;\n  width: 0;\n  height: 0;\n  border-top: 12px solid transparent;\n  border-bottom: 12px solid transparent;\n  border-left: 12px solid #e6f2fa;\n}\n\n.tabItem:hover {\n  background-color: #eaeaea;\n}\n\n.tabItem.active:hover {\n  background: var(--Color-Background-Primary-weak, #e9f6fc);\n  cursor: default;\n}\n\n.tabContent {\n  padding: 20px;\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsColor": `tabsColor_Q4nDV`,
	"tabContainer": `tabContainer_KAibm`,
	"tabList": `tabList_mhEUI`,
	"tabItem": `tabItem_VXMu1`,
	"active": `active_RjSQY`,
	"tabContent": `tabContent_DMwtX`
};
export default ___CSS_LOADER_EXPORT___;
