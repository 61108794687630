export const cloudResourceNamingFields = [
    { title: 'Subscription ID', type: 'Text', required: true, placeholder: 'Enter', fieldName: 'subscription_id', name: 'SubscriptionID' },
    { title: 'Client ID', type: 'Text', required: true, placeholder: 'Enter', fieldName: 'client_id', name: 'ClientID' },
    { title: 'Client Secret', type: 'Password', required: true, placeholder: 'Enter', fieldName: 'client_secret', name: 'ClientSecret' },
    { title: 'Resource Group', type: 'Text', required: true, placeholder: 'Enter', fieldName: 'resource_group', name: 'ResourceGroup' },
    { title: 'Tenant ID', type: 'Text', required: true, placeholder: 'Enter', fieldName: 'tenant_id', name: 'TenantID', },
    { title: 'Resource Type', type: 'Dropdown', required: true, placeholder: 'Select', fieldName: 'resource_type', name: 'ResourceType' },
    { title: 'Location', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'location', name: 'Location' },
    { title: 'Business Unit', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'business_unit_name', name: 'BusinessUnit' },
    { title: 'Environment Level', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'environment_level', name: 'EnvironmentLevel' },
    { title: 'Workload', type: 'Text', required: false, placeholder: 'Enter', fieldName: 'workload', name: 'Workload' },
    { title: 'Network Zone', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'network_zone', name: 'NetworkZone' },
    { title: 'Storage Class', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'storage_class_name', name: 'StorageClass' },
    { title: 'Cluster Node', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'cluster_node', name: 'ClusterNode' },
    { title: 'Storage Replication', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'storage_replication_name', name: 'StorageReplication' },
    { title: 'CSP', type: 'Text', required: false, placeholder: 'Enter', fieldName: 'csp_name', name: 'CSP' },
    { title: 'Connectivity', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'connectivity', name: 'Connectivity' },
    { title: 'Service Type', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'service_type', name: 'ServiceType' },
    { title: 'Sequence', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'sequence', name: 'Sequence' },
]

export const ResourceGroupNamingFields = [
    { title: 'Subscription ID', type: 'Text', required: true, placeholder: 'Enter', fieldName: 'subscription_id', name: 'SubscriptionID' },
    { title: 'Client ID', type: 'Text', required: true, placeholder: 'Enter', fieldName: 'client_id', name: 'ClientID' },
    { title: 'Client Secret', type: 'Password', required: true, placeholder: 'Enter', fieldName: 'client_secret', name: 'ClientSecret' },
    { title: 'Tenant ID', type: 'Text', required: true, placeholder: 'Enter', fieldName: 'tenant_id', name: 'TenantID', },
    { title: 'Location', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'location', name: 'Location' },
    { title: 'Business Unit', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'business_unit_name', name: 'BusinessUnit' },
    { title: 'Environment Level', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'environment_level', name: 'EnvironmentLevel' },
    { title: 'Workload', type: 'Text', required: false, placeholder: 'Enter', fieldName: 'workload', name: 'Workload' },
    { title: 'Subscription Purpose', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'subscription_purpose', name: 'SubscriptionPurpose' },
    { title: 'Sequence', type: 'Dropdown', required: false, placeholder: 'Select', fieldName: 'sequence', name: 'Sequence' },
]