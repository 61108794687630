import React, { useState } from 'react';
import TextField from "../Common/TextField/TextField";
import styles from './BaseBuild.css'
import { Button } from '@anchor/react-components'
import MultiInput from '../Common/MultiInput/MultiInput';

const BaseBuild = () => {
    const [value, setValue] = useState([])


    return <>
        <div className={`${styles.formCtn}`}>
            <div className={styles.inputCtn}>
                <MultiInput
                    className={styles.multiInput}
                    label={'Device Role'}
                    placeholder={'Enter Device Role'}
                    id={''}
                    value={value}
                    onChange={(e) => { setValue([...value, e]) }}
                    disabled={false}
                    expandable={true}
                />
                <TextField
                    fit="medium"
                    id="textInput"
                    label="Warehouse"
                    className={styles.inputField}
                    maxLength={10}
                    onChange={() => { console.log('here') }}
                    placeholder="Enter Warehouse Site ID"
                    required
                    type="text"
                    value={''}
                    variant="default"
                    disabled={false}
                /><TextField
                    fit="medium"
                    id="textInput"
                    label="Warehouse"
                    className={styles.inputField}
                    maxLength={10}
                    onChange={() => { console.log('here') }}
                    placeholder="Enter Warehouse Site ID"
                    required
                    type="text"
                    value={''}
                    variant="default"
                    disabled={false}
                />

            </div>
            <Button
                appearance="default"
                justifyItems="center"
                label="Submit"
                name="primary"
                className={styles.generateBtn}
                onClick={() => { console.log('here') }}
                title="Submit"
                variant="filled"
                disabled={false}
            />
        </div>
        {/* </div> */}
        {/* {showToastObj.show
            ? <Toast
                showToast={showToastObj.show}
                setShowToast={setShowToastObj}
                message={showToastObj.message}
                type={showToastObj.type} />
            : null} */}
    </>
}

export default BaseBuild;