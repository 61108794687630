import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_ERROR, LOADING, SUCCESS } from '../../constants.js'
import axiosInstance from '../../utility/axiosInstance.js'
const today = new Date()
const yesterday = new Date(today)
yesterday.setDate(yesterday.getDate() - 6)

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

export const fetchAuditReport = createAsyncThunk(
  'fetchAuditReport',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState()
      const intentResponse = await axiosInstance.get(`${process.env.API_GATEWAY}/report`, {
        params: state.report.params
      })
      const response = intentResponse.data || {}
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    reportData: [],
    fetchReportStatus: LOADING,
    params: {
      start_date: formatDate(yesterday),
      end_date: formatDate(today)
    }
  },

  reducers: {
    dateRangeSelector: (state, action) => {
      state.params.start_date = action.payload.startDate
      state.params.end_date = action.payload.endDate
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAuditReport.pending, (state) => {
        state.fetchReportStatus = LOADING
      })
      .addCase(fetchAuditReport.fulfilled, (state, action) => {
        state.fetchReportStatus = SUCCESS
        state.reportData = action.payload || {}
      })
      .addCase(fetchAuditReport.rejected, (state, action) => {
        state.fetchReportStatus = API_ERROR
      })
  }
})

export const { dateRangeSelector } = reportSlice.actions
export default reportSlice.reducer
