import React, { useState } from 'react'
import { Modal, Input, TextArea, Button } from '@anchor/react-components'
import PropTypes from 'prop-types'

const CommitModal = ({ commitModal, handleModalClose, handleCommit }) => {
  const [commitObj, setCommitObj] = useState({ reference: '', notes: '' })
  const [disabledBtn, setDisabledBtn] = useState(true)

  const handleReferenceChange = (event) => {
    setCommitObj({
      ...commitObj,
      reference: event.target.value
    })
    setDisabledBtn(event.target.value === '')
  }
  const handleNotesChange = (event) => {
    setCommitObj({
      ...commitObj,
      notes: event.target.value
    })
  }

  return <Modal
        width='400px'
        open={commitModal}
        onClose={handleModalClose}
        actions={{
          primaryAction: <Button label="Submit" onClick={() => handleCommit(commitObj.notes, commitObj.reference)} disabled={disabledBtn} />
        }}
        heading="Commit">
        <Input
            clearButton={true}
            onClear={handleReferenceChange}
            label="Change Reference Number"
            required
            fit="medium"
            id="textInput"
            onChange={handleReferenceChange}
            onIconClick={function noRefCheck () { }}
            type="text"
            value={commitObj.reference}
            variant="default"
        />
        <TextArea
            fit="medium"
            id="text-area"
            label="Notes"
            onChange={handleNotesChange}
            // rows={5}
            value={commitObj.notes}
        />
    </Modal>
}

export default CommitModal

CommitModal.propTypes = {
  commitModal: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleCommit: PropTypes.func
}
