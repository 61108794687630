import React, { useEffect, useState } from "react";
import styles from './DetailPage.css'
import { Icon, LoadingIndicator } from "@anchor/react-components/dist/lib/components";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompareConfig } from "../../redux/features/aegisBackupSlice";
import { LOADING } from "../../constants";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';

const TextViewer = () => {
    const { data, fetchStatus, hostName, configType, deviceType, role, region, siteName, content } = useSelector(state => state.aegisBackup)
    const [copied, setCopied] = useState(false)
    const [selectedItems, setSelectedItems] = useState([]);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (!hostName) navigate(`/aegis`)
    }, [])

    const handleCheckboxChange = (index) => {
        if (selectedItems.includes(index)) {
            setSelectedItems(selectedItems.filter((item) => item !== index));
        } else if (selectedItems.length < 2) {
            setSelectedItems([...selectedItems, index]);
        } else {
            setSelectedItems([selectedItems[1], index]);
        }
    };

    const copyToClipBoard = (text) => {
        navigator.clipboard.writeText(text)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    const compareConfigs = () => {
        if (selectedItems.length < 2) {
            return;
        }

        const date1 = new Date(data?.previous_versions[selectedItems[0]].date);
        const date2 = new Date(data?.previous_versions[selectedItems[1]].date);

        const compareDate = {
            from: date1 < date2 ? data?.previous_versions[selectedItems[0]].date : data?.previous_versions[selectedItems[1]].date,
            to: date1 > date2 ? data?.previous_versions[selectedItems[0]].date : data?.previous_versions[selectedItems[1]].date
        };

        dispatch(fetchCompareConfig({ hostName, configType: 'Compare Config', compareDate })).then((action) => {
            if (action.type.endsWith('/fulfilled')) {
                navigate('/aegis/detail');
            }
        });
    };

    return <>
        {fetchStatus === LOADING ? <LoadingIndicator /> :
            <div className={styles.pdfViewerContainer}>
                <div className={`${styles.pdfPage} ${styles.deviceDetailsContainer}`} >
                    <div className={styles.pageHeader}>
                        <h5>{hostName}</h5>
                    </div>
                    <div className={styles.pageContent}>
                        <div className={styles.headerSection}>
                            <div className="header-item">
                                <p>Region</p>
                                <strong>{region}</strong>
                            </div>
                            <div className="header-item">
                                <p>Site</p>
                                <strong>{siteName}</strong>
                            </div>
                            <div className="header-item">
                                <p>Device Role</p>
                                <strong>{role}</strong>
                            </div>
                            <div className="header-item">
                                <p>Device Type</p>
                                <strong>{deviceType}</strong>
                            </div>
                            <div className="header-item">
                                <p>Host Name</p>
                                <strong>{hostName}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.pdfPage} >
                    <div className={styles.pageHeader}>
                        <h5>Details</h5>
                        <button className={`${styles.copyButton} ${styles.tooltip}`} onClick={() => copyToClipBoard(content)}>
                            <span className={styles.tooltipText}>{copied ? '✓ Copied to clipboard' : 'Copy to clipboard'}</span>
                            <Icon className={styles.copyIcon} name='copy' />
                        </button>
                    </div>
                    <div className={styles.contentSection}>

                        {configType === 'Get Config' && <div className={styles.listContainer}>
                            <div className={styles.detailsList}>
                                {[...(data?.previous_versions || [])]
                                    .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in descending order
                                    .map((item, index) => (
                                        <div
                                            key={index}
                                            className={`${styles.detailItem}`}
                                            onClick={() => { handleCheckboxChange(index) }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedItems.includes(index)}
                                                onChange={() => handleCheckboxChange(index)}
                                            />
                                            <div className={styles.detailContent}>
                                                <div className={styles.detailDate}>{dayjs(item?.date).format('DD/MM/YYYY')}</div>
                                                <div className={styles.detailName}>{item?.name}</div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <div className={styles.compareSection}>
                                <button
                                    className={styles.compareButton}
                                    disabled={selectedItems.length < 2}
                                    onClick={compareConfigs}
                                >
                                    Compare
                                </button>
                            </div>
                        </div>}

                        <div className={`${styles.pageContent} ${styles.maxHeight}`}>
                            {configType === 'Get Config' && <pre>{content}</pre>}
                            {configType === 'Compare Config' && content?.map((item, index) => (
                                <pre key={`item-${index}`} className={`${item.startsWith('+') ? styles.addedLine : item.startsWith('-') ? styles.removedLine : ''} ${styles.line}`} >
                                    {item}
                                </pre>
                            ))}
                        </div>
                    </div>
                </div>
            </div>}
    </>
};

export default TextViewer;

